import { OverviewReportCounInitialState } from 'containers/dashboard/AdminDashboard/OverviewReport/filterTypes';
import { REMOVE_OVERVIEW_REPPORT_STATE, SET_ALL_OVERVIEW_REPPORT_STATE, SET_OVERVIEW_REPPORT_STATE } from '_store/constants/filterData_OverviewReport';
import Action from '../../types/action';

const initialState = { ...OverviewReportCounInitialState }
const filterData_OverviewReport = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_OVERVIEW_REPPORT_STATE:
      localStorage.setItem('overviewReportFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return Object.assign({}, state, { [action.payload.type]: action.payload.data });
      break;
      case SET_ALL_OVERVIEW_REPPORT_STATE:
        localStorage.setItem('overviewReportFilter', JSON.stringify({ ...state, ...action.payload }));
        return Object.assign({}, state, { ...action.payload });
        break;
    case REMOVE_OVERVIEW_REPPORT_STATE:
      localStorage.removeItem('overviewReportFilter');
      return Object.assign({}, initialState);
      break;
    default:
      return state
  }
}
export default filterData_OverviewReport