import { REMOVE_PENDING_CV_DATA_STATE, SET_PENDING_CV_DATA_STATE } from '_store/constants/filterData_pendingCvFeedback';
import Action from '../../types/action';

const initialState = {
  'setting': [
    { id: 1, item: 'ReceivedDate', sortBy: 'receivedDate', order: false },
    { id: 2, 
      item: 'Days Elapsed Since Received',
      sortBy: 'daysElapsedSinceReceived',
      order: false,
    },
    { id: 3, item: 'Organization', sortBy: 'organization', order: false },
    { id: 4, item: 'PfyPosition', sortBy: 'pfyPosition', order: false },
    { id: 5, item: 'Position Status', sortBy: 'positionStatus', order: false },
  ]
}
const filterData_pendingCvFeedback = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_PENDING_CV_DATA_STATE:
      return { ...state, [action.payload.type]: action.payload.data }
    case REMOVE_PENDING_CV_DATA_STATE:
      return initialState;
    default:
      return state
  }
}
export default filterData_pendingCvFeedback