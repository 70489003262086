import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { addLogPositionAPI } from '_store/apis/accountDetailsAPI';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../../_store/constants/index';
import styles from './index.module.scss';

const ChangePasswordModal = (props: any) => {
  const { show, handleClose } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedRoleInitial = { item: '', id: '' };
  let [selectedSubject, setSelectedSubject] = useState<any>(selectedRoleInitial);
  const [switchStatus, setSwitchStatus] = useState<boolean>(false);
  const positionId = localStorage.getItem('positionId')

  useEffect(() => {
    setSwitchStatus(switchStatus);
  }, [switchStatus]);

  const addSingleUserSchema = Yup.object().shape({
  });
  const editSingleUserSchema = Yup.object().shape({
  });
  const submitData = async () => {
    try {
      const payload = {
        ...formik.values,
        positionId: positionId,
      };
      const { status, body } = await addLogPositionAPI(payload);
      if (status === 200 || status === 201) {
        handleModalClose();
        selectedSubject = []
        setSelectedSubject(selectedSubject)
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: body?.detail ? body?.detail : 'Successfully Added Log',
        });
      } else if (status > 399 && status < 500) {
        dispatch({
          type: ERROR_MESSAGE,
          payload: body?.detail ? body?.detail : 'Failed to Add Log',
        });
      } else {
        dispatch({
          type: ERROR_MESSAGE,
          payload:
            body?.detail === undefined ? 'Failed to connect' : body?.detail,
        });
      }
    } catch (err) {
      dispatch({ type: ERROR_MESSAGE, payload: 'Failed to connect' });
    }
  };

  const formik = useFormik({
    initialValues: {
      comments: '',
      communicationType: '',
      subject: '',
      details: '',
    },
    validationSchema:
      selectedSubject.name === 'Other'
        ? editSingleUserSchema
        : addSingleUserSchema,
    onSubmit: () => submitData(),
  });

  const handleModalClose = () => {
    handleClose();
  };
  const changePasswordFunction = () => {
    history.push('/user/setting/change-password');
    handleModalClose();
  };
  const loggedInUsersFunction = () => {
    history.push('/user/setting/loggedin-users');
    handleModalClose();
  };
  return (
    <Modal
      centered
      show={show}
      onHide={handleModalClose}
      className={`${styles.modalCustomcard} ${styles.addUserPopupWrapper1}`}
    >
      <Modal.Header className={styles.modal_header} closeButton>
        <h4 className={styles.commonTitle}>Settings</h4>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <form>
            <>
              <div className={'row'}>
                <div className={'col-12'}>
                  <form className={styles.settingS}>
                    <div className="row">
                      <div className="col-md-3">
                        <div className={styles.ButtonSEC} onClick={() => changePasswordFunction()}>
                          <LockOpenOutlinedIcon className={styles.icon} />
                          Change Password
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className={styles.ButtonSEC} onClick={() => loggedInUsersFunction()}>
                          <VpnKeyOutlinedIcon className={styles.icon} />
                          LoggedIn Users
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          </form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
};
export default ChangePasswordModal;
