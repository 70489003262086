import { REMOVE_BACKOUT_CANDIDATRES_STATE, SET_ALL_BACKOUT_CANDIDATRES_STATE, SET_BACKOUT_CANDIDATRES_STATE } from '_store/constants/filterData_BackoutCandidates';
import Action from '../../types/action';

const initialState = {
  dateOfJoiningFrom: null,
  dateOfJoiningTo: null,
  scheduledDateFrom: new Date(),
  scheduledDateTo: null,
  offeredFrom: null,
  offeredTo: null,
  resignedFrom: null,
  resignedTo: null,
  selectedFrom: null,
  selectedTo: null,
  driveDayStatus: null,
  organizations: null,
  pfyPositions: null
}
const filterData_BackoutCandidates = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_BACKOUT_CANDIDATRES_STATE:
      localStorage.setItem('backoutCandidateFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
      case SET_ALL_BACKOUT_CANDIDATRES_STATE:
        localStorage.setItem('backoutCandidateFilter', JSON.stringify({ ...state, ...action.payload }));
        return Object.assign({}, state, { ...action.payload });
        break;
    case REMOVE_BACKOUT_CANDIDATRES_STATE:
      localStorage.removeItem('backoutCandidateFilter');
      return initialState;
      break;
    default:
      return state
  }
}
export default filterData_BackoutCandidates