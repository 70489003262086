import { settingListDefaultValue, selectedSettingList } from 'containers/dashboard/AdminDashboard/OpenPosition/filterTypes';
import { REMOVE_OPEN_POSITION_STATE, SET_ALL_OPEN_POSITION_STATE, SET_LOCAL_STORAGE_OPEN_POSITION_STATE, SET_OPEN_POSITION_STATE } from '_store/constants/filterData_openPosition';
import Action from '../../types/action';

const initialState = {
  setting: [...settingListDefaultValue, ...selectedSettingList], 
  receivedBefore: null, receivedAfter: null,
  sortType: 'desc',
  sortBy: 'positionId',
}
const filterData_OpenPosition = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_OPEN_POSITION_STATE:
      localStorage.setItem('dashboard_setOpenPositionFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return Object.assign({}, state, { [action.payload.type]: action.payload.data });
      break;

    case SET_ALL_OPEN_POSITION_STATE:
      const filterState = { ...action.payload };
      const sortType = filterState.sortType === 'asc' ? 'desc' : (filterState.sortType && 'asc');
      localStorage.setItem('dashboard_setOpenPositionFilter', JSON.stringify({ ...state, ...filterState, sortType }));
      return Object.assign({}, state, { ...filterState, sortType });
      break;
      
    case SET_LOCAL_STORAGE_OPEN_POSITION_STATE:
      const dashboard_setOpenPositionFilter = JSON.parse(localStorage.getItem('dashboard_setOpenPositionFilter')!);
      if (dashboard_setOpenPositionFilter) {
        return Object.assign({}, state, { ...dashboard_setOpenPositionFilter });
      }      
      return Object.assign({}, initialState);
      break;

    case REMOVE_OPEN_POSITION_STATE:
      localStorage.removeItem('dashboard_setOpenPositionFilter');
      return Object.assign({}, initialState);
      break;

    default:
      return state
  }
}
export default filterData_OpenPosition