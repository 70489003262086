import Avatar from '@material-ui/core/Avatar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import RouterOutlinedIcon from '@material-ui/icons/RouterOutlined';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
import { Alert, Button, IconButton, Menu, Snackbar, Tooltip, Drawer, Box, useMediaQuery, MenuItem, TextField } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { setAllPositionFilters } from '_store/actions/filterData_position';
import { setUsersData } from '_store/actions/filterData_users';
import { saveTabValue } from '_store/actions/tabValue';
import { getDashboardNotificationCount } from '_store/apis/BackoutCandidates';
import { getApi } from '_store/apis/api';
import { baseURLWS } from '_store/apis/urls';
import { handleResetReport } from '_store/reducer/filterData_reports';
import NotFound from 'containers/NotFound';
import AddEditSingleRole from 'containers/system_admin/Candidates/AddUser/modals/AddSingleCandidate';
import BoradcastMessageModal from 'containers/user/BroadcastMessage/Modals';
import ChangePasswordModal from 'containers/user/Setting/Modals/ChangePassword';
import React, { createRef, useEffect, useState } from 'react';
import { Modal, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch, matchPath, useHistory, useLocation } from 'react-router-dom';
import ModalComponent from 'widgets/Modal';
import NormalTextField from 'widgets/NormalTextField';
import NotificationGif from '../../src/utilities/images/icons/CriticalAlertpeoplefy.gif';
import { RootState } from '../_store/reducer/rootReducer';
import ErrorBoundary from '../errorBoundary';
import { OrgTableForwardMethod } from '../types/index';
import {
  AddCandidate,
  Bulk, CTCCalculator, Candidates,
  Dashboard, Data,
  NewAlertIcon,
  NewBroadcastIcon,
  NewNotification,
  NewPdfIcon,
  NotificationsIcon, Organization,
  Portal, Positions, Reports, SendBroadcast, Setting, Settings,
  Showuser, SideLogout, TimeZoneConvertor, Wiki
} from '../utilities/images/icons/index';
import PrimaryButton from '../widgets/PrimaryButton';
import { sidebarItemsMap } from '../widgets/SideBar/sidebarItems';
import NotificationComp from './Notification';
import styles from './index.module.scss';
import {
  RouteTemplate,
  peoplefyHeaderRoutesMap
} from './routers';
import { unsecureRoutes } from './routes/unsecureRoutes';

/*-----Sidebar navigation-----*/
type Anchor = 'left';
const PageRoutes = () => {
  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      className={`${styles.headBar} ${styles.mobiHeader}`}
      onClick={() => setOpenSubHHeaderMenu(!openSubHHeaderMenu)}
      onKeyDown={() => setOpenSubHHeaderMenu(!openSubHHeaderMenu)}
    >
      <div className={`${styles.subheaderbar} ${styles.subheaderMob}`}>
        {user?.role && (
          <ul>
            {role?.map((e: any, index: any) => activeClass(e, index))}
          </ul>
        )}
      </div>
    </Box>
  );
  const isMobile = useMediaQuery("(max-width:767px)");
  const isDesktop = useMediaQuery("(min-width:1024px)");
  /*-----End Sidebar navigation-----*/
  const url = localStorage.getItem('url');
  const [notification, setNotification] = useState<boolean>(false);
  const [addCandidateModal, setAddCandidateModal] = useState<boolean>(false);
  const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);
  const [broadcastMessageModal, setBroadcastMessageModal] =
    useState<boolean>(false);
  const [profile, setProfile] = useState<boolean>(false);
  const [user] = useSelector((state: RootState) => [state.user.user]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const primaryRole = localStorage.getItem('primaryRole') || "";
  let [languageValue, setLanguageValue] = useState<string>('en');
  const [menuItems, setMenuItems] = useState<any>([]);
  const history = useHistory();
  const [profileData] = useState<any>({});
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const newRole = peoplefyHeaderRoutesMap.get(primaryRole) || [];
  const [role, setRole] = useState<RouteTemplate[]>([]);
  const [showhide, setshowhide] = useState(false);
  const users = localStorage.getItem('user') || null;
  const userDetail = users ? JSON.parse(users) : null;
  const [currentCtc, setCurrentCtc] = useState<any>('');
  const [expectedCtc, setExpectedCtc] = useState<any>('');
  let [expectedHike, setExpectedHike] = useState<any>('');
  const notificationState: any = useSelector((state: RootState) => state.notificationCountData);
  const [criticalCount, setCriticalCount] = useState(0);
  const userTableForwardMethodModalRef = createRef<OrgTableForwardMethod>();

  const clearBroadCastMessage = () => localStorage.removeItem('broadcastMessage');

  const [criticalAlertCount, setCriticalAlertCount] = useState<number>(0);
  const [normalAlertCount, setNormalAlertCount] = useState<number>(0);

  const addCandidate = () => {
    setAddCandidateModal(true);
  };
  const changePassword = () => {
    setChangePasswordModal(true);
  };
  const broadcastMessageData = () => {
    setBroadcastMessageModal(true);
  };
  const broadcastMessageSocketUrl = window.location.protocol === "http:" ? `ws${baseURLWS}broadcastMessage` : `wss${baseURLWS}broadcastMessage`;
  // const { sendMessage, lastMessage, readyState, sendJsonMessage, lastJsonMessage  }: any = useWebSocket(broadcastMessageSocketUrl);

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: 'Connecting',
  //   [ReadyState.OPEN]: 'Open',
  //   [ReadyState.CLOSING]: 'Closing',
  //   [ReadyState.CLOSED]: 'Closed',
  //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  // }[readyState];
  // const [messageHistory, setMessageHistory] = useState([]);
  // useEffect(() => {
  //   if (lastMessage !== null) {
  //     setMessageHistory((prev: any) => prev.concat(lastMessage));
  //   }
  // }, [lastMessage, setMessageHistory]);
  useEffect(() => {
    if (notificationState.data && notificationState.data.length) {
      const criticalCount = notificationState?.data.filter(
        (ele: any) => ele.IsCritical
      ).length;
      setCriticalCount(criticalCount);
    }
  }, [notificationState]);

  const numberRegix = /^[0-9.]+$/;
  const handleChangeCurrentCtc = (event: any) => {
    setCurrentCtc(event.target.value);
    console.log(event);
  };

  const clearForm = () => {
    setExpectedHike('');
    setExpectedCtc('');
    setCurrentCtc('');
  };

  const handleChangeExpectedCtc = (event: any) => {
    setExpectedCtc(event.target.value);
  };

  const handleChangeExpectedHike = (event: any) => {
    setExpectedHike(event.target.value);

    console.log(event);
  };

  const expectedCTCChange = () => {
    if (currentCtc) {
      if (parseFloat(expectedCtc) > parseFloat(currentCtc)) {
        const increase = parseFloat(expectedCtc) - parseFloat(currentCtc);
        expectedHike = (increase / parseFloat(currentCtc)) * 100;
        expectedHike = parseFloat(expectedHike.toFixed(2));
        if (expectedHike > 9999) {
          expectedHike = 0;
        }
      } else if (parseFloat(expectedCtc) === parseFloat(currentCtc)) {
        expectedHike = 0;
      } else {
        expectedHike = "";
      }
    }
  };

  function updateHikePercent(ele: number, currCTC: any) {
    if (ele && currCTC) {
      const numbers = currCTC + (currCTC * ele) / 100;
      return numbers?.toFixed(2);
    } else {
      return null;
    }
  }
  const loadPresetValuesOfCTChike = (currCTC: any) => {
    const preSetPercent = [10, 15, 20, 25, 30, 35, 40, 45, 50];
    return (
      <>
        <table className={styles.ctcTable}>
          <tr>
            <th>Expected CTC %</th>
            <th>Value (LPA)</th>
          </tr>
          {preSetPercent?.map((ele) => {
            return (
              <tr>
                <td>
                  <span className="pr-1">{ele}% </span> Hike
                </td>
                <td>{updateHikePercent(ele, currCTC)}</td>
              </tr>
            );
          })}
        </table>
      </>
    );
  };
  const Routes: any = {
    Dashboard: <Dashboard className={styles.subIcons} />,
    Showuser: <Showuser className={`${styles.changeI} ${styles.subIcons}`} />,
    RoleUser: (
      <AccountBoxIcon className={`${styles.rolesI} ${styles.subIcons}`} />
    ),
    Organization: <Organization className={styles.subIcons} />,
    Positions: <Positions className={styles.subIcons} />,
    Candidates: <Candidates className={styles.subIcons} />,
    Wiki: <Wiki className={styles.subIcons} />,
    Reports: <Reports className={styles.subIcons} />,
    PortalAllocation: <Portal className={styles.subIcons} />,
    Bulk: <Bulk className={styles.subIcons} />,
    Data: <Data className={styles.subIcons} />,
  };
  useEffect(() => {
    const roles = role.map((item: RouteTemplate) => {
      return {
        ...item,
        isActive: (matchPath(location.pathname, { path: item.path, exact: false })) ? true : false
      }
    })
    setRole(roles);
  }, [location]);

  const listofVertical = [
    { id: 1, name: 'IT' },
    { id: 2, name: 'Non-IT' },
    { id: 3, name: 'KPO' },
    { id: 4, name: 'Others' }];

  const routeOnSubheader = (path: any) => {
    const user = JSON.parse(users!);
    const primaryRole = localStorage.getItem('primaryRole');
    let createPath = path;
    if (createPath.includes(':userId') && createPath.includes(':primaryRole')) {
      createPath = createPath
        .replace(':userId', user.userId)
        .replace(':primaryRole', primaryRole);
    }
    if (primaryRole === 'RPO' || primaryRole === 'VP') {
      dispatch(setUsersData({ type: 'userType', data: { item: 'Client' } }));
    }
    if (createPath.includes(':reportType?')) {
      createPath = createPath.replace('/:reportType?', '')
    }

    history.push(createPath)
    localStorage.setItem('currentRoute', createPath);
    if (!matchPath(location.pathname, { path: path, exact: false })) {
      dispatch(handleResetReport());
    }

    const positionFilter = JSON.parse(localStorage.getItem('positionFilter')!);
    const verticalId = JSON.parse(localStorage.getItem('verticalId')!);
    let verticalArray = listofVertical?.filter((item: any) => item.id == verticalId)
    if (positionFilter && positionFilter.vertical?.length) {
    }
    else {
      dispatch(setAllPositionFilters({ ...positionFilter, vertical: verticalArray ? verticalArray : null }));
    }
  }
  const activeClass = (e: RouteTemplate, index: any) => {
    return (<>
      {e.isVisible && (<>
        <li id={index} className={`${e.isActive ? styles.active : styles.inActive}`} onClick={() => routeOnSubheader(e.path)}>
          {e.isVisible && (<>
            {Routes[e.icon]}{e.name}
          </>)}
        </li>
      </>)}
    </>)
  }
  const handleThemeChange = (flag: boolean = user?.lightThemePreference) => {
    if (flag) {
      document.body.removeAttribute('data-theme');
    } else {
      document.body.setAttribute('data-theme', 'light');
    }
  };
  const handleClickOpen = () => {
    setModalInfo({
      modalIdentity: 'Logout',
    });
    setModalShow(true);
  };

  const hideModal = () => {
    setModalShow(false);
    setTimeout(() => 300);
  };

  useEffect(() => {
    if (user) {
      setMenuItems(sidebarItemsMap.get(user?.role));
      handleThemeChange();
    }
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      languageValue = localStorage.getItem('i18nextLng') || 'en';
      setLanguageValue(languageValue);
    }
  }, [localStorage.getItem('i18nextLng')]);

  useEffect(() => {
    if (primaryRole) {
      const currentRoute: any = localStorage.getItem('currentRoute');
      let some = peoplefyHeaderRoutesMap.get(primaryRole);
      const user = JSON.parse(users!);
      some = some?.map((role: RouteTemplate) => {
        let locationPathName = location.pathname;
        let path = role.path?.replace(':userId', user.userId)?.replace(':primaryRole', primaryRole ? primaryRole : '');

        if (locationPathName.includes('/reports') && path.includes('/reports/:reportType?')) {
          path = path.replace('/:reportType?', '')
          if (locationPathName !== path) {
            const a = locationPathName.split('/')
            const toReplace = a[a.length - 1];
            locationPathName = locationPathName.replace(`/${toReplace}`, '')
          }
        }
        return {
          ...role,
          isActive: currentRoute === path ? true : false
        };
      })
      setRole(some);

    }
  }, [primaryRole]);

  const ShowProfile = (profileType: any) => {
    setProfile(!profile);
    dispatch(saveTabValue(profileType));
    history.push('/profile');
  };

  const refreshTable = () => {
    userTableForwardMethodModalRef.current?.method1();
  };
  const handleDataUpdate = () => {
    refreshTable();
  };
  const onClickRole = (role: any) => {
    const some = peoplefyHeaderRoutesMap.get(role);
    localStorage.setItem(
      'currentRoute',
      `/user/${userDetail?.userId}/${role}/dashboard`
    );

    setRole(some);
    localStorage.setItem('primaryRole', role);
    if (role === 'SuperAdmin') {
      history.push('/home');
    } else {
      history.push(`/user/${userDetail?.userId}/${role}/dashboard`);
    }
    fetchUserNotificationCount();
    dispatch(handleResetReport());
  };
  const activeRole = (e: any) => {
    const active = localStorage.getItem('primaryRole');
    return e === active ? styles.active : 'inActive';
  };
  const handleNotificationChange = (name: any) => {
    clearBroadCastMessage();
    setNotificationMessage(prev => (prev === name ? '' : name));
  }

  //new Notification popup
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const openNotification = Boolean(anchorEl1);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
  };
  // End

  //new critical Notification popup
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const openCritical = Boolean(anchorEl2);
  const handleClickCritical = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseCritical = () => {
    setAnchorEl2(null);
  };
  // End

  //new Broadcast popup
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const openBroadcast = Boolean(anchorEl3);
  const handleClickBroadcast = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleCloseBroadcast = () => {
    setAnchorEl3(null);
  };
  // End


  //mobile menu
  const [anchorEl4, setAnchorEl4] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl4);
  const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleCloseMobile = () => {
    setAnchorEl4(null);
  };

  //end

  useEffect(() => {
    if (openBroadcast) {
      localStorage.removeItem('broadcastMessage')
      setToastMessage(null);
    }
  }, [openBroadcast]);

  useEffect(() => {
    if (openNotification) {
      localStorage.removeItem('notificationMessage')
      setToastNotificationMessage(null);
    }
  }, [openNotification]);

  const [listRoleWiseNotification, setListRoleWiseNotification] = useState<any>([]);

  const fetchUserNotificationCount = async () => {
    try {
      const { status, body }: { status: number, body: any } = await getApi(`api/dashboard/roleWise/notificationCount`);

      if (status === 200) {
        setListRoleWiseNotification(body.listRoleWieNotification)
      }
    } catch (error) {

    }
  }
  const getRoleWiseNotificationCount = (role: string) => {
    return listRoleWiseNotification.find(ele => ele.roleName === role);
  }

  useEffect(() => {
    if (user) {
      fetchUserNotificationCount()
    }
  }, [user]);

  const topHeaderIcons = (topMenuClass: any) => (
    <div className={` ${topMenuClass} ${styles.mobileNotification}`}>
      <ul className={`${styles.headIcons} `}>
        {user?.roles?.map((item: any) => (
          <li
            className={`${styles.TopMenu} ${activeRole(item.name)} `}
            onClick={() => onClickRole(item.name)}
          >
            {item.name}&nbsp;
            <span className={styles.bellDot}>
              <span className={styles.bellnoroll}>
                {getRoleWiseNotificationCount(item.name)?.count}
              </span>
            </span>
          </li>
        ))}
      </ul>

      <ul className={`${styles.headIcons} ${styles.headIpad} ${styles.secondUi}`}>
        {primaryRole === 'SuperAdmin' ||
          primaryRole === 'Admin' ||
          primaryRole === 'Manager' ||
          primaryRole === 'SPOC' ||
          primaryRole === 'RPO' ||
          primaryRole === 'InternalHR' ||
          primaryRole === 'Recruiter' ||
          primaryRole === 'QC' ||
          primaryRole === 'AgencyRCR' || primaryRole === 'VP' ? (<Tooltip title="Add Candidate">
            <li className={styles.rightSide}>
              <AddCandidate
                className={styles.settingHead}
                onClick={addCandidate}
              />
            </li>
          </Tooltip>) : null}
        <Tooltip title="Critical Alert">
          {criticalAlertCount === 0 ?
            <li className={`${styles.rightSide} ${openCritical ? styles.active : styles.inActive}`} >
              <div
                onClick={handleClickCritical}
                className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
                aria-controls={openCritical ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCritical ? 'true' : undefined}
              >
                <span className={styles.bellDot}>
                  <NewAlertIcon
                    className={styles.settingHead}
                  />
                  <span className={styles.bellno}>
                    {criticalAlertCount}
                  </span>
                </span>
              </div>
            </li>
            :
            <li className={`${styles.gifDiv} ${openCritical ? styles.active : styles.inActive}`} >
              <div
                onClick={handleClickCritical}
                className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
                aria-controls={openCritical ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCritical ? 'true' : undefined}
              >
                <span className={styles.bellDotgif}>
                  <img src={NotificationGif} className={styles.gifImg} />
                  <span className={styles.bellno}>
                    {criticalAlertCount}
                  </span>
                </span>
              </div>
            </li>}
        </Tooltip>
        <Menu
          anchorEl={anchorEl2}
          id="account-menu"
          open={openCritical}
          onClose={handleCloseCritical}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              }
            },
          }}
        >
          <NotificationComp type={"criticalAlert"} notificationCout={notificationCout} fetchUserNotificationCount={fetchUserNotificationCount} />
        </Menu>

        <Tooltip title="Notifications">
          <li className={`${styles.rightSide} ${openNotification ? styles.active : styles.inActive}`} >
            <div
              onClick={handleClick}
              className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
              aria-controls={openNotification ? 'account-menu1' : undefined}
              aria-haspopup="true"
              aria-expanded={openNotification ? 'true' : undefined}
            >
              <span className={styles.bellDot}>
                <NewNotification
                  className={styles.settingHead}
                />
                <span className={styles.bellno}>
                  {normalAlertCount + (geNotificationMessageFromStorage()?.length ? geNotificationMessageFromStorage()?.length : 0)}
                </span>
              </span>
            </div>
          </li>
        </Tooltip >
        <Menu
          anchorEl={anchorEl1}
          id="account-menu1"
          open={openNotification}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
        >
          <NotificationComp setNormalAlertCount={setNormalAlertCount} normalAlertCount={normalAlertCount} type={"notifications"} notificationCout={notificationCout} fetchUserNotificationCount={fetchUserNotificationCount} />
        </Menu>
        <Tooltip title="View Broadcast Message">
          <li className={`${styles.rightSide} ${openBroadcast ? styles.active : styles.inActive}`} >
            <div
              onClick={handleClickBroadcast}
              className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
              aria-controls={openBroadcast ? 'account-menu2' : undefined}
              aria-haspopup="true"
              aria-expanded={openBroadcast ? 'true' : undefined}
            >
              <span className={styles.bellDot}>
                <NewBroadcastIcon
                  className={styles.settingHead}
                  onClick={() => {
                    localStorage.removeItem('broadcastMessage')
                  }}
                />
                <span className={styles.bellno}>
                  {getBroadCastMessageFromStorage()?.length ? getBroadCastMessageFromStorage()?.length : 0}
                </span>
              </span>
            </div>
          </li>
        </Tooltip>
        <Menu
          anchorEl={anchorEl3}
          id="account-menu2"
          open={openBroadcast}
          onClose={handleCloseBroadcast}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
        >
          <NotificationComp type={"notificationsBroadCast"} notificationCout={notificationCout} fetchUserNotificationCount={fetchUserNotificationCount} />
        </Menu>


        <Tooltip title="PDF to DOC Convertor">
          <li className={styles.rightSide}>
            <NewPdfIcon
              className={styles.settingHead}
              onClick={() => window.open('https://pdf2doc.com/')}
            />
          </li>
        </Tooltip>
        <Tooltip title="CTC Calculator">
          <li className={styles.rightSide} onClick={() => setshowhide(true)}>
            <CTCCalculator className={`${styles.settingHead} ${styles.ctcI} `} />
          </li>
        </Tooltip>
        <Tooltip title="Time Zone Convertor">
          <li className={styles.rightSide}>
            <TimeZoneConvertor
              className={styles.settingHead}
              onClick={() => window.open('https://www.worldtimebuddy.com/')}
            />
          </li>
        </Tooltip>

        {
          primaryRole === 'SuperAdmin' || primaryRole === 'Admin' ? (
            <Tooltip title="Setting">
              <li className={styles.rightSide}>
                <Setting
                  className={styles.settingHead}
                  onClick={changePassword}
                />
              </li>
            </Tooltip>
          ) : null
        }



        {
          primaryRole === 'SuperAdmin' || primaryRole === 'Admin' ? (
            <Tooltip title="Send Broadcast Message">
              <li className={styles.rightSide}>
                <SendBroadcast
                  className={styles.settingHead}
                  onClick={broadcastMessageData}
                />
              </li>
            </Tooltip>
          ) : null
        }


        <li>
          <div className={`${styles.link} ${styles.dropdowncut} `}>
            {profileData?.profileImage ? (
              <label htmlFor="input" className={` ${styles.image_upload} `}>
                <Avatar
                  className={`${styles.user_avatar_img} `}
                  src={profileData?.profileImage}
                />
              </label>
            ) : (
              <label htmlFor="input" className={`${styles.image_upload} `}>
                <Avatar
                  className={`${styles.anyProfile} ${styles.user_avatar_img} `}
                  src={
                    url
                      ? url
                      : 'http://peoplefy2.0-dev.S3.ap-south-1.amazonaws.com/UserImage/blob'
                  }
                />
              </label>
            )}
            <div className={styles.profileName}>
              <div className={styles.desc}>{user?.fullName}</div>
            </div>
            <div className={styles.dropdownContent}>
              <div className={styles.userSection}>
                <div>
                  {profileData?.profileImage ? (
                    <label htmlFor="input" className={styles.image_upload}>
                      <Avatar
                        className={`${styles.user_avatar} `}
                        src={
                          url
                            ? url
                            : 'http://peoplefy2.0-dev.S3.ap-south-1.amazonaws.com/UserImage/blob'
                        }
                      />
                    </label>
                  ) : (
                    <label htmlFor="input" className={styles.image_upload}>
                      <Avatar
                        className={`${styles.anyProfile} ${styles.user_avatar} `}
                        src={
                          url
                            ? url
                            : 'http://peoplefy2.0-dev.S3.ap-south-1.amazonaws.com/UserImage/blob'
                        }
                      />
                    </label>
                  )}
                </div>
                <div className="ml-2">
                  <div className={styles.desc}>
                    {user?.fullName}
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                onClick={() => ShowProfile('ViewProfile')}
              >
                <button className={styles.ViewProfile}>View Profile</button>
              </div>
              <ul className={styles.settingMenu}>
                <li onClick={() => ShowProfile('ChangePassword')}>
                  <Settings className={styles.NameI} /> Change Password
                </li>
                <li>
                  <NotificationsIcon className={styles.NameI} /> Notifications
                </li>
                <li onClick={handleClickOpen}>
                  <SideLogout className={styles.NameI} />
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul >
    </div >
  );

  const checkMatchPath = () => {
    return newRole?.filter((route: any) => matchPath(location.pathname, { path: route.path, exact: false })).length;
  }
  const [toastMessage, setToastMessage] = useState<any>(null);
  const [criticalMessage, setCriticalMessage] = useState<any>([]);
  const [toastNotificationMessage, setToastNotificationMessage] = useState<any>(null);

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const showNotification = (messageData: any) => {
    new Notification(messageData[0].Template);
  }

  const notificationCout = async () => {
    try {
      const responseData: any = await getDashboardNotificationCount(localStorage.getItem('primaryRole'));
      const { status, body } = responseData;
      if (status == 200) {
        localStorage.removeItem('notificationMessage');
        setNormalAlertCount(body.normalALert)
        setCriticalAlertCount(body.criticalALert)
      }
    } catch (error) {
      console.error(error)
    }
  };

  // const loadNotificationDataWS = async () => {
  //   const localStorageUserDetails = localStorage.getItem('user') || null;
  //   const userDetails = JSON.parse(localStorageUserDetails!);
  //   const socketUrl = `${baseURLWS}${userDetails.userId}`;
  //   const socketNotification = new WebSocket(socketUrl);
  //   socketNotification.onopen = (event) => {
  //     // console.log('socket notification open', event)
  //     socketNotification.send('Ping');
  //   };
  //   socketNotification.onclose = (event) => {
  //     console.info('Socket closed notification', event);
  //   };
  //   socketNotification.onerror = (error) => {
  //     console.error('socket error notification', error);
  //   };
  //   socketNotification.onmessage = (event) => {
  //     // console.log('socket notification message', event.data)
  //     const messageData: any = JSON.parse(event.data);
  //     if (messageData.length) {
  //       console.log('messageData======', messageData)
  //       fetchUserNotificationCount();
  //       localStorage.removeItem('notificationMessage');
  //       localStorage.setItem('notificationMessage', JSON.stringify(messageData));
  //       let msg = [];
  //       msg = msg ? [...msg, ...messageData.map(ele => ({ ...ele, isActive: true }))] : messageData.map(ele => ({ ...ele, isActive: true }));
  //       messageData.map(ele => {
  //         if (ele.IsCritical) {
  //           setToastMessage(prev => ({ ...prev, ele }));
  //         }
  //       })
  //       notificationCout();
  //       setToastNotificationMessage(msg);
  //       showNotification(messageData)
  //     }
  //   };
  // }
  // const websocketBroadCastData = () => {
  //   const socketBroadCast = new WebSocket(broadcastMessageSocketUrl);
  //   socketBroadCast.onopen = (event) => {
  //     // console.log('socket broadcast open', event);
  //     socketBroadCast.send('Ping');
  //   };
  //   socketBroadCast.onclose = (event) => {
  //     console.info('Socket closed', event);
  //   };
  //   socketBroadCast.onerror = (error) => {
  //     console.error('socket error', error);
  //   };

  //   socketBroadCast.onmessage = (event) => {
  //     // console.log('socket broadcast message', event.data);
  //     const messageData: any = JSON.parse(event.data);
  //     if (messageData.length) {
  //       fetchUserNotificationCount();
  //       let msg = [];
  //       msg = JSON.parse(localStorage.getItem('broadcastMessage')!);
  //       msg = msg ? [...msg, ...messageData.map(ele => ({ ...ele, isActive: true }))] : messageData.map(ele => ({ ...ele, isActive: true }));
  //       localStorage.setItem('broadcastMessage', JSON.stringify(msg));
  //       setToastMessage(msg);
  //     }
  //   };
  // };
  // useEffect(() => {
  //   if (user) {
  //     loadNotificationDataWS();
  //   }
  // }, [user]);


  const removeDuplicatesBy = (keyFn, array) => {
    var mySet = new Set();
    return array.filter(function (x) {
      var key = keyFn(x), isNew = !mySet.has(key);
      if (isNew) mySet.add(key);
      return isNew;
    });
  }

  useEffect(() => {
    if (user) {
      const localStorageUserDetails = localStorage.getItem('user') || null;
      const userDetails = JSON.parse(localStorageUserDetails!);
      const socketUrl = window.location.protocol === "http:" ? `ws${baseURLWS}${userDetails?.userId}` : `wss${baseURLWS}${userDetails?.userId}`;
      const socketNotification = new WebSocket(socketUrl);
      socketNotification.onopen = () => {
        socketNotification.send('Ping');
      }

      const apiInterval = 10000; // 10 seconds
      setInterval(() => socketNotification.send('Ping'), apiInterval);

      socketNotification.onmessage = (msg) => {
        const message = JSON.parse(msg.data)
        const messageData: any = JSON.parse(msg.data);
        if (messageData.length) {
          const withoutDuplicates = removeDuplicatesBy(x => x.Template, messageData);
          fetchUserNotificationCount();
          localStorage.removeItem('notificationMessage');
          localStorage.setItem('notificationMessage', JSON.stringify(withoutDuplicates));
          let msg = [];
          msg = msg ? [...msg, ...withoutDuplicates.map(ele => ({ ...ele, isActive: true }))] : withoutDuplicates.map(ele => ({ ...ele, isActive: true }));

          withoutDuplicates.map(ele => {
            if (ele.IsCritical) {
              // setToastMessage(prev => ({ ...prev, ele }));
              setCriticalMessage([ele]);
              showNotification(withoutDuplicates);
            }
          })
          notificationCout();
          setToastNotificationMessage(msg?.filter((ele: any) => !ele.IsCritical));
          showNotification(withoutDuplicates)
        }
      }

      socketNotification.onclose = () => {
      }

      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        // event.returnValue = '';
        socketNotification.close();
      });

      return () => {
        socketNotification.close()
      }
    }

  }, [user, primaryRole])

  // useEffect(() => {
  //   websocketBroadCastData();
  // }, []);
  const getBroadCastMessageFromStorage = () => {
    const message = JSON.parse(localStorage.getItem('broadcastMessage')!);
    return message;
  }

  const geNotificationMessageFromStorage = () => {
    const message = JSON.parse(localStorage.getItem('notificationMessage')!);
    return message;
  }

  useEffect(() => {
    if (primaryRole !== "") {
      notificationCout();
    }
  }, [primaryRole]);
  const handleBroadCastToastMessageClose = (item: any) => {
    let msg = JSON.parse(localStorage.getItem('broadcastMessage')!);
    console.log("before ", msg);
    msg = msg.map(ele => ({ ...ele, isActive: ele.Id === item.Id ? false : ele.isActive }));
    // let newMsg = msg.filter(ele => ele.Id !== item.Id)
    console.log("after ", msg)
    localStorage.setItem('broadcastMessage', JSON.stringify(msg));
    setToastMessage(msg);
  }

  const handleCriticalToastMessageClose = (item: any) => {
    // let msg = JSON.parse(localStorage.getItem('notificationMessage')!);
    console.log("criticalMessage ", criticalMessage)
    const newArray = criticalMessage.filter(ele => ele.Id !== item.Id);
    setCriticalMessage(newArray)
    // localStorage.setItem('notificationMessage', JSON.stringify(msg));
    // setCriticalMessage(msg);
  }

  const handleNotificationToastMessageClose = (item: any) => {
    // let msg = JSON.parse(localStorage.getItem('notificationMessage')!);
    // msg = msg.map(ele => ({ ...ele, isActive: ele.Id === item.Id ? false : ele.isActive }));
    // localStorage.setItem('notificationMessage', JSON.stringify(msg));
    // setToastNotificationMessage((prev: any) => prev.map(ele => ({ ...ele, isActive: ele.Id === item.Id ? false : ele.isActive })));
    setToastNotificationMessage(null);
  }
  const handleBroadCastToastMessageAutoClose = () => {
    let msg = JSON.parse(localStorage.getItem('broadcastMessage')!);
    const lastItem = toastMessage[toastMessage.length - 1];
    if (lastItem) {
      msg = msg.filter(ele => ({ ...ele, isActive: ele.Id === lastItem.Id ? false : ele.isActive }));
      localStorage.setItem('broadcastMessage', JSON.stringify(msg));
      setToastMessage((prev: any) => prev.map(ele => ({ ...ele, isActive: ele.Id === lastItem.Id ? false : ele.isActive })));
    } else {
      setToastMessage(null);
    }

  }

  const [openSubHHeaderMenu, setOpenSubHHeaderMenu] = useState<boolean>(false);

  return (
    <>
      {user ? (
        <div>
          {toastMessage && toastMessage.length && toastMessage?.filter(ele => ele.isActive)?.map((item: any, index: number) => {
            (<><Snackbar
              className={styles.snackBar}
              sx={{
                marginTop: index * 10,
                // color: "#000",
                // bgcolor: '#fff',
              }}
              // onClose={() => handleBroadCastToastMessageClose(item)}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              // autoHideDuration={5000}
              open={item.isActive}
              action={
                <Button color="inherit" size="small" onClick={() => handleBroadCastToastMessageClose(item)}>
                  X
                </Button>
              }
              message={<Alert
                severity="success">
                {item.Template}
              </Alert>}
              key="snackbar"
            /></>)
          })}

          {toastNotificationMessage && toastNotificationMessage.length ? (<>
            {toastNotificationMessage.map((item: any, index: number) => (<>
              <Snackbar
                className={styles.snackBar}
                sx={{
                  marginTop: index * 10,
                  // color: "#000",
                  // bgcolor: '#fff',
                }}
                onClose={() => handleNotificationToastMessageClose(item)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={4000}
                open={toastNotificationMessage.length}
                // action={
                //   <Button color="inherit" size="small" onClick={() => handleNotificationToastMessageClose(item)}>
                //     X
                //   </Button>
                // }
                message={<Alert
                  severity="success">
                  {item.Template}
                </Alert>}
                key="snackbar"
              />
            </>))}

          </>) : ''}

          {criticalMessage && criticalMessage.length ? (<>
            {criticalMessage.map((item: any, index: number) => (<>
              <Snackbar
                className={styles.snackBar}
                sx={{
                  marginTop: index * 10,
                  // color: "#000",
                  // bgcolor: '#fff',
                }}
                // onClose={() => handleCriticalToastMessageClose(item)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                // autoHideDuration={4000}
                open={criticalMessage.length}
                action={
                  <Button color="inherit" size="small" onClick={() => handleCriticalToastMessageClose(item)}>
                    X
                  </Button>
                }
                message={<Alert
                  severity="success">
                  {item.Template}
                </Alert>}
                key="snackbar"
              />
            </>))}

          </>) : ''}




          {/* {(getBroadCastMessageFromStorage()?.length ? getBroadCastMessageFromStorage()?.length: '') ? (<Snackbar className={styles.snackBar}
            onClose={() => setToastMessage((prev: any) => !prev)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={90000}
            open={Boolean(toastMessage && toastMessage.length)}
            message={<Alert onClose={() => setToastMessage((prev: any) => !prev)} severity="success">
              {getBroadCastMessageFromStorage()?.pop()?.Template}
            </Alert>}
            key="snackbar"
          />) : ''} */}
          <div className={styles.headBar}>
            <Navbar
              variant="dark"
              className={`${styles.appBar} `}
              expand={false}
            >
              <>
                <Box display="flex" justifyContent="space-between" width={isMobile ? "100%" : "unset"}>
                  <Box>
                    <Navbar.Brand className={styles.sfnlogo}>
                      {isMobile && (
                        <Box>
                          {(['left'] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                              <Button onClick={() => setOpenSubHHeaderMenu(!openSubHHeaderMenu)} sx={{ mr: 1, minWidth: 0, }}><MenuIcon sx={{ color: "#fff" }} /></Button>
                              <Drawer
                                anchor={anchor}
                                open={openSubHHeaderMenu}
                                onClose={() => setOpenSubHHeaderMenu(!openSubHHeaderMenu)}
                                sx={{ ".MuiDrawer-paper": { width: 250 } }}
                              >
                                {list(anchor)}
                              </Drawer>
                            </React.Fragment>
                          ))}
                        </Box>
                      )}
                      <Link
                        className="navbar-brand d-flex align-items-center"
                        to={`${primaryRole === "SuperAdmin" ? "/home" : `/user/${userDetail?.userId}/${primaryRole}/dashboard`}`}
                      >
                        <div className={styles.aerlogoBlack}>
                          <span className={styles.Peoplefy}>
                            People<span className={styles.textStyles}>fy</span>
                          </span>
                        </div>
                        <div className={styles.aerlogowhite}>
                          <span className={styles.Peoplefy}>
                            People<span className={styles.textStyles}>fy</span>
                          </span>
                        </div>
                      </Link>
                    </Navbar.Brand>
                  </Box>
                  {isMobile && (
                    <Box mr={2} display="flex" alignItems='center' className={`${styles.MainTopNav}`} sx={{ backgroundColor: "unset !important", overflow: "unset !important", justifyContent: "end !important" }}>

                      <Box className={`${styles.headIcons}`}>

                        <Tooltip title="Critical Alert">
                          {criticalAlertCount === 0 ?
                            <div className={`${styles.rightSide} ${openCritical ? styles.active : styles.inActive}`} >
                              <div
                                onClick={handleClickCritical}
                                className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
                                aria-controls={openCritical ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openCritical ? 'true' : undefined}
                              >
                                <span className={styles.bellDot}>
                                  <NewAlertIcon
                                    className={styles.settingHead}
                                  />
                                  <span className={styles.bellno}>
                                    {criticalAlertCount}
                                  </span>
                                </span>
                              </div>
                            </div>
                            :
                            <div className={`${styles.gifDiv} ${openCritical ? styles.active : styles.inActive}`} >
                              <div
                                onClick={handleClickCritical}
                                className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
                                aria-controls={openCritical ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openCritical ? 'true' : undefined}
                              >
                                <span className={styles.bellDotgif}>
                                  <img src={NotificationGif} className={styles.gifImg} />
                                  <span className={styles.bellno}>
                                    {criticalAlertCount}
                                  </span>
                                </span>
                              </div>
                            </div>}
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl2}
                          id="account-menu"
                          open={openCritical}
                          onClose={handleCloseCritical}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              }
                            },
                          }}
                        >
                          <NotificationComp type={"criticalAlert"} notificationCout={notificationCout} fetchUserNotificationCount={fetchUserNotificationCount} />
                        </Menu>

                        <Tooltip title="Notifications">
                          <div className={`${styles.rightSide} ${openNotification ? styles.active : styles.inActive}`} >
                            <div
                              onClick={handleClick}
                              className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
                              aria-controls={openNotification ? 'account-menu1' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openNotification ? 'true' : undefined}
                            >
                              <span className={styles.bellDot}>
                                <NewNotification
                                  className={styles.settingHead}
                                />
                                <span className={styles.bellno}>
                                  {normalAlertCount + (geNotificationMessageFromStorage()?.length ? geNotificationMessageFromStorage()?.length : 0)}
                                </span>
                              </span>
                            </div>
                          </div>
                        </Tooltip >
                        <Menu
                          anchorEl={anchorEl1}
                          id="account-menu1"
                          open={openNotification}
                          onClose={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                            },
                          }}
                        >
                          <NotificationComp fetchUserNotificationCount={fetchUserNotificationCount} setNormalAlertCount={setNormalAlertCount} normalAlertCount={normalAlertCount} type={"notifications"} notificationCout={notificationCout} />
                        </Menu>
                        <Tooltip title="View Broadcast Message">
                          <div className={`${styles.rightSide} ${openBroadcast ? styles.active : styles.inActive}`} >
                            <div
                              onClick={handleClickBroadcast}
                              className={`${styles.dropdown} ${styles.notification_dropdown_wrapper} `}
                              aria-controls={openBroadcast ? 'account-menu2' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openBroadcast ? 'true' : undefined}
                            >
                              <span className={styles.bellDot}>
                                <NewBroadcastIcon
                                  className={styles.settingHead}
                                  onClick={() => {
                                    localStorage.removeItem('broadcastMessage')
                                  }}
                                />
                                <span className={styles.bellno}>
                                  {getBroadCastMessageFromStorage()?.length ? getBroadCastMessageFromStorage()?.length : 0}
                                </span>
                              </span>
                            </div>
                          </div>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl3}
                          id="account-menu2"
                          open={openBroadcast}
                          onClose={handleCloseBroadcast}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible',
                              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                              mt: 1.5,
                              '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                            },
                          }}
                        >
                          <NotificationComp type={"notificationsBroadCast"} notificationCout={notificationCout} fetchUserNotificationCount={fetchUserNotificationCount} />
                        </Menu>
                      </Box >

                      <IconButton
                        size="large"
                        aria-label="display more actions"
                        color="inherit"
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClickMobile}
                        sx={{ p: 1 }}
                      >
                        <MoreIcon sx={{ color: "#fff" }} />
                      </IconButton>
                      <Box className={`${styles.headIcons}`}>
                        <div className={`${styles.link} ${styles.dropdowncut} `}>
                          {profileData?.profileImage ? (
                            <label htmlFor="input" className={` ${styles.image_upload} `}>
                              <Avatar
                                className={`${styles.user_avatar_img} `}
                                src={profileData?.profileImage}
                              />
                            </label>
                          ) : (
                            <label htmlFor="input" className={`${styles.image_upload} `}>
                              <Avatar
                                className={`${styles.anyProfile} ${styles.user_avatar_img} `}
                                src={
                                  url
                                    ? url
                                    : 'http://peoplefy2.0-dev.S3.ap-south-1.amazonaws.com/UserImage/blob'
                                }
                              />
                            </label>
                          )}

                          <div className={styles.dropdownContent}>
                            <div className={styles.userSection}>
                              <div>
                                {profileData?.profileImage ? (
                                  <label htmlFor="input" className={styles.image_upload}>
                                    <Avatar
                                      className={`${styles.user_avatar} `}
                                      src={
                                        url
                                          ? url
                                          : 'http://peoplefy2.0-dev.S3.ap-south-1.amazonaws.com/UserImage/blob'
                                      }
                                    />
                                  </label>
                                ) : (
                                  <label htmlFor="input" className={styles.image_upload}>
                                    <Avatar
                                      className={`${styles.anyProfile} ${styles.user_avatar} `}
                                      src={
                                        url
                                          ? url
                                          : 'http://peoplefy2.0-dev.S3.ap-south-1.amazonaws.com/UserImage/blob'
                                      }
                                    />
                                  </label>
                                )}
                              </div>
                              <div className="ml-2">
                                <div className={styles.desc}>
                                  {user?.fullName}
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-center"
                              onClick={() => ShowProfile('ViewProfile')}
                            >
                              <button className={styles.ViewProfile}>View Profile</button>
                            </div>
                            <ul className={styles.settingMenu}>
                              <li onClick={() => ShowProfile('ChangePassword')}>
                                <Settings className={styles.NameI} /> Change Password
                              </li>
                              <li>
                                <NotificationsIcon className={styles.NameI} /> Notifications
                              </li>
                              <li onClick={handleClickOpen}>
                                <SideLogout className={styles.NameI} />
                                Logout
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  )}
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl4}
                    open={open}
                    onClose={handleCloseMobile}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    sx={{ ".MuiList-root": { width: "100%" }, ".MuiMenuItem-root": { backgroundColor: "#185297" }, }}
                    className={styles.mobileShortmenu}
                  >
                    {primaryRole === 'SuperAdmin' ||
                      primaryRole === 'Admin' ||
                      primaryRole === 'Manager' ||
                      primaryRole === 'SPOC' ||
                      primaryRole === 'RPO' ||
                      primaryRole === 'InternalHR' ||
                      primaryRole === 'Recruiter' ||
                      primaryRole === 'QC' ||
                      primaryRole === 'AgencyRCR' || primaryRole === 'VP' ? (
                      <Tooltip title="Add Candidate">
                        <MenuItem className={styles.rightSide}>
                          <div onClick={addCandidate}>
                            <AddCandidate
                              className={styles.settingHead}
                            />Add Candidate
                          </div>
                        </MenuItem>
                      </Tooltip>) : null}
                    <Tooltip title="PDF to DOC Convertor">
                      <MenuItem onClick={handleCloseMobile} className={styles.rightSide}>
                        <div onClick={() => window.open('https://pdf2doc.com/')}>
                          <NewPdfIcon
                            className={styles.settingHead}
                          />PDF to DOC Convertor
                        </div>
                      </MenuItem>
                    </Tooltip>
                    <Tooltip title="CTC Calculator" className={styles.rightSide}>
                      <MenuItem onClick={handleCloseMobile}>
                        <div onClick={() => setshowhide(true)}>
                          <CTCCalculator className={`${styles.settingHead} ${styles.ctcI} `} /> CTC Calculator
                        </div>
                      </MenuItem>
                    </Tooltip>

                    <Tooltip title="Time Zone Convertor">
                      <MenuItem onClick={handleCloseMobile} className={styles.rightSide}>
                        <div onClick={() => window.open('https://www.worldtimebuddy.com/')}>
                          <TimeZoneConvertor
                            className={styles.settingHead}
                          />Time Zone Convertor
                        </div>
                      </MenuItem>
                    </Tooltip>

                    {
                      primaryRole === 'SuperAdmin' || primaryRole === 'Admin' ? (
                        <Tooltip title="Setting">
                          <MenuItem onClick={handleCloseMobile} className={styles.rightSide}>
                            <div onClick={changePassword}>
                              <Setting
                                className={styles.settingHead}
                              />Setting
                            </div>
                          </MenuItem>
                        </Tooltip>
                      ) : null
                    }
                    {
                      primaryRole === 'SuperAdmin' || primaryRole === 'Admin' ? (
                        <Tooltip title="Send Broadcast Message">
                          <MenuItem onClick={handleCloseMobile} className={styles.rightSide}>
                            <div onClick={broadcastMessageData}>
                              <SendBroadcast
                                className={styles.settingHead}
                              />Send Broadcast Message
                            </div>
                          </MenuItem>
                        </Tooltip>
                      ) : null
                    }
                  </Menu>
                </Box>
              </>
              {user && topHeaderIcons(styles.MainTopNav)}

            </Navbar>
            <div className={styles.subheaderbar}>
              {user?.role && (
                <ul>
                  {role?.map((e: any, index: any) => activeClass(e, index))}
                </ul>
              )}
            </div>
          </div>
        </div>
      ) : null}

      <div
        className={`d - flex align - items - stretch ${styles.sfnPageRoutes} `}
      >
        <div
          className={`${styles.sfnMain} ${user ? styles.sfnLoggedInMain : styles.sfnLoggedOutMain
            } `}
        >
          <div
            className={`${styles.layouPadd} `}
            onClick={() => setNotification(false)}
          >
            <Switch>
              {!user &&
                unsecureRoutes.map(({ path, Component }, index) => (
                  <Route
                    exact
                    key={index}
                    path={path}
                    render={() => <ErrorBoundary><Component /></ErrorBoundary>}

                  />
                ))}
              {newRole
                .filter((route: any) => matchPath(location.pathname, { path: route.path, exact: true }))
                .map(({ path, Component }: any, index: number) => (
                  <>
                    <Route
                      exact
                      key={index}
                      path={path}
                      render={() => <ErrorBoundary><Component /></ErrorBoundary>}
                    />
                  </>
                ))}
              {checkMatchPath() === 0 ? (<><Route render={() => <NotFound />} /></>) : (<><Route render={() => <NotFound />} /></>)}
            </Switch>
          </div>
          <ModalComponent
            show={modalShow}
            modalInfo={modalInfo}
            onHideModal={hideModal}
            onHide={hideModal}
          />
        </div>
      </div>

      {showhide && <Modal
        centered
        size="xl"
        show={showhide}
        onHide={() => {
          setshowhide(false);
          clearForm();
        }}
        className={`${styles.modalCustomcard} ${styles.addUserPopupWrapper1} `}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <h4 className={styles.commonTitle}>CTC Calculator </h4>
        </Modal.Header>
        <Modal.Body>
          <NormalTextField
            className={styles.noramalF}
            label_text={'Current CTC (LPA) :'}
            placeholder={'Current CTC (LPA) :'}
            name="currentCtc"
            // type="number"
            handleChange={(event: any) => {
              const value = event.target.value;
              if (value !== "" && !numberRegix.test(value)) {
                return;
              }
              handleChangeCurrentCtc(event);
            }}
            value={currentCtc}
          ></NormalTextField>
          {/* <TextField
            className={styles.textF}
            onChange={(e: any) => {
              const value = e.target.value;
              if (value !== "" && !numberRegix.test(value)) {
                return;
              }
              handleChangeCurrentCtc(e);
            }}
            label={'Current CTC (LPA) :'}
            placeholder={'Current CTC (LPA) :'}
            name="currentCtc"
            type="text"
            variant="outlined"
            value={currentCtc}
            autoComplete="off"
          /> */}
          <div className={styles.customDiv}></div>
          <div className={styles.label}>
            {loadPresetValuesOfCTChike(parseFloat(currentCtc))}
            {expectedCTCChange()}
          </div>
          <div className={styles.customDiv}></div>
          <div>
            <p className="text-dark">Hike Expectation</p>
            <NormalTextField
              className={styles.noramalF}
              label_text={'Current CTC (LPA) :'}
              placeholder={'Current CTC1 (LPA) :'}
              name="currentCtc"
              // type="number"
              value={currentCtc}
              handleChange={(event: any) => {
                const value = event.target.value;
                if (value !== "" && !numberRegix.test(value)) {
                  return;
                }
                handleChangeCurrentCtc(event);
              }}
            ></NormalTextField>
            <div className={styles.customDiv}></div>
            <NormalTextField
              className={styles.noramalF}
              label_text={'Expected CTC (LPA) :'}
              name="expectedCtc"
              // type="number"
              value={expectedCtc}
              handleChange={(event: any) => {
                const value = event.target.value;
                if (value !== "" && !numberRegix.test(value)) {
                  return;
                }
                handleChangeExpectedCtc(event);
              }}
            ></NormalTextField>
            <div className={styles.customDiv}></div>
            <NormalTextField
              className={styles.noramalF}
              label_text={'% Hike Expected :'}
              name="expectedHikePerc"
              // type="number"
              value={expectedHike}
              handleChange={(event: any) => {
                const value = event.target.value;
                if (value !== "" && !numberRegix.test(value)) {
                  return;
                }
                handleChangeExpectedHike(event);
              }}
            ></NormalTextField>
          </div>
          <div className={styles.customDiv}></div>
          <div className={styles.modalPrimarymodal}>
            <PrimaryButton
              className={styles.Interbtn}
              method={() => { setshowhide(false); clearForm() }}
              text={'Close'}
            />
          </div>
        </Modal.Body>
      </Modal>}

      {addCandidateModal && (<AddEditSingleRole
        updateTable={handleDataUpdate}
        show={addCandidateModal}
        handleClose={() => setAddCandidateModal(false)}
      />)}
      {changePasswordModal && (<ChangePasswordModal
        show={changePasswordModal}
        handleClose={() => setChangePasswordModal(false)}
      />)}
      {broadcastMessageModal && (<BoradcastMessageModal
        show={broadcastMessageModal}
        handleClose={() => setBroadcastMessageModal(false)}
      />)}
    </>
  );
};
export default PageRoutes;
