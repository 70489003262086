import { useState } from 'react';
import { IconButton, TextField, InputAdornment } from '@material-ui/core';
import styles from './index.module.scss';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { FieldProps } from '../../types/index';

const PasswordTextField = ({
  name,
  label_text,
  required,
  error,
  touched,
  value,
  handleChange,
  handleBlur,
  className

}: FieldProps) => {
  const [focused] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePassword = () =>
    setTimeout(() => setShowPassword((prev) => !prev), 10);

  return (
    <div className={`${styles.commonInputfields} ${className ? className : ''}`}>
      <div
        className={`${focused
          ? styles.touched_label_text
          : error && touched
            ? styles.error_label_text
            : styles.label_text
          }`}
      >
        {label_text}
      </div>
      <TextField
        fullWidth
        id={name}
        label={label_text}
        type={showPassword ? 'text' : 'password'}
        onChange={handleChange}
        onBlur={
          handleBlur
        }
        helperText={touched ? error : ''}
        error={touched && Boolean(error)}
        value={value}
        required={required}
        margin="dense"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={styles.basicInt}>
              <IconButton
                className={styles.visibilityIcons}
                aria-label="toggle password visibility"
                onClick={togglePassword}
              >
                {showPassword ? (
                  <p style={{ margin: 0 }}>
                    <VisibilityIcon className={styles.visibilityIcons} />{' '}
                  </p>
                ) : (
                  <p style={{ margin: 0 }}>
                    <VisibilityOffIcon className={styles.visibilityIcons} />
                  </p>
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default PasswordTextField;
