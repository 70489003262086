import { hot } from 'react-hot-loader/root'
import PageRoutes from './pageRoutes'
import { useLocation } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import { Suspense, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from './_store/reducer/rootReducer'
import { save } from './_store/actions/user'
import Cookies from 'js-cookie'
import { setPathPage } from './_store/actions/pathPage'
import { SUCCESS_MESSAGE, } from './_store/constants/message'
import { sidebarItemsMap } from './widgets/SideBar/sidebarItems'
import styles from './App.module.scss'
import Loader from './widgets/loader'

interface IState {
  messageId: any,
  user: any
  open: boolean
}
const App = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  let [message] = useSelector((state: RootState) => [state.message]);
  const [initialStates, setInitialStates] = useState<IState>({
    messageId: '',
    user: null,
    open: false
  })

  const handlePathChange = async () => {
    let newPathPage
    if (initialStates.user) {
      sidebarItemsMap?.get(initialStates.user?.role)?.forEach((item) => {
        if (item.path === location.pathname) {
          newPathPage = { itemPathPage: item.path }
          return
        }
        item?.subItems?.forEach((subItem) => {
          if (subItem.path === location.pathname) {
            newPathPage = { itemPathPage: item.title, subItemPathPage: subItem.path }
            return
          }
        })
      })
      dispatch(setPathPage(newPathPage))
    }
  }

  useEffect(() => {
    handlePathChange()
  }, [location?.pathname])

  useEffect(() => {
    // @ts-ignore
    if (message.id && initialStates.messageId !== message.id) {
      // @ts-ignore
      setInitialStates((prev: any) => ({
        ...prev,
        open: true,
        messageId: message.id
      }))
    }
  }, [message])

  useEffect(() => {
    if (localStorage.getItem('user') && Cookies.get('token')) {
      dispatch(save(JSON.parse(localStorage.getItem('user')!)))
    }
    else if (!localStorage.getItem('user') && !Cookies.get('token')) {
      dispatch(save(JSON.parse(localStorage.getItem('user')!)))
    }
  }, [localStorage.getItem('user'), Cookies.get('token')])

  return (
    <Suspense fallback={<Loader />}>
      <Snackbar
        onClose={() => setInitialStates((prev: IState) => ({ ...prev, open: false }))
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={4000}
        open={initialStates.open}
        message={message ? message.message : 'message'}
        key='snackbar'
        // @ts-ignore
        className={`${message.type === SUCCESS_MESSAGE ? styles.snackbar_success : styles.snackbar_error}`}
      />
      <div className="loader">
        <div className="loaderBox">
          <div className="loaderRound"></div>
          &nbsp;PEOPLEFY
        </div>
      </div>
      <PageRoutes />
    </Suspense>
  )
}

export default hot(App)