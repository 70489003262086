import { REMOVE_USERS_STATE, SET_USERS_STATE, SET_ALL_USERS_FILTERS } from '_store/constants/filterData_users';

interface IPayload {
  type: string;
  data: any
}
export const setAllUserFilters = (payload: IPayload) => (dispatch: Function) => {
  dispatch({ type: SET_ALL_USERS_FILTERS, payload })
}
export const setUsersData = (payload: IPayload) => (dispatch: Function) => {
  dispatch({ type: SET_USERS_STATE, payload })
}

export const resetUsersData = (payload) => (dispatch: Function) => {
  dispatch({ type: REMOVE_USERS_STATE, payload })
}