
import { REMOVE_BULK_TEAM_ALLOCATION_STATE, SET_BULK_TEAM_ALLOCATION_STATE, SET_ALL_BULK_TEAM_ALLOCATION_STATE } from '_store/constants/filterData_BulkTeamAllocation';
import Action from '../../types/action';

const initialState = {
  contactNo: '',
  firstName: '',
  lastName: '',
  listScreenForOrganization: [],
  screenedForOrganization: [],
  candidateStatus: [],
  organizations: [],
  positions: [],
  currentEmployeer: [],
  functions: [],
  gender: [],
  industry: [],
  internalPositionName: [],
  listofOrganizations: [],
  minExp: '',
  maxExp: '',
  minCtc: '',
  maxCtc: '',
  noticePeriod: '',
  preferedJobCity: [],
  primarySkill: [],
  resumeSourceFolder: '',
  screenedByRecruiter: [],
  screenedFrom: null,
  date: new Date(),
  statuss: { item: 'Not Allocated' },
}
const filterData_BulkTeamAllocation = (state = initialState, action: Action) => {
  switch (action.type) {

    case SET_BULK_TEAM_ALLOCATION_STATE:
      localStorage.setItem('bulkTeamAllocationFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
    case SET_ALL_BULK_TEAM_ALLOCATION_STATE:
      localStorage.setItem('bulkTeamAllocationFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
      case REMOVE_BULK_TEAM_ALLOCATION_STATE:
        localStorage.setItem('bulkTeamAllocationFilter', JSON.stringify(initialState));
        return initialState;
    default:
      return state
  }
}
export default filterData_BulkTeamAllocation