import Action from '../../types/action';
import { SET_NOTIFICATION_DATA } from '../constants';
export interface INotification {
    count: number;
    data: any;
}
const initialState = {
    count: 0,
    data: []
}

const notificationCountData = (state: INotification = initialState, action: Action) => {
    switch (action.type) {
        case SET_NOTIFICATION_DATA:
            const c = !state.data.some(item => action.payload.data.every(ele => ele.id === item.id))
            return {
                ...state,
                count: action.payload.count,
                data: c ? action.payload.data : state.data
            }
        default:
            return state
    }
}
export default notificationCountData