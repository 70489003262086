import React from "react";
import { withRouter } from "react-router-dom";
import Error from "./utilities/images/error.jpg"
class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  componentDidCatch (error, info) {
    this.setState({ hasError: true, error: error.toString() });
  }
  goBack () {
    this.props.history.go(0);
  }
  render () {
    if (this.state.hasError) {
      return (<>
      <div className="errorBound">
        <img src={Error} alt="error message"/>
        <div className="d-flex flex-column">
          <h3>An error has occured. </h3>
          <div className="d-flex justify-content-center">
              <button className="BackBtn" onClick={() =>  this.goBack()}>Go Back</button>
          </div>
        </div>
      </div>
      </>)
    }

    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);