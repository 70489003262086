import styles from './index.module.scss';
import { useFormik, FormikProvider } from 'formik';
import { decriptionYup, fullNameYup } from 'utilities/yupObjects';
import NormalTextField from 'widgets/NormalTextField';
import PrimaryButton from 'widgets/PrimaryButton';
import * as Yup from 'yup';
import { AddUser, Createuser } from '../../utilities/images/icons/index';
import { useEffect } from 'react';
import Icon from 'widgets/IconComponent';
import TextArea from 'widgets/TextArea';

const CreateOrEditIDPComponent = (props) => {
  useEffect(() => {
    if (props.resetForm) {
      formik.resetForm();
    }
  }, [props.resetForm]);

  const loginSchema = Yup.object().shape({
    name: fullNameYup,
    description: decriptionYup,
  });

  const formik = useFormik({
    initialValues: {
      id: props.modalIdentity === 'editIDP' ? props.editObj.id : '',
      name: props.modalIdentity === 'editIDP' ? props.editObj.name : '',
      description: props.modalIdentity === 'editIDP' ? props.editObj.description : '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      props.accountChange(values);
    },
  });

  return (
    <>
      <Createuser className={styles.modalTopIcon} />
      <h5 className={styles.commonTitle}>
        <strong>{props.modalIdentity === 'createIDP' ? 'Create' : 'Edit'}</strong>&nbsp;<span>IDP</span>
      </h5>
      <FormikProvider value={formik}>
        <form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.handleSubmit();
            }
          }}
        >
          <NormalTextField
            error={formik.errors.name}
            touched={formik.touched.name}
            placeholder="IDP name"
            name="name"
            type="name"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.name}
            startAdor={<Icon icon={<AddUser />} />}
          />
          <div className={styles.customDiv}></div>
          <TextArea
            error={formik.errors.description}
            touched={formik.touched.description}
            className={styles.descriptionBox}
            placeholder="IDP Description"
            name="description"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.description}
          />

          <div className={styles.button}>
            <PrimaryButton
              text={`${props.modalIdentity === 'createIDP' ? 'Save' : 'Update' }`}
              method={formik.handleSubmit}
              className={styles.modalBtn}
            />
          </div>
        </form>
      </FormikProvider>
    </>
  );
};

export default CreateOrEditIDPComponent;
