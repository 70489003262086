import { REMOVE_LOADER_STATE, SET_ALL_LOADER_STATE, SET_LOADER_STATE } from '_store/constants/loader';
import Action from '../../types/action';
  
  const initialState = {
    urls: null,
    count: 0
  };
  const filterData_Loader = (state = initialState, action: Action) => {
    switch (action.type) {
      case SET_LOADER_STATE:
        return { ...state, [action.payload.type]: action.payload.data };
        break;
        case SET_ALL_LOADER_STATE:
          return { ...state, ...action.payload };
          break;
      case REMOVE_LOADER_STATE:
        return initialState;
        break;
      default:
        return state;
    }
  };
  export default filterData_Loader;
  