import { REMOVE_POSITION_STATE, SET_POSITION_STATE, SET_ALL_POSITION_FILTERS } from '_store/constants/filterData_position';

interface IPayload {
  type: string;
  data: any
}

export const setAllPositionFilters = (payload: IPayload) => (dispatch: Function) => {
  dispatch({ type: SET_ALL_POSITION_FILTERS, payload })
}

export const setPositionData = (payload: IPayload) => (dispatch: Function) => {
  dispatch({ type: SET_POSITION_STATE, payload })
}

export const resetPositionData = () => (dispatch: Function) => {
  dispatch({ type: REMOVE_POSITION_STATE })
}
