import api from '_store/apis/api';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'widgets/PrimaryButton';
import { path } from '../../pageRoutes/routers';
import { Createuser } from '../../utilities/images/icons/index';
import styles from './index.module.scss';

const LogoutComponent = (props) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user')!);
  const handleYes = async () => {
    try {
      const res = await api.post(`api/logout/${user.userId}`)
      const currentRoute: any = localStorage.getItem('currentRoute');
      localStorage.clear();
      localStorage.setItem('currentRoute', currentRoute);
      Cookies.remove('token')
      Cookies.remove('Tabvalue')
      Cookies.remove('primaryRole')
      Cookies.remove('positionTracking')
      history.push(path.Login);
      window.location.href = '/login'
      props.hide()
    } catch (error) {

    }
  }

  return (

    <div className={`${styles.invitemodal} ${styles.centerContentModal}`}>
      <Createuser className={styles.modalTopIcon} />
      <h5 className={styles.commonTitle}>
        <strong>Confirm</strong>&nbsp;<span>Logout</span>
      </h5>
      <h6>Are You Sure?</h6>
      <div className="d-flex justify-content-center align-items-center mt-3">
        <div className={`m-0 ${styles.button}`}>
          <PrimaryButton
            className="m-2"
            method={props.hide}
            text="No"
          />
        </div>
        <PrimaryButton
          className="m-2"
          text={'Yes'}
          method={() => handleYes()}
        />
      </div>
    </div>

  );
};

export default LogoutComponent;