import styles from './index.module.scss';
import { useFormik, FormikProvider } from 'formik';
import { emailYup, firstNameYup, lastNameYup, passwordYup, 
 } from 'utilities/yupObjects';
import NormalTextField from 'widgets/NormalTextField';
import PrimaryButton from 'widgets/PrimaryButton';
import * as Yup from 'yup';
import React, {
  useEffect,
  useState
} from 'react';
import SelectField from 'widgets/SelectField';
import PasswordTextField from '../../widgets/PasswordTextField';
import { allAccountRoles } from '_store/apis/accountDetailsAPI';

const AddUserModal = (props) => {
  useEffect(() => {
    if (props.resetForm) {
      formik.resetForm();
    }
    fetchRoles();
    if (props.modalIdentity === 'AddUser') {
      setTextField(true);
    }
  }, [props.resetForm]);
  const [listofRoles, setListofRoles] = React.useState([]);
  const [selectIdpSp, setSelectIdpSp] = useState({ item: '', id: '' });
  const [textField, setTextField] = useState<boolean>(false);

  const fetchRoles = async () => {
    try {
      const payload = `offset=${1}&limit=${1000}`;
      const data = await allAccountRoles(payload);
      const { status, body }: any = data;
      if (status === 200) {
        const data = body;

        setListofRoles(
          data.map((e) => {
            return {
              item: e.name,
              id: e.roleId,
            };
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loginSchema = Yup.object().shape({
    firstName: firstNameYup,
    lastName: lastNameYup,
    email: emailYup,
    password: passwordYup ,
    confirm_password: passwordYup.oneOf(
      [Yup.ref('password'), null],
      'Passwords didn’t match. Please try again.'
    ),
  });

  const editSchema = Yup.object().shape({
    firstName: firstNameYup,
    lastName: lastNameYup,
    email: emailYup,
  });

  const formik = useFormik({
    initialValues: {
      id: props.modalIdentity === 'editUser' ? props.editObj.userId : '',
      firstName: props.modalIdentity === 'editUser' ? props.editObj.firstName : '',
      lastName: props.modalIdentity === 'editUser' ? props.editObj.lastName : '',
      email: props.modalIdentity === 'editUser' ? props.editObj.email : '',
      roleId: props.modalIdentity === 'editUser' ? props.editObj.roleId : '',
      password: '',
      confirm_password: '',
    },
    validationSchema:  props.modalIdentity === 'AddUser' ? loginSchema: editSchema,
    onSubmit: (values) => {
      props.accountChange(values);
    },
  });

  
  const handleRoleChange = (value) => {
    setSelectIdpSp(value);
  }

  return (
    <>

      <h5 className={styles.commonTitle}>
      <strong>{props.modalIdentity === 'AddUser' ? 'Add' : 'Edit'}</strong>&nbsp;<span>User</span>
      </h5>
      <FormikProvider value={formik}>
        <form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              formik.handleSubmit();
            }
          }}
        >
          <div className={styles.commonLabel}>
            <div className={styles.innerFields}>
              <div className={'row'}>
                <div className={'col-lg-6 col-md-12 col-sm-12 col-12'}>
                  <label>First Name</label>
                  <NormalTextField
                    error={formik.errors.firstName}
                    touched={formik.touched.firstName}
                    placeholder="First Name"
                    name="firstName"
                    type="text"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />
                </div>
                <div className={'col-lg-6 col-md-12 col-sm-12 col-12'}>
                  <label>Last Name</label>
                  <NormalTextField
                    error={formik.errors.lastName}
                    touched={formik.touched.lastName}
                    placeholder="Last Name"
                    name="lastName"
                    type="text"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                </div>
              </div>
            </div>
            <div className={styles.customDiv}></div>
            <label>Work Email</label>
            <NormalTextField
              error={formik.errors.email}
              touched={formik.touched.email}
              placeholder="Email"
              name="email"
              type="email"
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <div className={styles.customDiv}></div>
            <label>Role (S)</label>
            <SelectField
              className={styles.filter_select_field}
              placeholder="Select Role"
              attributeName="item"
              name="role"
              options={listofRoles}
              value={selectIdpSp}
              methodHandleChange={(value) => {
                handleRoleChange(value)
                formik.values.roleId = value.id
              }}
            />
            <div className={styles.customDiv}></div>
            {textField && props.modalIdentity === 'AddUser' && (
              <div>
            <label>Set Password</label>
            <PasswordTextField
              placeholder="password"
              name="password"
              error={formik.errors.password}
              touched={formik.touched.password}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={formik.values.password}
            />
             </div>
               )}   
             {textField && props.modalIdentity === 'AddUser' && (
              <div>
            <div className={styles.customDiv}></div>
            <label>Confirm Password</label>
            <PasswordTextField
              placeholder="Confirm your password"
              name="confirm_password"
              error={formik.errors.confirm_password}
              touched={formik.touched.confirm_password}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              value={formik.values.confirm_password}
            />
            </div>
               )}   
            <div className={styles.button}>
              <PrimaryButton
               text={`${props.modalIdentity === 'AddUser' ? 'Add' : 'Update' }`}
                method={formik.handleSubmit}
                className={styles.modalBtn}
              />
            </div>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};

export default AddUserModal;
