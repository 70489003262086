import { dropdownInitialState } from '../../containers/system_admin/Organizations/filterTypes';


import {
  SET_ORG_STATE, REMOVE_ORG_STATE, SET_ALL_ORG_FILTERS
} from '_store/constants/filterData_org';
import Action from '../../types/action';

const initialState = {
  listOfVerticalOrg: [],
  verticals: [],
  listOfPfySpocOrg: [],
  pfySpocs: [],
  listOfCityOrg: [],
  cities: [],
  listOfReqClosedInLastOrg: [],
  requirementClosedInLast: [],
  openPosition: [],
  organizationType: dropdownInitialState,
  name: '',
  followedUpAfter: null,
  followedUpBefore: null,
  listOfSkillOrg: [],
  skills: [],
  listOfProficiencyOrg: [],
  proficiencies: [],
  listOfCvUploadedListOrg: [],
  selectedCvUploadedListOrg: [],
  listOfOpenPositionsOrg: [],
  listOfPotentialOrg: [],
  potentials: [],
  listOfPriorityOrg: [],
  priority: [],
  currentScope: '',
  pitchToCandidates: '',
  clientOnBoardAfter: null,
  clientOnBoardBefore: null,
  clientStatus: [],
  listofcvsUploadedInLasts: [],
  cvsUploadedInLast: [],
  vendorEmpMonth: [],
}
const filterData_org = (state = initialState, action: Action) => {
  switch (action.type) {

    case SET_ORG_STATE:
      localStorage.setItem('orgFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
    case SET_ALL_ORG_FILTERS:
      return { ...state, ...action.payload };
      break;
    case REMOVE_ORG_STATE:
      localStorage.setItem('orgFilter', JSON.stringify(initialState));
      return initialState;
      break;
    default:
      return state
      break;
  }
}
export default filterData_org