import api from './api';
import {
  apiCandidateEdit,
  candidateActivePosition,
  candidateCvList,
  candidatedownload,
  candidateFolloUp,
  candidateMask,
  candidateMaskSendToQc,
  candidateMaskSkillList,
  parsedEducationDetails,
  candidatePFYList,
  candidatePFYPositionList,
  candidatescheduleinterviewdetailList,
  candidateSentToQcSelectedNameList,
  candidateSentToQcTabInfo,
  candidateStatusList,
  candidateStatusUpdate,
  candidateStatusUpdateListApi,
  checklistFileDelete,
  checkListFileDownload,
  checkListFileGet,
  checklistFileUpload,
  joinibilyScore,
  selectedCandidateChecklist,
  sendToQcPost,
  communicationQcDoneBy,
  candidatescheduleinterviewavailableSlot,
  candidateEmailCheckUrl,
  exceptionReasonUrl,
  screenSheetList,
  stopScreening,
  qcPassStatusToScheduleInterviewUrl,
  checkrecruiterbackupRecruiteraccess,
  candidateFolloUp1Day,
  candidateFolloUp2Hours,
  todoUpdateStatusJoinedCandidateLog,
  getvendorDetails,
  postVendorDetails,
  userAccessibility,
} from './urls';

export const candidateCVAPI = async (payload: any) => {
  try {
    return await api
      .get(`${candidateCvList}/${payload}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const candidateActivePositionAPI = async (payload: any) => {
  try {
    return await api
      .get(`${candidateActivePosition}/${payload}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const candidatePutAPI = async (payload: any) => {
  try {
    return await api
      .put(apiCandidateEdit, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const candidatedownloadapi = async (values) => {
  try {
    return await api
      .get(`${candidatedownload}/${values.downloadId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const candidateMaskGetAPI = async (values: any) => {
  try {
    return await api
      .get(`${candidateMask}/${values.candidateId}/${values.positionId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const candidateMaskSkillListAPI = async (values: any) => {
  try {
    return await api
      .get(`${candidateMaskSkillList}/${values.candidateId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const candidateGetPFYList = async (values: any) => {
  try {
    return await api
      .get(`${candidatePFYList}/${values}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const getVendorInterviewDetails = async (values: any) => {
  try {
    return await api
    .post(getvendorDetails, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const addVendorInterviewDetails = async (values: any) => {
  try {
    return await api
    .post(postVendorDetails, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const candidateMaskedSendToQcAPI = async () => {
  try {
    return await api
      .get(`${candidateMaskSendToQc}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const candidatescheduleinterviewdetailListAPI = async (
  payload: Object
) => {
  try {
    return await api
      .post(candidatescheduleinterviewdetailList, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const candidatescheduleinterviewavailableSlotAPI = async (
  payload: Object
) => {
  try {
    return await api
      .post(candidatescheduleinterviewavailableSlot, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const marskInfoPutAPI = async (payload: any) => {
  try {
    return await api
      .put(candidateMask, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const getCandidateSentToQcTabInfo = async (values) => {
  try {
    return await api
      .get(
        `${candidateSentToQcTabInfo}/${values.candidateId}/${values.positionId}`
      )
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const getCandidateSentToQcSelectedNameListAPI = async (values) => {
  try {
    return await api
      .get(`${candidateSentToQcSelectedNameList}/${values.positionId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const sendToQcPostAPi = async (payload: any, role: any) => {
  try {
    return await api
      .post(`${sendToQcPost}/${role}`, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const getCandidateStatusUpdateList = async (values) => {
  try {
    return await api
      .post(`${candidateStatusUpdateListApi}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const getSelectedCandidateChecklistApi = async (values) => {
  try {
    return await api
      .get(
        `${selectedCandidateChecklist}/${values.candidateId}/${values.positionId}`
      )
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const postCandidateCheckListAPI = async (values) => {
  try {
    return await api
      .post(`${selectedCandidateChecklist}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const checkrecruiterbackupRecruiteracces = async (values) => {
  try {
    return await api
      .post(`${checkrecruiterbackupRecruiteraccess}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const checkIsAccessibleOrNot = async (values) => {
  try {
    return await api
      .post(`${userAccessibility}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const getCandidateStatusApi = async (values) => {
  try {
    return await api
      .get(`${candidateStatusList}/${values.candidateId}/${values.positionId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const postCandidateStatus = async (values) => {
  try {
    return await api
      .post(`${candidateStatusUpdate}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const getCandidateJoinibilyScrore = async (values) => {
  try {
    return await api
      .get(
        `${joinibilyScore}/${values.candidateId}/${values.positionId}/${values.organizationId}/${values.useName}`
      )
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const joiniblityPutAPi = async (role, values) => {
  try {
    return await api
      .put(`${joinibilyScore}/${role}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const candidateCommunicationQcDoneByAPI = async () => {
  try {
    return await api
      .get(`${communicationQcDoneBy}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const checklistFileUploadAPI = async (values) => {
  try {
    return await api
      .post(`${checklistFileUpload}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const checkListFileGetAPI = async (values) => {
  try {
    return await api
      .get(`${checkListFileGet}/${values.candidateId}/${values.positionId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const checkListFileDownloadAPI = async (values) => {
  try {
    return await api
      .get(
        `${checkListFileDownload}/${values.candidateId}/${values.positionId}/${values.documentType}`
      )
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const checklistFileDeleteAPI = async (values) => {
  try {
    return await api
      .delete(`${checklistFileDelete}`, { data: values })
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const followUpPostAPI = async (values) => {
  try {
    return await api
      .post(`${candidateFolloUp}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const InterviewfollowUp1DayPostAPI = async (values) => {
  try {
    return await api
      .post(`${candidateFolloUp1Day}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const TodoJoinedCandidateLogs = async (values) => {
  try {
    return await api
      .post(`${todoUpdateStatusJoinedCandidateLog}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const InterviewfollowUp2HoursPostAPI = async (values) => {
  try {
    return await api
      .post(`${candidateFolloUp2Hours}`, values)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const followUpGetAPI = async (values) => {
  try {
    return await api
      .get(`${candidateFolloUp}/${values}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};

export const candidateEmailCheck = async (payload) => {
  try {
    return await api
      .post(`${candidateEmailCheckUrl}`, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};


export const exceptionReasonsGet = async (payload) => {
  try {
    return await api
      .get(`${exceptionReasonUrl}/${payload.candidateId}/${payload.positionId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const getCandidateScreenSheetListAPI = async (values) => {
  try {
    return await api
      .get(`${screenSheetList}/${values.candidateId}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const stopScreeningAPI = async (payload, loggedInUserRoleName) => {
  try {
    return await api
      .post(`${stopScreening}/${loggedInUserRoleName}`, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const qcPassStatusToScheduleInterviewAPI = async (values) => {
  try {
    return await api
      .get(
        `${qcPassStatusToScheduleInterviewUrl}/${values.candidateId}/${values.positionId}`
      )
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
export const candidateParsedEducationDetailsAPI= async (values: any) => {
    try {
        return await api.get(`${parsedEducationDetails}/${values.candidateId}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidateGetPFYPositionListAPI = async (values: any) => {
  try {
    return await api
      .get(`${candidatePFYPositionList}/${values}`)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};
