import { SideHome } from '../../utilities/images/icons/index'
import { path } from '../../pageRoutes/routers'
import { Role } from '../../types/index'


const accountAdmin = [
    {
        Icon: SideHome,
        title: 'Home',
        path: path.CustomerHome,
        hasMenu: false,
    },
]

const superAdmin = [
    {
        Icon: SideHome,
        title: 'Home',
        path: path.CustomerHome,
    },
    {
        Icon: SideHome,
        title: 'Users',
        path: path.Users,
    },
    {
        Icon: SideHome,
        title: 'Organizations',
        path: path.Organizations,
    },
    {
        Icon: SideHome,
        title: 'Positions',
        path: path.Positions,
    },
    {
        Icon: SideHome,
        title: 'Wiki',
        path: path.Wiki,
    },
    {
        Icon: SideHome,
        title: 'Candidates',
        path: path.Candidates,
    },
    {
        Icon: SideHome,
        title: 'Bulk Upload',
        path: path.BulkUpload,
    },
    {
        Icon: SideHome,
        title: 'Reports',
        path: path.Reports,
    },
    {
        Icon: SideHome,
        title: 'Data Management',
        path: path.DataManagement,
    }
]
const sidebarItemsMap = new Map<any, any>()
sidebarItemsMap.set(Role.SuperAdmin, superAdmin)
sidebarItemsMap.set(Role.SuperAdmin_Admin, superAdmin)
sidebarItemsMap.set(Role.SuperAdmin_BD, superAdmin)
sidebarItemsMap.set(Role.AccountAdmin, accountAdmin)

export {
    sidebarItemsMap
}