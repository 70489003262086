import styles from './index.module.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React, { useEffect, useState } from 'react'

import { createFilterOptions } from '@mui/material';

interface Props {
  name: string,
  label?: string,
  label_text?: any,
  options?: any,
  error?: any,
  touched?: boolean,
  handleChange?: any,
  className?: any,
  value?: any,
  methodHandleChange: any,
  attributeName: string
  disabled?: boolean
  placeholder?: any
  startAdornment?: string
  idValueFlag?: boolean
  idValue?: any
  required?: boolean;
  selectAllLabel?: any;
  handleBlur?: any
  limitTags?: number;
}


const SelectField = ({ required = false, disabled = false, name, label_text, options, selectAllLabel,
  touched, handleBlur, className, value, methodHandleChange, attributeName,
  placeholder, error = false, idValueFlag = false, idValue, limitTags = 3 }: Props) => {
  let [displayValue, setDisplayValue] = useState<any>()
  const [displayValue2, setDisplayValue2] = useState<any>([])
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const style = { width: '100%', height: '100%' }
  useEffect(() => {
    options?.every(e => {
      if (e.id === idValue) {
        displayValue2?.push(e);
        setDisplayValue2(displayValue2);

      }
    });
  }, [idValue])
  useEffect(() => {
    displayValue = value
    setDisplayValue(displayValue);
  }, [value])

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 300,
  });

  return options ? (
    <div className={`${styles.commonInputfields} ${className ? className : ''}`}>
      <Autocomplete
        multiple
        filterOptions={filterOptions}
        id={name}
        limitTags={limitTags}
        options={options}
        disabled={disabled}
        disableCloseOnSelect
        value={idValueFlag ? displayValue2 : value}
        onChange={methodHandleChange}
        getOptionLabel={(option) => (option?.name ? option.name : option.item)}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.item ? option.item : option.name}
          </React.Fragment>
        )}
        style={style}
        renderInput={(params) => (
          <TextField {...params} required={required} variant="outlined" helperText={touched ? error : ''}
            error={touched && Boolean(error)} label={label_text ? label_text : []} placeholder={placeholder}
          onBlur={
            handleBlur
          }
          />
        )}
      />
    </div>) : <></>
}


export default SelectField
