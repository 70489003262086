import * as Yup from 'yup';
import { registrationIdR, websiteR } from './regEx';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const name = /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/;
const roleName = /^[A-Za-z0-9_-]*$/;
const websiteYup = Yup.string().matches(websiteR, 'Website is invalid').required('Website is required')
const emailYup = Yup.string()
    .email('Enter valid Email')
    .min(8, 'Atleast 8 characters')
    .max(100, 'Only 100 characters are allowed')
    .required('Email is required');
const emailYup1 = Yup.string()
    .email('Enter valid Email')
    .min(8, 'Atleast 8 characters')
    .max(50, 'Only 50 characters are allowed')
    .required('Email is required');
const regExp = /\b\d{6}\b/;
const pinYup = Yup.string().matches(regExp, { message: 'Must be 6 digin pin number', excludeEmptyString: true }).required('Code is required')

const passwordYup = Yup.string()
    .min(8, 'Atleast 8 characters')
    .max(16, 'Only 16 characters are allowed')
    .matches(/[a-z]/, 'Atleast one lowercase char')
    .matches(/[A-Z]/, 'Atleast one uppercase char')
    .matches(/[0-9]/, 'Atleast 1 number')
    .matches(/[^a-zA-Z0-9\s]+/, 'Atleast 1 special char (@, !, #, etc).').required('Password is required')

const registrationNoYup = Yup.string().matches(registrationIdR, 'Registration id is invalid').required('Registration id is required')

const zipCodeYup = Yup.string()
    .min(6, 'Zip Code is invalid')
    .max(6, 'Zip Code must not exceed 6 characters')
    .nullable();
const percentage = Yup.string()
    .required('Percentage is required')
    .min(0, 'Percentage invalid')
    .max(3, 'Percentage invalid');
const Percentage_GradeYup = Yup.string()
    .max(10, 'Percentage/Grade must not exceed 10 characters');
const companyYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Company Name is required');
const addressYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .required('Address is required');
const fullNameYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Name is required')
    .matches(name, 'Enter Valid Name');
const firstNameYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(30, 'First Name must not exceed 30 characters')
    .required('First Name is required')
    .matches(name, 'Enter Valid Name');
const clientPositionYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(100, 'Only 100 characters are allowed')
    .required('Client Position Name is required')
const pfyPositionYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(100, 'Only 100 characters are allowed')
    .required('PFY Position Name is required')
const nameYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Name is required')
    .matches(name, 'Enter Valid Name');
const nameYupTextLimit = Yup.string()
  .min(1, 'Atleast 1 characters')
  .max(50, 'Only 50 characters are allowed')
  .required('Name is required')
  .matches(roleName, 'Name should be alphanumeric without space');
const FathernameYup = Yup.string()
    .max(20, 'Only 20 characters are allowed')
    .matches(name, 'Enter Valid Name')
    .nullable();
const MothernameYup = Yup.string()
    .max(20, 'Only 20 characters are allowed')
    .matches(name, 'Enter Valid Name')
    .nullable();
const SiblingYup = Yup.string()
    .max(50, 'Only 50 characters are allowed')
    .matches(name, 'Enter Valid Name')
    .nullable();
const SpousYup = Yup.string()
    .max(20, 'Only 20 characters are allowed')
    .matches(name, 'Enter Valid Name')
    .nullable();
const lastNameYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(30, 'Last Name must not exceed 30 characters')
    .required('Last Name is required')
    .matches(name, 'Enter Valid Name');
const descriptionYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(100, 'Only 100 characters are allowed')
    .required('Description is required')
    .matches(name, 'Enter Valid Name');
const descriptionRoleYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(100, 'Only 100 characters are allowed')
    .required('Description is required')
const roleYup = Yup.string()
    .required('Role is required')
const accountYup = Yup.string()
    .required('Company is required')
const locationYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Location is required')
    .matches(name, 'Enter Valid Location');
const phoneYup = Yup.string()
    .required('Mobile number is required');
const countryYup = Yup.string().required('Country is required');
const communicationTypeYup = Yup.string().required('Communication Type is required');
const assessmentYup = Yup.string().required('Field Name is required');
const trackingYup = Yup.string().required('Select Fileld');
const assessment1Yup = Yup.string().required('Field Name is required');
const customFieldNameYup = Yup.string().required('Field Name is required');
const subjectYup = Yup.string().required('Subject is required');
const detailsYup = Yup.string().required('Details field is requied');
const stateYup = Yup.string().required('State is required');
const cityYup = Yup.string().required('City is required');
const userTypeYup = Yup.string().required('User Type is required');
const departmentYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Department Or Divison is required');
const designationYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Designation is required');
const domainYup = Yup.string()
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed')
    .required('Domain is required');
const panNoYup = Yup.string()
    .required('PAN number is required')
    .matches(phoneRegExp, 'PAN number is invalid')
    .min(10, 'PAN number should be of 10 digits')
    .max(10, 'PAN number should be of 10 digits');
const googleCodeYup = Yup.string()
    .required('Code is required')
    .matches(phoneRegExp, 'Code is invalid')
    .min(6, 'Code should be of 6 digits')
    .max(6, 'Code should be of 6 digits');
const industryYup = Yup.string()
    .required('industry is required')
const verticalYup = Yup.string()
    .ensure()
    .required('vertical is required')
const requiredField = Yup.number()
    .required('Required')
const timeDurationYup = Yup.number()
    .required('Required')
    .min(15, 'Min value 15.')
const titleYupTextLimit = Yup.string()
    .min(1, 'Atleast 1 characters')
    .max(200, 'Only 200 characters are allowed')
    .required('Title is required')
    .matches(/^[a-zA-Z0-9 ]+$/, 'Title should be alphanumeric');    

const categoryYupTextLimit = Yup.string()
    .min(1, 'Atleast 1 characters')
    .max(500, 'Only 500 characters are allowed')
    .required('Category is required')
    .matches(/^[a-zA-Z0-9 ]+$/, 'Category should be alphanumeric');        


const fileYup = (FILE_SIZE: number, SUPPORTED_FORMATS: Array<string>) =>
    Yup.mixed()
        .test(
            'fileSize',
            'File Size is too large',
            (value) => value.size <= FILE_SIZE
        )
        .test(
            'fileType',
            `${SUPPORTED_FORMATS.join(', ')} are only supported`,
            (value) => SUPPORTED_FORMATS.includes(value.type)
        );

const accountName = Yup.string()
    .required('Account Name is Required')
    .min(3, 'Atleast 3 characters')
    .max(32, 'Only 32 characters are allowed');

const subDomain = Yup.string()
    .required('Sub Domain is Required')
    .min(2, 'Atleast 2 characters')
    .max(15, 'Only 15 characters are allowed');


const decriptionYup = Yup.string()
    .required('Description is Required')
    .min(10, 'Atleast 10 characters')
    .max(250, 'Only 250 characters are allowed');

const textYup = Yup.string()
    .required('Please Enter the value')
const versionYup = Yup.string()
    .required('Version is mandatory')
    .min(2, 'Atleast 2 characters')
    .max(6, 'Only 6 characters are allowed');
const selectIdpSpYup = Yup.string()
    .required('Version is mandatory')
const generalYup = Yup.string()
    .required('Required')
    .min(1, 'Required')
const reqIdYup = Yup.string()
    .required('Required')
    .min(1, 'Required');
const firstNameVal = Yup.string()
    .max(30, 'First Name must not exceed 30 characters')
    .required('First Name is required')
    .matches(name, 'Enter Valid Name');
const lastNameVal = Yup.string()
    .max(30, 'Last Name must not exceed 30 characters')
    .required('Last Name is required')
    .matches(name, 'Enter Valid Name');
export {
    registrationNoYup,
    reqIdYup,
    generalYup,
    requiredField,
    companyYup,
    panNoYup,
    googleCodeYup,
    designationYup,
    domainYup,
    addressYup,
    zipCodeYup,
    percentage,
    fullNameYup,
    roleYup,
    accountYup,
    locationYup,
    emailYup,
    emailYup1,
    passwordYup,
    pinYup,
    firstNameYup,
    pfyPositionYup,
    clientPositionYup,
    lastNameYup,
    descriptionYup,
    descriptionRoleYup,
    nameYup,
    phoneYup,
    countryYup,
    communicationTypeYup,
    assessmentYup,
    trackingYup,
    assessment1Yup,
    customFieldNameYup,
    subjectYup,
    stateYup,
    cityYup,
    departmentYup,
    fileYup,
    websiteYup,
    accountName,
    subDomain,
    decriptionYup,
    textYup,
    versionYup,
    industryYup,
    verticalYup,
    selectIdpSpYup,
    detailsYup,
    timeDurationYup,
    userTypeYup,
    Percentage_GradeYup,
    FathernameYup,
    MothernameYup,
    SpousYup,
    SiblingYup,
    nameYupTextLimit,
    titleYupTextLimit,
    categoryYupTextLimit,
    firstNameVal,
    lastNameVal
};

