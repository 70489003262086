import { REMOVE_WEEKLY_LEAD_REPORT_STATE, SET_ALL_WEEKLY_LEAD_REPORT_STATE, SET_WEEKLY_LEAD_REPORT_STATE } from '_store/constants/filterData_WeeklyLeadReport';
import Action from '../../types/action';

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const initialState = {
    organizations: [],
    recruiters: [],
    from: null,
    to: null,
}
const filterData_WeeklyLeadReport = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ALL_WEEKLY_LEAD_REPORT_STATE:
      localStorage.setItem('weeklyLeadReport', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;

    case SET_WEEKLY_LEAD_REPORT_STATE:
      localStorage.setItem('weeklyLeadReport', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
    case REMOVE_WEEKLY_LEAD_REPORT_STATE:
      localStorage.removeItem('weeklyLeadReport');
      return initialState;
      break;
    default:
      return state
  }
}
export default filterData_WeeklyLeadReport