import {
  REMOVE_ROLES_STATE,
  SET_ALL_ROLES_FILTERS,
  SET_ROLES_STATE
} from '_store/constants/filterData_roles';
import Action from '../../types/action';
const initialState = {
  basicRoles: [],
  permissions: [],
  statuss: [],
  name: '',
};

const filterData_roles = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ROLES_STATE:
      localStorage.setItem(
        'rolesFilter',
        JSON.stringify({ ...state, [action.payload.type]: action.payload.data })
      );
      return { ...state, [action.payload.type]: action.payload.data };

    case SET_ALL_ROLES_FILTERS:
      return { ...state, ...action.payload };

    case REMOVE_ROLES_STATE:
      localStorage.setItem('rolesFilter', JSON.stringify(initialState));
      return initialState;

    default:
      return state;
  }
};
export default filterData_roles;
