import createSagaMiddleware from "redux-saga";
import { watcherDriveTrackerSaga } from "./driveTracker";
import { spawn } from "redux-saga/effects"


const sagaMiddleWare = createSagaMiddleware();

export default function* rootSaga() {
    yield spawn(watcherDriveTrackerSaga);
}

// export { rootSaga };

