import {
  TextField
} from '@material-ui/core'
import { FieldProps } from '../../types/index'
import styles from './index.module.scss'

const NormalTextField = ({ autoFocus = false, disabled = false, name, label_text, required = false, maxLength, placeholder, error, touched, className, value, handleChange, handleBlur, type }: FieldProps) => {

  return (<div className={`${styles.commonInputfields} ${className ? className : ''}`}>

    <TextField
      fullWidth
      label={label_text ? label_text : []}
      inputProps={{ maxLength: maxLength }}
      id={name}
      required={required}
      autoFocus={autoFocus}
      name={name}
      type={type}
      onChange={handleChange}
      disabled={disabled}
      onBlur={
        handleBlur
      }
      helperText={touched ? error : ''}
      error={touched && Boolean(error)}
      value={type === 'text' && value ? value?.trimLeft() : value}
      margin="dense"
      variant="outlined"

    />
    {null ? null : <div className={touched}></div>}
    {null ? null : <div className={placeholder} ></div>}

  </div>)
}

export default NormalTextField
