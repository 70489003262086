import { lazy } from 'react';

export const editPositionSourcingPoints = lazy(() => import('./system_admin/Positions/EditPosition/SourcingPoints'));
export const RecruiterAllocation = lazy(() => import('./system_admin/Positions/EditPosition/RecruiterAllocation'));
export const Login = lazy(() => import('./user/Login/index'));
export const Register = lazy(() => import('./user/Register'));
export const ForgotPassword = lazy(() => import('./user/ForgotPassword'));
export const CustomerPage1 = lazy(() => import('./user/CustomerPage1'));
export const CustomerPage2 = lazy(() => import('./user/CustomerPage2'));
export const CustomerHome = lazy(() => import('./user/CustomerHome'));
export const ResetPasswordFeedback = lazy(() => import('./user/ResetPasswordFeedback'));
export const ForgotPasswordFeedback = lazy(() => import('./user/ForgotPasswordFeedback'));
export const AdduserFeedback = lazy(() => import('./user/AdduserFeedback'));
export const qcCandidateTracker = lazy(() => import('./reports/tables/qcCandidateTracker'));
export const ResetPassword = lazy(() => import('./user/ResetPassword'));
export const SetPassword = lazy(() => import('./user/SetPassword'));
export const Calendars = lazy(() => import('./system_admin/Positions/EditPosition/PositionInformation/Calendar'));
export const ThankYou = lazy(() => import('./user/ThankYou'));
export const UserManagement = lazy(() => import('./user/Account/UserManagement'));
export const UserDeleted = lazy(() => import('./user/Account/UserDeleted'));
export const UserProfile = lazy(() => import('./user/UserProfile'));
export const UserProfileEdit = lazy(() => import('./user/UserProfileEdit'));
export const Profile = lazy(() => import('./user/Profile'));
export const Notification = lazy(() => import('./user/Notification'));
export const AdminProfile = lazy(() => import('./system_admin/AdminProfile'));
export const ManageSPConfig = lazy(() => import('./system_admin/AdminProfileEdit'));
export const Users = lazy(() => import('./system_admin/users'));
export const Reports = lazy(() => import('./reports'));
export const productivityRecruiter = lazy(() => import('./reports/tables/productivityRecruiterTable'));
export const pendingCVFeedbackChild = lazy(() => import('./reports/tables/pendingCVFeedbackChildTable'));
export const UsersDetails = lazy(() => import('./system_admin/users/EditUser'));
export const AdminProfileEdit = lazy(() => import('./system_admin/AdminProfileEdit'));
export const viewUsers = lazy(() => import('./system_admin/users/EditUser'));
export const editPostioin = lazy(() => import('./system_admin/Positions/EditPosition'));
export const editCandidate = lazy(() => import('./system_admin/Candidates/EditCandidate'));
export const viewCandidate = lazy(() => import('./system_admin/Candidates/EditCandidate'));
export const viewPosition = lazy(() => import('./system_admin/Positions/EditPosition'));
export const EditOrganization = lazy(() => import('./system_admin/Organizations/EditOrg'));
export const viewOrganization = lazy(() => import('./system_admin/Organizations/EditOrg'));
export const SalaryBandDetails = lazy(() => import('./system_admin/Organizations/EditOrg/salaryBandDetails'));
export const BillingInformation = lazy(() => import('./system_admin/Organizations/EditOrg/BillingInformation'));
export const ContactInformation = lazy(() => import('./system_admin/Organizations/EditOrg/ContactInformation'));
export const Roles = lazy(() => import('./system_admin/Roles'));
export const ToDo = lazy(() => import('./system_admin/ToDo'));
export const BulkUpload = lazy(() => import('./system_admin/BulkUpload'));
export const Organizations = lazy(() => import('./system_admin/Organizations'));
export const Candidates = lazy(() => import('./system_admin/Candidates'));
export const Wiki = lazy(() => import('./system_admin/Wiki'));
export const WikiView = lazy(() => import('./system_admin/Wiki/WikiViewMode'));
export const candidateSearch  = lazy(() => import('./system_admin/Candidates/CandidateSearch'));
export const savedSearch  = lazy(() => import('./system_admin/Candidates/SavedSearch'));
export const Positions = lazy(() => import('./system_admin/Positions'));
export const DataManagement = lazy(() => import('./system_admin/DataManagement'));
export const EducationalDetails = lazy(() => import('./system_admin/users/EditUser/EducationalDetails'));
export const AddEditViewUserDetails = lazy(() => import('./system_admin/users/EditUser/PersonalInformation'));
export const AdditionalInformation = lazy(() => import('./system_admin/users/EditUser/AdditionalInformation'));
export const TwoFactorAuth1 = lazy(() => import('./user/Login/TwoFactorAuth1'));
export const TwoFactorAuth2 = lazy(() => import('./user/Login/TwoFactorAuth2'));
export const CandidateInformation = lazy(() => import('./system_admin/Positions/EditPosition/CandidateInformation'));
export const CandidateInformationQC = lazy(() => import('./system_admin/Positions/EditPosition/CandidateInformation'));
export const Postjoiningfeedback = lazy(() => import('./system_admin/Positions/EditPosition/PostJoiningFeedback'));
export const Viewmaskedcandidateinfo = lazy(() => import('./system_admin/Positions/EditPosition/Maskedcandidateinfo'));
export const SendToqcform = lazy(() => import('./system_admin/Candidates/EditCandidate/SendToqcform'));
export const EditMaskCandidateInfo = lazy(() => import('./system_admin/Candidates/EditCandidate/MaskCandidateInfo/EditMaskInfo'));
export const PreviewMaskedInfo = lazy(() => import('./system_admin/Candidates/EditCandidate/MaskCandidateInfo/previewMasInfo'));
export const SendToQC = lazy(() => import('./system_admin/Candidates/EditCandidate/MaskCandidateInfo/SendToQC'));
export const SelectedCandidateChecklist = lazy(() => import('./system_admin/Candidates/EditCandidate/SelectedCandidateChecklist'));
export const JoinabilityScoreTable = lazy(() => import('./system_admin/Candidates/EditCandidate/JoinibiltyScore'));
export const UpdateQCFeedback = lazy(() => import('./system_admin/Positions/EditPosition/UpdateqcFeedback'));
export const ScheduleInterview = lazy(() => import('./system_admin/Candidates/EditCandidate/ScheduleInterview'));
export const AdminDashboard = lazy(() => import('./dashboard/AdminDashboard'));
export const AgencyRCRDashboard = lazy(() => import('./dashboard/AgencyRCR'));
export const OpenPosition = lazy(() => import('./dashboard/AdminDashboard/OpenPosition'));
export const PendingInterviewFeedback = lazy(() => import('./dashboard/AdminDashboard/PendingInterviewFeedback'));
export const InterviewsToBeScheduled = lazy(() => import('./dashboard/AdminDashboard/InterviewsToBeScheduled'));
export const Calendars1 = lazy(() => import('./system_admin/Candidates/EditCandidate/ScheduleInterview/Calendar1'));
export const InterviewFollowUp = lazy(() => import('./system_admin/Candidates/EditCandidate/InterviewFollowUp'));
export const Screeningsheet = lazy(() => import('./system_admin/Positions/EditPosition/Viewscreeningsheet'));
export const JoinedCandidates = lazy(() => import('./dashboard/AdminDashboard/JoinedCandidates'));
export const clientLastAction = lazy(() => import('./dashboard/SPOCDashboard/ClientLastAction'));
export const BackoutCandidates = lazy(() => import('./dashboard/AdminDashboard/BackoutCandidates'));
export const DriverTracker = lazy(() => import('./dashboard/AdminDashboard/DriverTracker'));
export const DriveSummaryReport = lazy(() => import('./dashboard/AdminDashboard/DriverTracker/DriveSummaryReport'));
export const SelectedCandidates = lazy(() => import('./dashboard/AdminDashboard/SelectedCandidates'));
export const CandidateTracker = lazy(() => import('./dashboard/AdminDashboard/CandidateTracker'));
export const CandidatesPositionTable = lazy(() => import('./dashboard/AdminDashboard/PendingInterviewFeedback/CandidatesPositionTable'));
export const SPOCDashboard = lazy(() => import('./dashboard/SPOCDashboard'));
export const BDDashboard = lazy(() => import('./dashboard/BDDashboard'));
export const OpenPositionTable = lazy(() => import('./dashboard/AdminDashboard/OpenPosition/OpenPositionTable'));
export const ManagerDashboard = lazy(() => import('./dashboard/ManagerDashboard'));
export const ToDoDetailView = lazy(() => import('./system_admin/ToDo/ToDoDetailView'));
export const InternalHRDashboard = lazy(() => import('./dashboard/InternalHR'));
export const ClientHrDashboard = lazy(() => import('./dashboard/ClientHrDashboard'));
export const RecruiterDashboard = lazy(() => import('./dashboard/RecruiterDashboard'));
export const SentToClient = lazy(() => import('./dashboard/SPOCDashboard/SentToClient'));
export const VPDashboard = lazy(() => import('./dashboard/VPDashboard'));
export const ClientManagerDashboard = lazy(() => import('./dashboard/ClientManagerDashboard'));
export const RPODashboard = lazy(() => import('./dashboard/RPODashboard'));
export const BulkTeamAllocation = lazy(() => import('./dashboard/SPOCDashboard/BulkTeamAllocation'));
export const TeamLeadDashboard = lazy(() => import('./dashboard/TeamLead'));
export const OverviewReport = lazy(() => import('./dashboard/AdminDashboard/OverviewReport'));
export const OfferPendingCandidate = lazy(() => import('./dashboard/SPOCDashboard/OfferPendingCandidates'));
export const UpcomingJoinees = lazy(() => import('./dashboard/SPOCDashboard/UpcomingJoinees'));
export const InterviewScheduled = lazy(() => import('./dashboard/AdminDashboard/InterviewScheduled'));
export const PortalAllocations = lazy(() => import('../containers/system_admin/PortalAllocation'));
export const portalAllocationHistory = lazy(() => import('./system_admin/PortalAllocation1/Index'));
export const LeadReports = lazy(() => import('./system_admin/LeadReports'));
export const DailyLeadReports = lazy(() => import('./system_admin/LeadReports/DailyLeadReport'));
export const WeeklyLeadReports = lazy(() => import('./system_admin/LeadReports/WeeklyLeadReport'));
export const MonthlyLeadReports = lazy(() => import('./system_admin/LeadReports/MonthlyLeadReport'));
export const OverViewTable = lazy(() => import('./dashboard/AdminDashboard/OverviewReport/CandidatesPositionTable/index'));
export const MISReportDashboard = lazy(() => import('./dashboard/AcountMISDashboard'));
export const AccountMISReport = lazy(() => import('./dashboard/AcountMISDashboard/RecruiterAllocation'));
export const PaymentTracker = lazy(() => import('./dashboard/AcountMISDashboard/RecruiterAllocation/PaymentTracker'));
export const QCdashboard = lazy(() => import('./dashboard/QCDashboard'));
export const ChangePassword = lazy(() => import('./user/Setting/ChangePassword'));
export const LoggedInUsers = lazy(() => import('./user/Setting/LoggedInUsers'));
export const AccessDetails = lazy(() => import('./user/Setting/AccessDetails'));
export const PendingSourcingPointTable = lazy(() => import('./dashboard/ClientHrDashboard/PendingSourcingPointerTable'));
export const CandidateBulkUpload = lazy(() => import('./user/BulkCandidateUpload'));