import { path, RouteTemplate } from 'pageRoutes/routers';
import * as container from '../../containers';


export const unsecureRoutes: RouteTemplate[] = [
    {
      path: `${path.Index}`,
      Component: container.Login,
    },
    {
      path: `${path.Login}`,
      Component: container.Login,
    },
    {
      path: `${path.TwoFactorAuth1}`,
      Component: container.TwoFactorAuth1,
    },
    {
      path: `${path.TwoFactorAuth2}`,
      Component: container.TwoFactorAuth2,
    },
    {
      path: `${path.ResetPassword}`,
      Component: container.ResetPassword,
    },
    {
      path: `${path.SetPassword}`,
      Component: container.SetPassword,
    },
    {
      path: `${path.ForgotPassword}`,
      Component: container.ForgotPassword,
    },
    {
      path: `${path.ResetPasswordFeedback}`,
      Component: container.ResetPasswordFeedback,
    },
    {
      path: `${path.ForgotPasswordFeedback}`,
      Component: container.ForgotPasswordFeedback,
    },
  ];