

// let baseURL = "/"; 
// let baseURLWS = `/ws/`;  

//for build generation
let baseURL = "/";
let baseURLWS = `://${window.location.host}/ws/`;

// For Dev
  //  let baseURL = 'http://13.235.104.9:88/'
  //  let baseURLWS = `ws://13.235.104.9:88/ws/`

//for QA
// let baseURL = 'http://13.235.104.9/';   
// let baseURLWS = '://13.235.104.9/ws/';

// var getUrl = window.location;
// // var baseURL = getUrl .protocol + "//" + getUrl.host + "/";
// // var baseURLWS = getUrl .protocol + "//" + getUrl.host + "/ws";
// // for QA
// let baseURL = getUrl .protocol + "//" + getUrl.host + "/"; 
// let baseURLWS = getUrl .protocol + "//" + getUrl.host + "/ws";


if (process.env.NODE_ENV.toLowerCase() === 'qa') {
  baseURL = 'http://13.235.104.9:88/';
  baseURLWS = 'ws://13.235.104.9:88/ws/'
}

const authenticate = 'api/authenticate'; 
const generatetwofactorkey = 'api/generate/twofactor/key';
const validatepin = 'api/validate/pin';
const accountresetpasswordinit = 'api/forget/password/init';
const accountresetpasswordfinish = 'api/forget/password/finish';
const setpassword = 'api/set/password';
const allaccountdetails = 'api/account';
const allaccountroles = 'api/role';
const apimasterrole = 'api/master/role';
const apipermissionlist = 'api/permission/list';
const apipermissionlistroleWise = 'api/permission/list/roleWise';
const apiroleedit = 'api/role';
const Usersuperadmin = 'api/Users/superadmin';
const rolelist = 'api/role/list';
const addUserSuperadmin = 'api/user/superadmin';
const addapirole = 'api/role';
const addorganization = 'api/organization';
const organizationRolewise = 'api/organizationList/roleWise';
const getButkTeamAllocationOrganizationListRolewiseAPI = 'api/bulkTeamAllocation/organizationList/roleWise';
const dashboardorganizationListupcomingJoinee = '/api/dashboard/organizationList/upcomingJoinee';
const edituser = 'api/user';
const alluserlist = 'api/users';
const addSingleUser = 'api/user';
const addSingleOrganization = 'api/organization';
const addSingleCandidate = 'api/candidate';
const addUserDetails = 'api/user';
const positionLog = 'api/position/log';
const positionSourcingPointSendForApprove = "api/position/sourcing/point/send/for/approve";
const positionSourcingPointSendForApproveAccept = "api/position/sourcing/point/approve";
const positionSourcingPointSendForApproveReject = "api/position/sourcing/point/reject";
const addLogOrg = 'api/organization/log';
const viewLogOrg = 'api/organization/log';
const positionSourcingPointStatus = 'api/position/sourcingPoint/status';
const addLogCandidate = 'api/candidate/log';
const viewLogCandidate = 'api/candidate/log';
const viewLogPosition = 'api/position/sourcing/point/log';
const positiontrackingsheet = 'api/position/tracking/sheet';
const assessmentExcelOrgnization = 'api/organization/assessment/sheet';
const assessmentWordOrgnization = 'api/organization/assessment/sheet';
const assessmentExcelPosition = 'api/position/assessment/sheet';
const positionSourcingPointTransaction = "api/position/sourcing/point/transaction";
const trackingExcelOrganization = 'api/organization/tracking/sheet';
const assessmentExcelOrganization = 'api/organization/assessment/sheet';
const positionjdmom = 'api/position/jd/mom';
const assessmentWordPosition = 'api/position/assessment/sheet';
const imageUpload = 'api/image/upload';
const checkAssociateRole = 'api/role/associate';
const checkAssociatePosition = 'api/position/associate';
const deleteSingleUser = 'api/role';
const removetowfactorkey = 'api/remove/two/factor/key';
const deleteSingleUsers = 'api/user';
const accountsuperadmin = 'api/account/superadmin';
const accountapproveAccounts = 'api/account/approve';
const apiaccountdisapproveAccounts = 'api/account/disapprove';
const apiaccount = 'api/account';
const addedituser = 'api/user';
const specialization = 'api/specialization/courseWise';
const apiaccountdetails = 'api/account';
const companydeletesingle = 'api/account';
const companydeletemultiple = 'api/account';
const templateDownload = 'api/user/tempateDownload';
const IDP = 'api/IDP';
const idpVerison = 'api/IDPVersion/All';
const providerVersion = 'api/IDPVersion';
const setManageIdpVersion = 'api/ProviderConfiguration/Admin/IdpVersion';
const setDataIdp = 'api/ProviderConfiguration';
const idpVerisonAdmin = 'api/ProviderConfiguration/Admin/IdpVersion';
const userProfile = 'api/user/profile';
const userProfileEdit = 'api/user/edit/profile';
const userProfileLocality = 'api/localityList/cityWise';
const deleteProfile = 'api/userProfile/picture';
const notification = 'notification';
const notificationRemove = 'api/Notification/Remove';
const notificationCount = 'api/Notification/TotalCount';
const userPassword = 'api/change/password';
const updateProfileInfo = 'api/userProfile/updateProfileInfo';
const updateProfilePicture = 'api/userProfile/updateProfileInfo';
const accontUpdateAddress = 'api/Account/UpdateAddress';
const inviteUserDetails = 'api/invitation';
const manageIDPDataAdmin = 'api/ProviderConfiguration/Admin';
const manageIDPData = 'api/ProviderConfiguration';
const isIdpConfiguredAdminCheck =
  'api/ProviderConfiguration/Admin/IsIdpConfigured/Account';
const isIdpConfiguredCheck = 'api/ProviderConfiguration/IsIdpConfigured';
const deleteIdp = 'api/ProviderConfiguration/';
const trackingSheetPosition = 'api/position/tracking/sheet';
const joinibilyScore = 'api/candidate/joinabilityScore';
const candidatescheduleinterviewdetailList =
  'api/candidate/schedule/interview/detailList';
const candidatescheduleinterviewavailableSlot =
  'api/candidate/schedule/interview/availableSlot';
// BOT Tasks
const getBotlist = 'api/bottasks';
const getBotListById = 'api/bottasks';
const addUpdateBottask = 'api/bottask';
const deleteBottask = 'api/bottask';
// Registration
const register = 'api/account';
const adminregister = 'adminregister';
const active = 'activate';
const account = 'account';
const accountchangepassword = 'api/change/password';
const allcountriesstatescities = 'allcountriesstatescities';
const registrationcomplete = 'registration-complete';
const useraddsingle = 'user/add/single';
const useraddmultiple = 'api/user/bulkupload';
const userEmailCheckUrl = 'api/user/email/check';
const roleNameCheckUrl = 'api/role/name/check';
const organizationUserTypeWise = 'api/organization/userTypeWise';
const rolesUserTypeWise = 'api/role/userTypeWise';
const superadminuseraddmultiple = 'api/user/bulkupload/SuperAdmin';
const userlist = 'api/user/list';
const userlistactionsstatussingle = 'user/list/actions/status/single';
const clienthrPositionListFilterData = "api/clienthr/positionList/filterData";
const userlistactionsstatusmultiple = 'user/list/actions/status/multiple';
const userlistfiltervalues = 'user/list/filter/values';
const userlistfilter = 'user/list/filter';
const userlistsearch = 'api/user';
const userdeletesingle = 'user/delete/single';
const userdeletemultiple = 'api/role';
const userinvitemultiple = 'user/invite/multiple';
const changeCurrentPassword = 'change/currentPassword';
const customeruseraccount = 'customeruseraccount';
const listdepartments = 'list/departments';
const preferencesalerts = 'preferences/alerts';
const customeruserprofilepictureupload = 'customeruser/profilepicture/upload';
const systemadminaccount = 'system/admin/account';
const systemadminprofilepictureupload = 'system/admin/profilepicture/upload';
const shippersdeletesingle = 'shippers/delete/single';
const shippersdeletemultiple = 'shippers/delete/multiple';
const shippersdetails = 'shippers/details';
const shippers = 'shippers';
const filedownload = 'file/download';
const chalistfiltervalues = 'cha/list/filter/values';
const chalistfilter = 'cha/list/filter';
const addcha = 'add/cha';
const editcha = 'edit/cha';
const chalist = 'cha/list';
const chadeletemultiple = 'cha/delete/multiple';
const chalistsearch = 'cha/list/search';
const chalistactionsstatussingle = 'cha/list/actions/status/single';
const chalistactionsstatusmultiple = 'cha/list/actions/status/multiple';
const emailSettings = 'api/EmailSetting';
const userlistactionstatusactive = 'api/user/activate';
const bottaskActive = 'api/bottask/activate';
const bottaskDeactivate = 'api/bottask/deactivate';
const userlistactionstatusDeactivate = 'api/user/deactivate';
const mastervertical = 'api/master/vertical';
const reportingtTo = 'api/reportingtTo';
const roleWiseReportingtTo = 'api/reportingToList/roleWise';
const roleWisePosition = 'api/positionList/roleWise';
const recruiterRoleWise = 'api/recruiterList/roleWise';
const organization = 'api/organization';
const masterindustry = 'api/master/industry';
const mastercountry = 'api/master/country';
const apimasterindustry = 'api/master/industry';
const apimasterdata = 'api/master/data';
const apiorganization = 'api/organization';
const apiRolewiseOrganization='api/positionFilter/organizationList'
const roleWiseReportingTo = 'api/roleWise/reportingTo';
const apiLeadCountList = 'api/leadCountList';
const apiLeadCount = 'api/leadCount';
const rolewisereportingToList = 'api/rolewise/reportingToList';
const reportingToRolewise = '/api/user/reportingTo/roleWise'
const roleWiseRecruiter = 'api/roleWise/recruiter';
const apicurrentemployer = 'api/current/employer';
const apipositionprojectname = 'api/position/project/name';
const apifunction = 'api/master/function';
const apidesignation = 'api/master/designations';
const apiskill = 'api/skill/categoryWise';
const apibasicrole = 'api/basic/role';
const apimastervertical = 'api/master/vertical';
const apimastercity = 'api/master/city';
const apimasterfunction = 'api/master/function';
const apimasterskill = 'api/skill';
const apimasterlanguage = 'api/master/language';
const apimasteruniversity = 'api/master/university';
const apimastercourse = 'api/master/course';
const apimasterreportingto = 'api/reportingtTo';
const apimasterdesignations = 'api/master/designations';
const apimasterspecialization = 'api/master/specialization';
const apiusers = 'api/user';
const apiorganizationlist = 'api/organization/list';
const pfySpoclist = 'api/organization/teamAllocationDetail';
const apipositionlist = 'api/position/list';
const clientHrPositionList = "api/clientHr/positionList";
const deleteSingleOrganization = 'api/organization';
const apipositiondelete = 'api/position';
const apipositionfiltervalue = 'api/position/filter/value';
const positionEdit = 'api/position/edit';
const screeningSheetPostURL = '/api/candidate/screeningsheet/add';
const checkCoolingPeriod = 'api/candidate/checkCoolingPeriod';
const apiorganizationedit = 'api/organization';
const apipositionorganiationwise = 'api/position/organizationWise';
const apiteamAllocationpositionorganiationwise =
  'api/position/teamAllocationDetail/organizationwise';
const apiaddposition = 'api/position';
const apiorganizationteamallocationdetails =
  'api/organization/teamAllocationDetail';
const apipositionrecruiters = 'api/position/recruiters';
const apipositionmanagerqcclientexecutive =
  'api/position/manager/qc/clientexecutive';
const apipositionitskills = 'api/position/it/skills';
const apipositionsoftskills = 'api/position/soft/skills';
const multiplespecializationcourseWise =
  'api/multiple/specialization/courseWise';
const apicandidatelist = 'api/candidate/list';
const apicandidatedelete = 'api/candidate';
const apiCandidateEdit = 'api/candidate/edit';
const apipositionorganizationWise = 'api/position/organizationWise';
const savedSearchApi = 'api/candidate/getSavedSearch';
const deleteSavedSearchApi = 'api/candidate/savedSearch';
const getParticularSavedSearchApi = 'api/candidate/getParticularSavedSearch';
const addSearchRecord = 'api/candidate/addSavedSearch';
const advancedSearchRecord = 'api/candidate/advanceSearch';
const sentToClientFilterVerticalListOrganizationWise = "api/dashboard/sentToClientFilterVerticalList/organizationWise";
const dashboardSentToClientFilterPositionListBackendSpocWise = "api/dashboard/sentToClientFilterPositionList/backendSpocWise";
const dashboardSentToClientPositionList = 'api/clienthr/positionListForFilter';
const sentToClientFilterBackendspocListOrganizationVerticalWise = "api/dashboard/sentToClientFilterBackendspocList/organizationVerticalWise"
const apipositionorganizationRoleWise = 'api/positionList/organizationWise/roleWise';
const apiDashboardSentToClientCandidateInformationList = "api/dashboard/sentToClientCandidateInformationList";
const dashboardSentToClientEmail = "api/dashboard/sentToClientEmail";
const apiwikilist = 'api/wiki/list';
const apiwiki = 'api/wiki';
const apiwikidelete = 'api/wiki';
const apieditcandidatedeedit = 'api/candidate';
const apidataduplicate = 'api/data/duplicate';
const apidatamanagement = 'api/data/management';
const apiorganizationtrackingsheetget = 'api/organization/tracking/sheet';
const apiorganizationtrackingsheet1get = 'api/organization/assessment/sheet';
const apiorganizationtrackingsheet2get = 'api/organization/assessment/sheet';
const apiPositionAssessmentShetGet = 'api/position/assessment/sheet';
const reportinvoicepaymentpending = 'api/invoice/payment/pending';
const reportproductivity = 'api/productivity';
const reportrevenuepipeline = 'api/revenue/pipeline';
const reportrevenueachieved = 'api/revenue/achieved';
const reportteamperformance = 'api/team/performance';
const reportscreeningstatus = 'api/screening/status';
const reportqcproductivity = 'api/qc/productivity';
const candidateCSV = 'api/candidate/cv/upload';
const candidateCSVWithParser = 'api/candidate/cv/uploadWithParser';
const candidateCSVparser = 'api/candidate/resume/parser';
const positioncandidateinformation = 'api/position/candidateInformation';
const positionClienthrCandidateInformation = "api/position/clienthr/candidateInformation";
const candidateClienthrUpdateCandidateStatus = "api/Candidate/clienthr/updateCandidateStatus";
const candidateClienthrCurrentStatus = "api/candidate/clienthr/currentStatus";
const organizationprofileupload = 'api/organization/profile/upload';
const organizationProfileList = 'api/organization/profile/list';
const organizationEmailCheckUrl = 'api/organization/email/check';
const organizationNameCheck = 'api/verify/organizationName';
const organizationProfilePreSignedUrl = 'api/organization/profile/pre/signed/url';
const positionjdPreSignedUrl = 'api/position/jd/pre/signed/url';
const candidatedownload = 'api/candidate/cv/pre/signed/url';
const candidateMask = 'api/candidate/masked';
const candidateMaskSkillList = 'api/Candidate/masking/skillList';
const parsedEducationDetails = 'api/candidate/parsedEducationDetails';
const candidateMaskSendToQc = 'api/candidate/masking/sendToQc/pfyPositionList';
const candidateSentToQcTabInfo = 'api/candidate/sendToQc/tabInfo';
const candidateSentToQcSelectedNameList =
  'api/candidate/sendToQc/selectNameList';
const sendToQcPost = 'api/candidate/stopScreening';
const candidatePFYList = 'api/candidate/burgerMenu/PositionNameList';
const getvendorDetails = 'api/candidate/getVendorDetail';
const postVendorDetails = 'api/candidate/addVendorDetail';
const candidatePFYPositionList =
  'api/candidate/burgerMenu/PositionNameList/candidateChecklist';
const candidateImageUpload = 'api/candidate/image/upload';
const candidateCvList = 'api/candidate/cv/list';
const positionprofileupload = 'api/position/jd/upload';
const viewscreeningsheetcandidate = 'api/candidate/screeningSheet';
const candidateActivePosition = 'api/candidate/activePosition';
const maskedCandidateview = 'api/candidate/masked';
const sendToQc = 'api/candidate/masking/sendToQc/pfyPositionList';
const positionTypeWisePositionList = 'api/positionList/roleWise/positionTypeWise';
const sendToQcpositioncandidateid = 'api/candidate/sendToQc/tabInfo';
const qcNamelistdropdown = 'api/candidate/sendToQc/selectNameList';
const clientHrOrganizationWise = 'api/clientHr/position/organizationWise';
const organizationWisePfySpoc = 'api/organizationWise/pfySpoc';
const candidatescreenpositionList = 'api/screenPosition/roleWise';
const organizationListroleWiseuserTypeWise = "api/organizationList/roleWise/userTypeWise";
const candidateStatusUpdateListApi = 'api/candidate/candidateStatusList';
const interviewSlot = 'api/interview/slot';
const getInterviewSlot = 'api/position/getInterviewSlot';
const createInterviewSlot = 'api/position/createInterviewSlot';
const interviewStageNameList = 'api/position/interviewStageNameList';
const panelNameList = 'api/position/interviewStage/panelNameList';
const selectedCandidateChecklist = 'api/candidate/selectedCandidateChecklist';
const checkrecruiterbackupRecruiteraccess = 'api/check/recruiter/backupRecruiter/access';
const userAccessibility = 'api/candidate/check/roleWiseEdit/access';
const postJoiningPendingFeedback =
  'api/position/burgerMenu/getPostJoiningPendingFeedback';
const viewFeedbackHistory = 'api/position/viewFeedbackHistory';
const takeJoiningFeedback = 'api/position/takeJoiningFeedback';
const qcName = 'api/qc/name';
const candidateStatusList = 'api/candidate/selectStatus';
const candidateStatusUpdate = 'api/Candidate/updateCandidateStatus';
const updateQCFeedback = 'api/Candidate/updateQcFeedback';

// checklist document
const checklistFileUpload = 'api/candidate/checklist/file/upload';
const checkListFileGet = 'api/candidate/checklist/file/list';
const checkListFileDownload = 'api/candidate/checklist/pre/signed/url';
const checklistFileDelete = 'api/candidate/checklist/file/delete';
const candidateFolloUp = 'api/candidate/interview/followUp';
const candidateFolloUp1Day = 'api/todo/updateStatus/1dayPriorFollowUp';
const todoUpdateStatusJoinedCandidateLog = "api/todo/updateStatus/joinedCandidateLog";
const candidateFolloUp2Hours = 'api/todo/updateStatus/2hourPriorFollowUp';
const candidateEmailCheckUrl = 'api/candidate/email/check';
const exceptionReasonUrl = 'api/candidate/exceptionReason';

const communicationQcDoneBy = 'api/Candidate/communicationQcDoneBy';
const deleteInterviewStage = 'api/position/deleteInterviewStage';

//Dashboard
// open position
const positionListFilterByOrg = 'api/positionListFilter/organizationWise';
const dashboardOpenPosition = 'api/dashboard/openPosition';
const dashboardPendingInterviewFeedback =
  'api/dashboard/pendingInterviewFeedback';
const backendSpocFrontendSpocOrganizationWise =
  'api/dashboard/backendSpoc/frontendSpoc/organizationWise';
const interviewsToBeScheduled = 'api/dashboard/interviewToBeScheduled';
const dashboardRecruiterList = 'api/dashboard/recruiterList';
const dashboardBackupRecruiterRecruiterList = 'api/dashboard/backupRecruiter/recruiterList';
const dashboardOpenPositionColumns = 'api/dashboard/openPositionColumns';
//join candidate
const dashboardJoinedCandidateList = 'api/dashboard/joinedCandidateList';
const dashboardJoinedCandidateTakeFeedback =
  'api/dashboard/joinedCandidate/takeFeedback';
const dashboardJoinedCandidateGetFeedback =
  '/api/dashboard/joinedCandidate/getFeedback';
const dashboardPendingInterviewFeedbackColumnDetailList =
  '/api/dashboard/pendingInterviewFeedback/columnDetailList';
const condidateInterviewFeedback = '/api/candidate/interview/feedback';
const todoUpdateStatusInterviewFeedbackUpdate = "api/todo/updateStatus/interviewFeedbackUpdate";
const todoUpdateStatusProfileFeedbackUpdate = "api/todo/updateStatus/profileFeedbackUpdate";
const condidateInterviewCancel = 'api/candidate/interview/cancel';
const InterviewDropCandidate = 'api/candidate/interview/drop/candidate';
// candidate tracker
const candidateTrackerapi = 'api/dashboard/candidateTracker';
const dashboardCandidateTrackerColumns =
  'api/dashboard/candidateTrackerColumns';
const candidateTrackerClientBu = 'api/clientBu/organizationWise';

const updateInterviewStage = 'api/position/updateInterviewStage';
const addInterviewStage = 'api/position/addInterviewStage';
const localityList = 'api/candidate/screen/localityList';

const interviewSlotBook = 'api/candidate/schedule/interview';
const interviewReshedule = 'api/candidate/reschedule/interview/availableSlot';
const candidateReshedulePost = 'api/candidate/reschedule/interview';
const positionJDList = 'api/position/jd/list';
const screenSheetList = 'api/candidate/screeningSheet/List';
const stopScreening = 'api/candidate/stopScreening';

// bulk Upload
const userBulkUploadUrl = 'api/bulk/upload/user';
const candidateBulkUploadUrl = 'api/bulk/upload/candidate';
const organizationBulkUploadUrl = 'api/bulk/upload/organization';
const positionBulkUploadUrl = 'api/bulk/upload/position';
const qcPassStatusToScheduleInterviewUrl =
  'api/candidate/qcPassStatusToScheduleInterview';

// Backout Candidate
const dashboardBackoutCandidate = 'api/dashboard/backoutCandidate';
const candidateLog = 'api/candidate/log';
const upcomingJoineeSelectedCandidateLog = 'api/dashboard/selectedCandidate/log';
const clientBuOrganizationWise = 'api/clientBu/organizationWise';
//Selected Candidates
const dashboardSelectedCandidate = 'api/dashboard/selectedCandidate';
const dashboardSelectedCandidateException =
  'api/dashboard/selectedCandidateWithException';
const selectedCandidateLog = 'api/dashboard/selectedCandidate/log';
const selectedCandidateAddLog = 'api/dashboard/selectedCandidate/addLog';


//BackUpRecruiter
const dashboardBackUpRecruiterList = 'api/dashboard/backup/recruiter/list';
const dashboardBackUpRecruiter = 'api/dashboard/backup/recruiter';
//DriverTracker
const dashboardDriveTracker = 'api/dashboard/driveTracker';
const dashboardDriveTrackerChangeStatus =
  'api/dashboard/driveTracker/changeStatus';
const dashboardDriveTrackerUpdateField =
  '/api/dashboard/driveTracker/updateField/basedOnRole';
const dashboardDriveTrackerSummaryReport = 'api/dashboard/driveSummaryReport';
//Bulk Team Allocation
const bulkTeamAllocationQCList = 'api/dashboard/bulkTeamAllocation/QcList';
const bulkTeamAllocation = 'api/dashboard/bulkTeamAllocation';
const updateBulkTeamAllocation = 'api/dashboard/update/bulkTeamAllocation';

// Today's Assigned Positions
const todaysAssignedPosition = 'api/todays/assigned/position';
const PositionManagerQCClientexecutive =
  'api/position/manager/qc/clientexecutive';

// offer-pending-candidate
const offerpendingcandidate = '/api/dashboard/offerPendingCandidate';
// Pending CV Feedback
const pendingCVFeedback = 'api/pending/cv/feedback';
const pendingCVFeedbackCVSSent = 'api/pending/cv/feedback/cvs/sent'
const pendingCVFeedbackFeedbackAwaited = 'api/pending/cv/feedback/feedback/awaited'
//Todo's APIs
const apitodotodoTasks = 'api/todo/todoTasks';
const apitodo = 'api/todo';
const apiupdatestatus = 'api/todo/updateStatus';
const apitodoUpdateStatusEmailAcknowledgment = 'api/todo/updateStatus/emailAcknowledgment';

// Upcoming Joining
const apiDashboardUpcomingJoinees = 'api/dashboard/upcoming/joinees';
// Recruiter Work Allocation
const RecruiterWorkAllocation = 'api/recruiter/work/allocation';
// Upcoming Meetings
const upcomingMeetings = 'api/upcoming/meetings';
// Upcoming Followups
const upcomingFollowups = 'api/upcoming/followups';

// candidate qcStatus
const qcStatusCandidate = 'api/qcProductivityReport/columnDetail';
const productivitycolumnDetail = 'api/productivity/columnDetail';
// interview Scheduled
const interviewScheduled = 'api/dashboard/interviewScheduled';
//Overview Reprots
const overviewReportCount = 'api/dashboard/overview/report/count';
const overviewReportofferInProcess =
  'api/dashboard/overviewReport/offerInProcess';
const overviewReportoffered = 'api/dashboard/overviewReport/offered';
const overviewReportJoined = 'api/dashboard/overview/report/joined';
const overviewReportBackout = 'api/dashboard/overview/report/backout';
const overviewReportYetToJoin = 'api/dashboard/overview/report/yetToJoin';

// MIS Report
const DashboardMisReport = 'api/dashboard/mis/report';
const DashboardPaymentTracker = '/api/dashboard/payment/tracker';
const dashboardMisBillingDetail = 'api/dashboard/mis/billing/detail';

//convertToCLient
const convertToCLient = '/api/organization/convertToClient';

//cancelInterviewModal
const candidateInterviewCancel = 'api/candidate/interview/cancel';
const candidateInterviewFeedback = 'api/candidate/interview/feedback';
const candidateEmailAcknowledge = 'api/candidate/interview/acknowledgement';
const candidateInterviewDropCandidate =
  'api/candidate/interview/drop/candidate';

//Dashboard Count
const dashboardCount = 'api/dashboard/count'
//QC Dashboard
const qcdashboard = 'api/dashboard/position/qc/status'
//Dashboard API
const dashbardTodaysAssignedPosition = 'api/dashboard/todays/assigned/position';
const dashboardProductivityReport = 'api/dashboard/productivity/report'
const dashboardClientAssignedPosition = "api/dashboard/client/assignedPosition";
//All User Change Password
const allUsersChangePassword = 'api/alluser/change/password';
const getUserName = 'api/alluser/detail'

//Organization Filter
const getVenderEmpMonth = '/api/prospectOrganization/vendorEmpMonth'

//Lead Reports 
const getRecruiterList = 'api/position/recruiters'
const organizationList = 'api/lead/get/organizations'
const positionListByOrg = 'api/position/organizationWise'
const apirecentlyAddedLeadReportlist = 'api/recently/added/lead/report/list'
const addLeads = 'api/add/leads'
const updateLeads = 'api/add/lead'
const getAddLeadDetails = 'api/add/lead/report/detail'
const dailyleadReportList = 'api/daily/lead/report/list'
const weeklyleadReportList = 'api/weekly/lead/report'
const monthlyleadReportList = 'api/monthly/lead/report'

//logggedInUsers
const logggedInUsers = 'api/loggedInUser/list'
const logoutAllInstances = 'api/logout/all/instance'
const logoutAllExceptCurrentInstances = 'api/logout/all/except/currentInstance'
const accessDetails = 'api/access/detail'

//broadCastMessage
const broadcastmessage = 'api/broadcast/message'

//link share
const linkShare = 'api/candidate/interview/linkShare';

const dashboardNotificationCount = 'api/dashboard/notification/count';
const dashboardNotificationDetail = 'api/dashboard/notification/detail';
const notificationReadNotification = 'notification/readNotification';
const readNotification = 'notification';
const notificationMarkAllAsRead = "notification/markAllAsRead"

//Portal Allocation
const apiPortalAllocationList = "api/portalAllocation/list";
const apiPortalAllocationHistoryList = "api/portalAllocation/history";
const apiPortalAllocatioAddAccount = "api/portalAllocation/add/account";
const apiPortalAllocationEdit = "api/portalAllocation/editAccountDetail";
const apiPortalAllocationAssignUser = "api/portalAllocation/assignUser";
const apiPortalAllocationeditAccountDetail = "api/portalAllocation/editAccountDetail";
const apiPortalAllocationAssignUserData = "api/portalAllocation/assignUser";
const apiPortalAllocationDelete = "api/portalAllocation/delete";
const apiPortalAllocationDuplicateNameCheck = "api/portalAllocation/duplicateNameCheck";


export {
  baseURLWS,
  baseURL,
  apiPortalAllocationList,
  apiPortalAllocationDuplicateNameCheck,
  apiPortalAllocationeditAccountDetail,
  apiPortalAllocationDelete,
  apiPortalAllocationAssignUserData,
  apiPortalAllocationHistoryList,
  apiPortalAllocationAssignUser,
  apiPortalAllocationEdit,
  apiPortalAllocatioAddAccount,
  positionListFilterByOrg,
  positionEdit,
  apieditcandidatedeedit,
  apicandidatedelete,
  apicandidatelist,
  multiplespecializationcourseWise,
  apipositionitskills,
  apipositionsoftskills,
  apipositionorganiationwise,
  apipositionmanagerqcclientexecutive,
  apipositionrecruiters,
  apiaddposition,
  apiorganizationedit,
  apimasterindustry,
  apimasterdata,
  apiorganization,
  apicurrentemployer,
  apipositionprojectname,
  apifunction,
  apidesignation,
  apiskill,
  apimastervertical,
  apiorganizationteamallocationdetails,
  apimastercity,
  templateDownload,
  register,
  adminregister,
  active,
  authenticate,
  generatetwofactorkey,
  validatepin,
  account,
  accountchangepassword,
  accountresetpasswordinit,
  accountresetpasswordfinish,
  setpassword,
  allcountriesstatescities,
  registrationcomplete,
  useraddsingle,
  useraddmultiple,
  superadminuseraddmultiple,
  userlist,
  userlistactionsstatussingle,
  userlistactionsstatusmultiple,
  userlistfiltervalues,
  userlistfilter,
  getBotlist,
  getBotListById,
  addUpdateBottask,
  deleteBottask,
  userlistsearch,
  userdeletesingle,
  userdeletemultiple,
  userinvitemultiple,
  changeCurrentPassword,
  customeruseraccount,
  listdepartments,
  preferencesalerts,
  customeruserprofilepictureupload,
  systemadminaccount,
  systemadminprofilepictureupload,
  shippersdeletesingle,
  shippersdeletemultiple,
  shippersdetails,
  shippers,
  filedownload,
  chalistfiltervalues,
  chalistfilter,
  addcha,
  editcha,
  chalist,
  chadeletemultiple,
  chalistsearch,
  chalistactionsstatussingle,
  chalistactionsstatusmultiple,
  allaccountdetails,
  alluserlist,
  addSingleUser,
  addSingleOrganization,
  addSingleCandidate,
  checkAssociateRole,
  checkAssociatePosition,
  deleteSingleUser,
  deleteSingleUsers,
  removetowfactorkey,
  inviteUserDetails,
  IDP,
  idpVerison,
  userProfile,
  userProfileEdit,
  deleteProfile,
  notification,
  notificationRemove,
  notificationCount,
  userPassword,
  updateProfileInfo,
  updateProfilePicture,
  accontUpdateAddress,
  idpVerisonAdmin,
  manageIDPDataAdmin,
  manageIDPData,
  isIdpConfiguredAdminCheck,
  isIdpConfiguredCheck,
  setManageIdpVersion,
  deleteIdp,
  setDataIdp,
  providerVersion,
  addUserDetails,
  positionLog,
  addLogOrg,
  viewLogOrg,
  viewLogCandidate,
  positiontrackingsheet,
  assessmentExcelOrgnization,
  assessmentWordOrgnization,
  assessmentExcelPosition,
  positionjdmom,
  assessmentWordPosition,
  imageUpload,
  allaccountroles,
  apimasterrole,
  apipermissionlist,
  apiroleedit,
  Usersuperadmin,
  rolelist,
  addUserSuperadmin,
  addapirole,
  addorganization,
  edituser,
  emailSettings,
  userlistactionstatusactive,
  userlistactionstatusDeactivate,
  mastervertical,
  reportingtTo,
  organization,
  masterindustry,
  mastercountry,
  accountsuperadmin,
  accountapproveAccounts,
  apiaccountdisapproveAccounts,
  apiaccount,
  addedituser,
  specialization,
  apiaccountdetails,
  companydeletesingle,
  companydeletemultiple,
  bottaskActive,
  bottaskDeactivate,
  apimasterfunction,
  apimasterskill,
  apimasterlanguage,
  apimasteruniversity,
  apimastercourse,
  apimasterspecialization,
  apimasterreportingto,
  apimasterdesignations,
  apiusers,
  pfySpoclist,
  apiorganizationlist,
  deleteSingleOrganization,
  apipositionlist,
  apipositiondelete,
  apipositionfiltervalue,
  apipositionorganizationWise,
  apiwikilist,
  apiwiki,
  addLogCandidate,
  apiwikidelete,
  trackingExcelOrganization,
  assessmentExcelOrganization,
  apidataduplicate,
  apidatamanagement,
  apiorganizationtrackingsheetget,
  apiorganizationtrackingsheet1get,
  apiorganizationtrackingsheet2get,
  candidateCSV,
  candidateCSVWithParser,
  candidateCSVparser,
  organizationprofileupload,
  organizationProfileList,
  organizationProfilePreSignedUrl,
  positionjdPreSignedUrl,
  reportproductivity,
  reportinvoicepaymentpending,
  reportrevenuepipeline,
  reportrevenueachieved,
  reportteamperformance,
  reportscreeningstatus,
  reportqcproductivity,
  viewLogPosition,
  candidateImageUpload,
  positioncandidateinformation,
  candidateCvList,
  candidateActivePosition,
  apiPositionAssessmentShetGet,
  positionprofileupload,
  viewscreeningsheetcandidate,
  clientHrOrganizationWise,
  organizationWisePfySpoc,
  apiCandidateEdit,
  candidatedownload,
  candidatescreenpositionList,
  interviewSlot,
  getInterviewSlot,
  createInterviewSlot,
  maskedCandidateview,
  sendToQc,
  sendToQcpositioncandidateid,
  qcNamelistdropdown,
  candidateMask,
  candidatePFYList,
  candidatePFYPositionList,
  candidateMaskSendToQc,
  candidateSentToQcTabInfo,
  candidateSentToQcSelectedNameList,
  sendToQcPost,
  candidateMaskSkillList,
  interviewStageNameList,
  panelNameList,
  candidateStatusUpdateListApi,
  selectedCandidateChecklist,
  postJoiningPendingFeedback,
  viewFeedbackHistory,
  takeJoiningFeedback,
  trackingSheetPosition,
  qcName,
  candidateStatusList,
  candidateStatusUpdate,
  joinibilyScore,
  updateQCFeedback,
  checklistFileUpload,
  checkListFileGet,
  checkListFileDownload,
  checklistFileDelete,
  candidateFolloUp,
  communicationQcDoneBy,
  deleteInterviewStage,
  candidatescheduleinterviewdetailList,
  dashboardOpenPosition,
  dashboardPendingInterviewFeedback,
  backendSpocFrontendSpocOrganizationWise,
  interviewsToBeScheduled,
  dashboardRecruiterList,
  candidatescheduleinterviewavailableSlot,
  updateInterviewStage,
  localityList,
  addInterviewStage,
  candidateEmailCheckUrl,
  userEmailCheckUrl,
  organizationUserTypeWise,
  rolesUserTypeWise,
  exceptionReasonUrl,
  interviewSlotBook,
  positionJDList,
  screenSheetList,
  interviewReshedule,
  candidateReshedulePost,
  stopScreening,
  screeningSheetPostURL,
  userBulkUploadUrl,
  candidateBulkUploadUrl,
  organizationBulkUploadUrl,
  positionBulkUploadUrl,
  dashboardJoinedCandidateList,
  dashboardJoinedCandidateTakeFeedback,
  dashboardJoinedCandidateGetFeedback,
  dashboardPendingInterviewFeedbackColumnDetailList,
  condidateInterviewFeedback,
  condidateInterviewCancel,
  InterviewDropCandidate,
  dashboardOpenPositionColumns,
  qcPassStatusToScheduleInterviewUrl,
  dashboardBackoutCandidate,
  candidateLog,
  upcomingJoineeSelectedCandidateLog,
  clientBuOrganizationWise,
  dashboardSelectedCandidate,
  selectedCandidateLog,
  selectedCandidateAddLog,
  candidateTrackerapi,
  dashboardCandidateTrackerColumns,
  candidateTrackerClientBu,
  dashboardBackUpRecruiter,
  dashboardBackUpRecruiterList,
  todaysAssignedPosition,
  PositionManagerQCClientexecutive,
  dashboardDriveTracker,
  dashboardDriveTrackerChangeStatus,
  bulkTeamAllocationQCList,
  offerpendingcandidate,
  apitodotodoTasks,
  apitodo,
  apiupdatestatus,
  pendingCVFeedback,
  dashboardDriveTrackerSummaryReport,
  bulkTeamAllocation,
  updateBulkTeamAllocation,
  apiDashboardUpcomingJoinees,
  dashboardDriveTrackerUpdateField,
  RecruiterWorkAllocation,
  upcomingMeetings,
  upcomingFollowups,
  interviewScheduled,
  roleWiseReportingTo,
  rolewisereportingToList,
  roleWiseRecruiter,
  overviewReportCount,
  overviewReportofferInProcess,
  overviewReportoffered,
  overviewReportJoined,
  overviewReportBackout,
  overviewReportYetToJoin,
  DashboardMisReport,
  DashboardPaymentTracker,
  dashboardMisBillingDetail,
  apipermissionlistroleWise,
  apibasicrole,
  parsedEducationDetails,
  roleNameCheckUrl,
  organizationEmailCheckUrl,
  organizationNameCheck,
  convertToCLient,
  candidateInterviewCancel,
  candidateInterviewFeedback,
  candidateEmailAcknowledge,
  candidateInterviewDropCandidate,
  dashboardCount,
  qcdashboard,
  qcStatusCandidate,
  dashboardSelectedCandidateException,
  allUsersChangePassword,
  getUserName,
  getVenderEmpMonth,
  getRecruiterList,
  organizationList,
  positionListByOrg,
  apirecentlyAddedLeadReportlist,
  addLeads,
  updateLeads,
  getAddLeadDetails,
  dailyleadReportList,
  weeklyleadReportList,
  monthlyleadReportList,
  logggedInUsers,
  logoutAllInstances,
  logoutAllExceptCurrentInstances,
  accessDetails,
  dashbardTodaysAssignedPosition,
  broadcastmessage,
  apiteamAllocationpositionorganiationwise,
  dashboardProductivityReport,
  apiRolewiseOrganization,
  reportingToRolewise,
  productivitycolumnDetail,
  dashboardorganizationListupcomingJoinee,
  linkShare,
  organizationRolewise,
  recruiterRoleWise,
  apipositionorganizationRoleWise,
  roleWiseReportingtTo,
  checkrecruiterbackupRecruiteraccess,
  roleWisePosition,
  organizationListroleWiseuserTypeWise,
  pendingCVFeedbackFeedbackAwaited,
  pendingCVFeedbackCVSSent,
  dashboardBackupRecruiterRecruiterList,
  apitodoUpdateStatusEmailAcknowledgment,
  candidateFolloUp2Hours,
  candidateFolloUp1Day,
  todoUpdateStatusInterviewFeedbackUpdate,
  todoUpdateStatusProfileFeedbackUpdate,
  todoUpdateStatusJoinedCandidateLog,
  dashboardClientAssignedPosition,
  clientHrPositionList,
  positionSourcingPointTransaction,
  positionClienthrCandidateInformation,
  positionSourcingPointSendForApprove,
  positionSourcingPointSendForApproveReject,
  positionSourcingPointSendForApproveAccept,
  sentToClientFilterVerticalListOrganizationWise,
  sentToClientFilterBackendspocListOrganizationVerticalWise,
  apiDashboardSentToClientCandidateInformationList,
  positionSourcingPointStatus,
  dashboardSentToClientFilterPositionListBackendSpocWise,
  candidateClienthrUpdateCandidateStatus,
  clienthrPositionListFilterData,
  dashboardSentToClientEmail,
  candidateClienthrCurrentStatus,
  dashboardNotificationCount,
  dashboardNotificationDetail,
  notificationReadNotification,
  readNotification,
  notificationMarkAllAsRead,
  dashboardSentToClientPositionList,
  positionTypeWisePositionList,
  userProfileLocality,
  postVendorDetails,
  getvendorDetails,
  getParticularSavedSearchApi,
  deleteSavedSearchApi,
  savedSearchApi,
  addSearchRecord,
  getButkTeamAllocationOrganizationListRolewiseAPI,
  checkCoolingPeriod,
  userAccessibility,
  advancedSearchRecord,
  apiLeadCountList,
  apiLeadCount
};
