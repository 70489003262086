
import { REMOVE_CITY_CITY, REMOVE_CITY_LIST, REMOVE_DESIGNATION_DESIGNATION, REMOVE_DESIGNATION_LIST, REMOVE_EMAIL_EMAIL, REMOVE_FILTERDATA_FILTERDATA, REMOVE_FUNCTION_FUNCTION, REMOVE_FUNCTION_LIST, REMOVE_INDUSTRY_INDUSTRY, REMOVE_INDUSTRY_LIST, REMOVE_LANGUAGE_LANGUAGE, REMOVE_LANGUAGE_LIST, REMOVE_ORGANIZATION_LIST, REMOVE_ORGANIZATION_ORGANIZATION, REMOVE_REPORTINGTO_LIST, REMOVE_REPORTINGTO_REPORTINGTO, REMOVE_ROLE_LIST, REMOVE_ROLE_ROLE, REMOVE_SKILL_LIST, REMOVE_SKILL_SKILL, REMOVE_VERTICAL_LIST, REMOVE_VERTICAL_VERTICAL, SET_CITY, SET_CITY_LIST, SET_DESIGNATION, SET_DESIGNATION_LIST, SET_EMAIL, SET_FILTERDATA, SET_FUNCTION, SET_FUNCTION_LIST, SET_INDUSTRY, SET_INDUSTRY_LIST, SET_LANGUAGE, SET_LANGUAGE_LIST, SET_ORGANIZATION, SET_ORGANIZATION_LIST, SET_REPORTINGTO, SET_REPORTINGTO_LIST, SET_ROLE, SET_ROLE_LIST, SET_SKILL, SET_SKILL_LIST, SET_VERTICAL, SET_VERTICAL_LIST } from '_store/constants/filterData'
import Action from '../../types/action'
const initialState = {
  userType: '',
  email: '',
  listofRoles: [],
  selectedRole: [],
  listofVerticals: [],
  selectedVertical: [],
  listofLanguages: [],
  selectedLanguage: [],
  listofIndustries: [],
  selectedIndustry: [],
  listofFunctions: [],
  selectedFunction: [],
  listOfSkill: [],
  selectedSkill: [],
  listOfDesignation: [],
  selectedDesignation: [],
  listOfReportingTo: [],
  selectedReportingTo: [],
  listofOrganizations: [],
  selectedOrganization: [],
  listOfCities: [],
  selectedCity: []
}
const filterData = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_FILTERDATA:
      return { ...state, userType: action.payload }
    case REMOVE_FILTERDATA_FILTERDATA:
      return { ...state, userType: '' }
    case SET_EMAIL:
      return { ...state, email: action.payload }
    case REMOVE_EMAIL_EMAIL:
      return { ...state, email: '' }
    case SET_ORGANIZATION_LIST:
      return { ...state, listofOrganizations: action.payload }
    case REMOVE_ORGANIZATION_LIST:
      return { ...state, listofOrganizations: [] }
    case SET_ORGANIZATION:
      return { ...state, selectedOrganization: action.payload }
    case REMOVE_ORGANIZATION_ORGANIZATION:
      return { ...state, selectedOrganization: [] }
    case SET_ROLE_LIST:
      return { ...state, listofRoles: action.payload }
    case REMOVE_ROLE_LIST:
      try {
        return { ...state, listofRoles: [] }
      } finally {
        action?.payload()
      }
    case SET_ROLE:
      return { ...state, selectedRole: action.payload }
    case REMOVE_ROLE_ROLE:
      return { ...state, selectedRole: [] }
    case SET_VERTICAL_LIST:
      return { ...state, listofVerticals: action.payload }
    case REMOVE_VERTICAL_LIST:
      try {
        return { ...state, listofVerticals: [] }
      } finally {
        action?.payload()
      }
    case SET_VERTICAL:
      return { ...state, selectedVertical: action.payload }
    case REMOVE_VERTICAL_VERTICAL:
      return { ...state, selectedVertical: [] }
    case SET_LANGUAGE_LIST:
      return { ...state, listofLanguages: action.payload }
    case REMOVE_LANGUAGE_LIST:
      try {
        return { ...state, listofLanguages: [] }
      } finally {
        action?.payload()
      }
    case SET_LANGUAGE:
      return { ...state, selectedLanguage: action.payload }
    case REMOVE_LANGUAGE_LANGUAGE:
      return { ...state, selectedLanguage: [] }
    case SET_INDUSTRY_LIST:
      return { ...state, listofIndustries: action.payload }
    case REMOVE_INDUSTRY_LIST:
      try {
        return { ...state, listofIndustries: [] }
      } finally {
        action?.payload()
      }
    case SET_INDUSTRY:
      return { ...state, selectedIndustry: action.payload }
    case REMOVE_INDUSTRY_INDUSTRY:
      return { ...state, selectedIndustry: [] }
    case SET_FUNCTION_LIST:
      return { ...state, listofFunctions: action.payload }
    case REMOVE_FUNCTION_LIST:
      try {
        return { ...state, listofFunctions: [] }
      } finally {
        action?.payload()
      }
    case SET_FUNCTION:
      return { ...state, selectedFunction: action.payload }
    case REMOVE_FUNCTION_FUNCTION:
      return { ...state, selectedFunction: [] }
    case SET_SKILL_LIST:
      return { ...state, listOfSkill: action.payload }
    case REMOVE_SKILL_LIST:
      try {
        return { ...state, listOfSkill: [] }
      } finally {
        action?.payload()
      }
    case SET_SKILL:
      return { ...state, selectedSkill: action.payload }
    case REMOVE_SKILL_SKILL:
      return { ...state, selectedSkill: [] }
    case SET_DESIGNATION_LIST:
      return { ...state, listOfDesignation: action.payload }
    case REMOVE_DESIGNATION_LIST:
      try {
        return { ...state, listOfDesignation: [] }
      } finally {
        action?.payload()
      }
    case SET_DESIGNATION:
      return { ...state, selectedDesignation: action.payload }
    case REMOVE_DESIGNATION_DESIGNATION:
      return { ...state, selectedDesignation: [] }
    case SET_REPORTINGTO_LIST:
      return { ...state, listOfReportingTo: action.payload }
    case REMOVE_REPORTINGTO_LIST:
      try {
        return { ...state, listOfReportingTo: [] }
      } finally {
        action?.payload()
      }
    case SET_REPORTINGTO:
      return { ...state, selectedReportingTo: action.payload }
    case REMOVE_REPORTINGTO_REPORTINGTO:
      return { ...state, selectedReportingTo: [] }
    case SET_CITY_LIST:
      return { ...state, listOfCities: action.payload }
    case REMOVE_CITY_LIST:
      try {
        return { ...state, listOfCities: [] }
      } finally {
        action?.payload()
      }
    case SET_CITY:
      return { ...state, selectedCity: action.payload }
    case REMOVE_CITY_CITY:
      return { ...state, selectedCity: [] }
    default:
      return state
  }
}
export default filterData