import { createSlice } from '@reduxjs/toolkit';
interface IReponse {
    count: number;
    driveTrackerList: Array<object>
}
interface IPropsResponse {
    request: any;
    response: IReponse
}
export const driverTrackerDataInitialState: IPropsResponse = {
    request: {
        pageNo: 1,
        pageSize: 10,
        sortType: "asc",
        sortBy: "scheduledDate",
        scheduledDateFrom: new Date(),
        scheduledDateTo: null
    },
    response: {
        count: 0,
        driveTrackerList: []
    }
};
export const activeClients = createSlice({
    name: 'driverTrackerData',
    initialState: { ...driverTrackerDataInitialState },
    reducers: {
        setDriveTrackerData: (state, action) => {
            state.response = action.payload.response;
            state.request = { ...state.request, ...action.payload.request };
        },
        resetDriveTrackerState: (state, action) => {
            state.response = action.payload.response;
            state.request = { ...action.payload.request };
        },
    },
})

// Action creators are generated for each case reducer function
export const { setDriveTrackerData, resetDriveTrackerState } = activeClients.actions

export default activeClients.reducer