import api from './api'
import { addLogCandidate, addorganization, backendSpocFrontendSpocOrganizationWise, selectedCandidateLog, clientBuOrganizationWise, clientHrOrganizationWise, dashboardBackoutCandidate, dashboardJoinedCandidateGetFeedback, dashboardJoinedCandidateList, dashboardJoinedCandidateTakeFeedback, dashboardRecruiterList, interviewsToBeScheduled, organizationRolewise, roleWisePosition, organizationListroleWiseuserTypeWise, dashboardBackupRecruiterRecruiterList, dashboardNotificationCount, dashboardNotificationDetail, notificationReadNotification, readNotification, notificationMarkAllAsRead, getButkTeamAllocationOrganizationListRolewiseAPI } from './urls'

export const getInterviewsToBeScheduled = async (value: any) => {
    try {
        return await api
            .post(`${interviewsToBeScheduled}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getJoinedCandidateList = async (value: any) => {
    try {
        return await api
            .post(`${dashboardJoinedCandidateList}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getdBackoutCandidateList = async (value: any) => {
    try {
        return await api.post(`${dashboardBackoutCandidate}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const BackendSpocfrontendSpocList = async (value: any) => {
    try {
        return await api
            .post(`${backendSpocFrontendSpocOrganizationWise}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const clientBuOrganizationWiseList = async (value: any) => {
    try {
        return await api
            .post(`${clientBuOrganizationWise}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const joinedCandidateTakeFeedback = async (value: any) => {
    try {
        return await api
            .post(`${dashboardJoinedCandidateTakeFeedback}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getDashboardRecruiterAPI = async () => {
    try {
        return await api.get(`${dashboardRecruiterList}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getDashboardBackupRecruiterAPI = async () => {
    try {
        return await api.get(`${dashboardBackupRecruiterRecruiterList}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const dashboardJoinedCandidataGetFeedback = async (candidateId, positionId) => {
    try {
        return await api.get(`${dashboardJoinedCandidateGetFeedback}/${candidateId}/${positionId}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getAddorganizationAPI = async () => {
    try {
        return await api.get(`${addorganization}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getorganizationRolewiseAPI = async (value: any) => {
    try {
        return await api.get(`${organizationRolewise}/${value}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getButkTeamAllocationOrganizationRolewiseAPI = async (value: any) => {
    try {
        return await api.get(`${getButkTeamAllocationOrganizationListRolewiseAPI}/${value}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getDashboardNotificationCount = async (value: any) => {
    try {
        return await api.get(`${dashboardNotificationCount}/${value}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getReadNotifications = async (payload: any) => {
    try {
        return await api.post(`${notificationReadNotification}`,payload)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const putReadNotification = async (id) => {
    try {
        return await api.put(readNotification + '/' + id)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    } catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const markAllAllRead = async (payload:any) => {
    try {
        return await api
            .put(`${notificationMarkAllAsRead}`,payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getDashboardNotificationDetail = async (value: any) => {
    try {
        return await api
            .post(`${dashboardNotificationDetail}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getPositionsRolewiseAPI = async (value: any) => {
    try {
        return await api.get(`${roleWisePosition}/${value}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getOrganizationListroleWiseuserTypeWise = async (value: any) => {
    try {
        return await api.get(`${organizationListroleWiseuserTypeWise}/${value.primaryRole}/${value.userType}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionClientHrOrganizationWiseAPI = async (value: any) => {
    try {
        return await api
            .post(`${clientHrOrganizationWise}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addLogCandidateAPI = async (user: Object) => {
    try {
        return await api.post(addLogCandidate, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidateLogAPI = async (payload) => {
    try {
        return await api.post(`${selectedCandidateLog}`, payload)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
