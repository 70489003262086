import { REMOVE_LOADER_STATE, SET_ALL_LOADER_STATE, SET_LOADER_STATE } from "_store/constants/loader";

interface IPayload {
  type: string;
  data: any
}
export const setAllLoder = (payload: IPayload) => (dispatch: Function) => {
  dispatch({ type: SET_ALL_LOADER_STATE, payload })
}
export const setLoader = (payload: IPayload) => (dispatch: Function) => {
  dispatch({ type: SET_LOADER_STATE, payload })
}
export const resetLoaders = () => (dispatch: Function) => {
  dispatch({ type: REMOVE_LOADER_STATE })
}