import styles from './index.module.scss';
import { Createuser } from '../../utilities/images/icons/index';
import PrimaryButton from 'widgets/PrimaryButton';


const DeleteUser = (props) => {

  return (

    <div className={styles.invitemodal}>
      <Createuser className={styles.modalTopIcon} />
      <h5 className={styles.commonTitle}>
        <strong>Are you sure?</strong>&nbsp;
      </h5>
      <h6>Do you want to delete the user?</h6>
      <div className="d-flex justify-content-center align-items-center mb-4">
        <div className={`m-0 ${styles.button}`}>
          <PrimaryButton
            className="m-2"
            method={props.hide}
            text="No"
          />
        </div>
        <PrimaryButton
          className="m-2"
          text={'Yes'}
          method={props.deleteuser}
        />
      </div>
    </div>

  );
};

export default DeleteUser;