import {
  REMOVE_CLIENTLASTACTION_STATE,
  SET_ALL_CLIENTLASTACTION_STATE,
  SET_CLIENTLASTACTION_STATE,
} from '_store/constants/filterData_ClientLastAction';
import Action from '../../types/action';

const initialState = {
  organizations: [],
      vertical: [],
      positions: [],
      clientHR: [],
      receivedFrom: null,
      receivedTo: null
};
const filterData_ClientlastAction = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_CLIENTLASTACTION_STATE:
      localStorage.setItem('clientLastActionFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data };
      break;
      case SET_ALL_CLIENTLASTACTION_STATE:
        localStorage.setItem('clientLastActionFilter', JSON.stringify({ ...state, ...action.payload }));
        return { ...state, ...action.payload };
        break;
    case REMOVE_CLIENTLASTACTION_STATE:
      localStorage.removeItem('clientLastActionFilter');
      return initialState;
      break;
    default:
      return state;
  }
};
export default filterData_ClientlastAction;
