import Action from '../../types/action'

export const SET = 'SET'
export const GET = 'GET'
const initialState = {
    data: []
}
const dagData = (state = initialState, action: Action) => {

    switch (action.type) {
        case SET:
            return { ...state, data: action.payload }
        case GET:
            return { ...state, data: state.data }
        default:
            return state
    }
}
export default dagData