export const SET_FILTERDATA = 'SET_FILTERDATA'
export const REMOVE_FILTERDATA_FILTERDATA = 'REMOVE_FILTERDATA_FILTERDATA'
export const SET_EMAIL = 'SET_EMAIL'
export const REMOVE_EMAIL_EMAIL = 'REMOVE_EMAIL_EMAIL'
export const SET_ORGANIZATION_LIST = 'SET_ORGANIZATION_LIST'
export const REMOVE_ORGANIZATION_LIST = 'REMOVE_ORGANIZATION_LIST'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const REMOVE_ORGANIZATION_ORGANIZATION = 'REMOVE_ORGANIZATION_ORGANIZATION'
export const SET_ROLE_LIST = 'SET_ROLE_LIST'
export const REMOVE_ROLE_LIST = 'REMOVE_ROLE_LIST'
export const SET_ROLE = 'SET_ROLE'
export const REMOVE_ROLE_ROLE = 'REMOVE_ROLE_ROLE'
export const SET_VERTICAL_LIST = 'SET_VERTICAL_LIST'
export const REMOVE_VERTICAL_LIST = 'REMOVE_VERTICAL_LIST'
export const SET_VERTICAL = 'SET_VERTICAL'
export const REMOVE_VERTICAL_VERTICAL = 'REMOVE_VERTICAL_VERTICAL'
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST'
export const REMOVE_LANGUAGE_LIST = 'REMOVE_LANGUAGE_LIST'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const REMOVE_LANGUAGE_LANGUAGE = 'REMOVE_LANGUAGE_LANGUAGE'
export const SET_INDUSTRY_LIST = 'SET_INDUSTRY_LIST'
export const REMOVE_INDUSTRY_LIST = 'REMOVE_INDUSTRY_LIST'
export const SET_INDUSTRY = 'SET_INDUSTRY'
export const REMOVE_INDUSTRY_INDUSTRY = 'REMOVE_INDUSTRY_INDUSTRY'
export const SET_FUNCTION_LIST = 'SET_FUNCTION_LIST'
export const REMOVE_FUNCTION_LIST = 'REMOVE_FUNCTION_LIST'
export const SET_FUNCTION = 'SET_FUNCTION'
export const REMOVE_FUNCTION_FUNCTION = 'REMOVE_FUNCTION_FUNCTION'
export const SET_SKILL_LIST = 'SET_SKILL_LIST'
export const REMOVE_SKILL_LIST = 'REMOVE_SKILL_LIST'
export const SET_SKILL = 'SET_SKILL'
export const REMOVE_SKILL_SKILL = 'REMOVE_SKILL_SKILL'
export const SET_DESIGNATION_LIST = 'SET_DESIGNATION'
export const REMOVE_DESIGNATION_LIST = 'REMOVE_DESIGNATION'
export const SET_DESIGNATION = 'SET_DESIGNATION'
export const REMOVE_DESIGNATION_DESIGNATION = 'REMOVE_DESIGNATION_DESIGNATION'
export const SET_REPORTINGTO_LIST = 'SET_REPORTINGTO_LIST'
export const REMOVE_REPORTINGTO_LIST = 'REMOVE_REPORTINGTO_LIST'
export const SET_REPORTINGTO = 'SET_REPORTINGTO'
export const REMOVE_REPORTINGTO_REPORTINGTO = 'REMOVE_REPORTINGTO_REPORTINGTO'
export const SET_CITY_LIST = 'SET_CITY_LIST'
export const REMOVE_CITY_LIST = 'REMOVE_CITY_LIST'
export const SET_CITY = 'SET_CITY'
export const REMOVE_CITY_CITY = 'REMOVE_CITY_CITY'