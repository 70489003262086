
import MomentUtils from '@date-io/moment';
import 'bootstrap/dist/css/bootstrap.css';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import CacheBuster from 'react-cache-buster';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loader from 'widgets/loader';
import { version } from '../package.json';
import App from './App';
import store from './_store/store';
import './index.scss';

const tempHot: any = module;
const rootEl = document.getElementById('root');
const isProduction = process.env.NODE_ENV === 'production';
ReactDOM.render(<>
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  </CacheBuster>
</>,
  document.getElementById('root')
);

if (tempHot.hot) {
  tempHot.hot.accept('./App', function () {

    const NextApp = require('./App');
    ReactDOM.render(<NextApp />, rootEl);
  });

}
