import api from './api'
import {
    addLogCandidate, addLogOrg, addUserDetails, allaccountdetails, allaccountroles, alluserlist, apibasicrole, apiDashboardSentToClientCandidateInformationList, apidesignation, apifunction, apimastercity, apimastercourse, apimasterdata, apimasterdesignations, apimasterfunction, apimasterlanguage, apimasterreportingto, apimasterrole, apimasterskill, apimasterspecialization, apimasteruniversity, apimastervertical, apiorganization, apiorganizationedit, apiorganizationteamallocationdetails, apiorganizationtrackingsheet1get,
    apiorganizationtrackingsheet2get, apiorganizationtrackingsheetget, apipermissionlist, apipermissionlistroleWise, apiPositionAssessmentShetGet, apipositionitskills, apipositionlist, apipositionorganizationWise, apipositionprojectname, apipositionrecruiters, apipositionsoftskills, apiroleedit, apiRolewiseOrganization, apiskill, apiusers, apiwiki, assessmentExcelOrganization, assessmentExcelOrgnization, assessmentExcelPosition, assessmentWordOrgnization, assessmentWordPosition, candidateImageUpload, dashboardSentToClientEmail, dashboardSentToClientFilterPositionListBackendSpocWise, dashboardSentToClientPositionList, deleteSingleUser, imageUpload, inviteUserDetails, mastercountry, masterindustry, mastervertical, multiplespecializationcourseWise, organization, positionClienthrCandidateInformation, positionjdmom, positionLog, positionSourcingPointSendForApprove, positionSourcingPointSendForApproveAccept, positionSourcingPointSendForApproveReject, positionSourcingPointStatus, positionSourcingPointTransaction, positiontrackingsheet, reportingtTo, sentToClientFilterBackendspocListOrganizationVerticalWise, sentToClientFilterVerticalListOrganizationWise, trackingExcelOrganization, viewLogCandidate, viewLogOrg, viewLogPosition
} from './urls'
export const allAccountDetails = async (payload) => {
    try {
        return await api
            .get(`${allaccountdetails}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const allAccountRoles = async (payload) => {
    try {
        return await api
            .get(`${allaccountroles}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const allVerticalAPI = async (payload) => {
    try {
        return await api
            .get(`${mastervertical}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const allOrganizationAPI = async (payload) => {
    try {
        return await api
            .get(`${organization}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const allReportingToAPI = async (payload) => {
    try {
        return await api
            .get(`${reportingtTo}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const allCountryAPI = async (payload) => {
    try {
        return await api
            .get(`${mastercountry}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const allDesignationsAPI = async () => {
    try {
        return await api
            .get(`${apidesignation}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const allIndustryAPI = async (payload) => {
    try {
        return await api
            .get(`${masterindustry}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const allRolesAPI = async (payload) => {
    try {
        return await api
            .get(`${apimasterrole}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}



export const getRolesAPI = async () => {
    try {
        return await api
            .get(`${apimasterrole}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getPermissionsAPI = async () => {
    try {
        return await api
            .get(`${apipermissionlist}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getEditDataAPI = async (values) => {
    try {
        return await api
          .get(
            `${apiroleedit}/${values.roleId}/${values.logedInUserRoleName}/${values.key}`
          )
          .then((response) => {
            return { status: response.status, body: response.data };
          })
          .catch((err) => {
            return { status: err.response.status, body: err.response.data };
          });
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getWikiEditDataAPI = async (values) => {
    try {
        return await api
            .get(`${apiwiki}/${values.wikiId}/${values.logedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getUserList = async (payload) => {
    try {
        return await api
            .get(`${alluserlist}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getRoleList = async (payload) => {
    try {
        return await api
            .get(`${allaccountroles}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getInvitationList = async (payload) => {
    try {
        return await api
            .get(`${inviteUserDetails}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const deleteUser = async (payload: any) => {
    try {
        return await api.delete(deleteSingleUser, { data: payload })
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const searchAccountDetails = async (payload) => {
    try {
        return await api
            .get(`${allaccountdetails}?${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const createAccount = async (user: Object) => {
    try {
        return await api.post(allaccountdetails, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addUser = async (user: Object) => {
    try {
        return await api.post(addUserDetails, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addLogAPI = async (user: Object) => {
    try {
        return await api.post(positionLog, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const viewLogAPI = async (payload: any)  => {
    try {
        return await api
            .get(`${positionLog}/${payload.positionId}/${payload.LoggedInUserRoleName}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const addLogPositionAPI = async (user: Object) => {
    try {
        return await api.post(positionLog, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const viewLogpositionAPI = async (payload: Object) => {
    try {
        return await api.post(viewLogPosition, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sendForApprovalApi = async (payload: Object) => {
    try {
        return await api.post(positionSourcingPointSendForApprove, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sendForApprovalAcceptApi = async (payload: Object) => {
    try {
        return await api.post(positionSourcingPointSendForApproveAccept, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sendForApprovalRejectApi = async (payload: Object) => {
    try {
        return await api.post(positionSourcingPointSendForApproveReject, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const viewLogOrgAPI = async (user: Object) => {
    try {
        return await api
            .get(`${viewLogOrg}/${user}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getPositionStatusApi = async (positionId) => {
    try {
        return await api
            .get(`${positionSourcingPointStatus}/${positionId}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const addLogOrgAPI = async (user: Object) => {
    try {
        return await api.post(addLogOrg, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionTrackingSheetAPI = async (user: Object) => {
    try {
        return await api.post(positiontrackingsheet, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const assessmentExcelOrgnizationAPI = async (user: Object) => {
    try {
        return await api.post(assessmentExcelOrgnization, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const assessmentWordOrgnizationAPI = async (user: Object) => {
    try {
        return await api.post(assessmentWordOrgnization, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const assessmentExcelPositionAPI = async (user: Object) => {
    try {
        return await api.post(assessmentExcelPosition, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getSourcingTransactions = async (user: Object) => {
    try {
        return await api.post(positionSourcingPointTransaction, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getClientHrCandidateInformation = async (user: Object) => {
    try {
        return await api.post(positionClienthrCandidateInformation, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const assessmentExcelPositionPutAPI = async (user: Object) => {
    try {
        return await api.put(assessmentExcelPosition, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getPositionAssistmentSheetListAPI = async (values) => {
    try {
        return await api.get(`${apiPositionAssessmentShetGet}/${values.positionId}/${values.LogedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const JdMomGetAPI = async (jdMom: Object) => {
    try {
        return await api
            .get(`${positionjdmom}/${jdMom}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const JdMomAPI = async (jdMom: Object) => {
    try {
        return await api.post(positionjdmom, jdMom)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const JdMomEditAPI = async (jdMom: Object) => {
    try {
        return await api.put(positionjdmom, jdMom)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const assessmentWordPositionAPI = async (user: Object) => {
    try {
        return await api.post(assessmentWordPosition, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const imageUploadAPI = async (user: Object) => {
    try {
        return await api.post(imageUpload, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const editUser = async (user: any) => {
    try {
        const payload = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role,
        };
        return await api.put(alluserlist + '/' + user.id, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const inviteUser = async (user: Object) => {
    try {
        return await api.post(inviteUserDetails, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const resendInvitation = async (id) => {
    try {
        return await api.put(inviteUserDetails + '/' + id)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    } catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getOrganizationAPI = async () => {
    try {
        return await api
            .get(`${apiorganization}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getOrganizationRolewiseAPI = async (payload : any) => {
    try {
        return await api
            .get(`${apiRolewiseOrganization}/${payload.loggedInUserRole}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getProjectNameAPI = async () => {
    try {
        return await api
            .get(`${apipositionprojectname}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getFuntionAPI = async () => {
    try {
        return await api
            .get(`${apifunction}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getSkillAPI = async (payload: any) => {
    try {
        return await api
            .get(`${apiskill}?categoryName=${payload}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getMasterAPI = async () => {
    try {
        return await api
            .get(`${apimasterdata}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getVerticalAPI = async () => {
    try {
        return await api
            .get(`${apimastervertical}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getBDAPI = async () => {
    try {
        return await api
            .get(apiorganizationteamallocationdetails)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getVerticalIdAPI = async () => {
    try {
        return await api
            .get(`${apimastervertical}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getCityAPI = async () => {
    try {
        return await api
            .get(`${apimastercity}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getFunctionAPI = async () => {
    try {
        return await api
            .get(`${apimasterfunction}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getSkillsAPI = async () => {
    try {
        return await api
            .get(`${apimasterskill}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getLanguageAPI = async () => {
    try {
        return await api
            .get(`${apimasterlanguage}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getCourseAPI = async () => {
    try {
        return await api
            .get(`${apimastercourse}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getUniversityAPI = async () => {
    try {
        return await api
            .get(`${apimasteruniversity}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getReportingToAPI = async () => {
    try {
        return await api
            .get(`${apimasterreportingto}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getDesignationsAPI = async () => {
    try {
        return await api
            .get(`${apimasterdesignations}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getSpecializationAPI = async () => {
    try {
        return await api
            .get(`${apimasterspecialization}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getUsersAPI = async (values) => {
    try {
        return await api
            .get(`${apiusers}/${values.id}/${values.userType}/${values.role}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getOrganizationEditAPI = async (values) => {
    try {
        return await api
            .get(`${apiorganizationedit}/${values.id}/${values.organizationType}/${values.LoggedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getpositionlistAPI = async () => {
    try {
        return await api
            .get(`${apipositionlist}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getInternalAgencyRecruiter = async () => {
    try {
        return await api
            .get(`${apipositionrecruiters}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}


export const getItSkillsAPI = async () => {
    try {
        return await api
            .get(`${apipositionitskills}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getSoftSkillsAPI = async () => {
    try {
        return await api
            .get(`${apipositionsoftskills}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getCourseWiseSpecializationMultipleAPI = async (payload: Object) => {
    try {
        return await api.post(multiplespecializationcourseWise, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionorganizationWiseAPI = async (payload) => {
    try {

        return await api.put(apipositionorganizationWise, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidatePositionOganizationWiseAPI = async (payload) => {
    try {

        return await api.post(apipositionorganizationWise, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sentToClientVerticalList = async (payload) => {
    try {

        return await api.post(sentToClientFilterVerticalListOrganizationWise, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sentToClientPositionList = async (payload) => {
    try {

        return await api.post(dashboardSentToClientFilterPositionListBackendSpocWise, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const ClientHRPositionsList = async () => {
    try {

        return await api.post(dashboardSentToClientPositionList)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sentToClientBackendSpocList = async (payload) => {
    try {

        return await api.post(sentToClientFilterBackendspocListOrganizationVerticalWise, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sentToClientFilterData = async (payload) => {
    try {

        return await api.post(apiDashboardSentToClientCandidateInformationList, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const sentToClientEmailApi = async (payload) => {
    try {

        return await api.post(dashboardSentToClientEmail, payload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const addLogCandidateAPI = async (user: Object) => {
    try {
        return await api.post(addLogCandidate, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const viewLogCandidateAPI = async (user: Object) => {
    try {
        return await api
            .get(`${viewLogCandidate}/${user}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getapiorganizationtrackingsheetAPI = async (values) => {
    try {
        return await api.get(`${apiorganizationtrackingsheetget}/${values.organizationId}/${values.LogedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getapiorganizationassessmentsheet1API = async (values) => {
    try {
        return await api.get(`${apiorganizationtrackingsheet1get}/${values.organizationId}/${values.LogedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getapiorganizationassessmentsheet2API = async (values) => {
    try {
        return await api.get(`${apiorganizationtrackingsheet2get}/${values.organizationId}/${values.LogedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const trackingExcelOrganizationAPI = async (user: Object) => {
    try {
        return await api.post(trackingExcelOrganization, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const assessmentExcelOrganizationPutAPI = async (user: Object) => {
    try {
        return await api.put(assessmentExcelOrganization, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const trackingExcelOrganizationPutAPI = async (user: Object) => {
    try {
        return await api.put(trackingExcelOrganization, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionTrackingSheetPutAPI = async (user: Object) => {
    try {
        return await api.put(positiontrackingsheet, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const candidateImageUploadAPI = async (user: Object) => {
    try {
        return await api.post(candidateImageUpload, user)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getPermissionsRoleWiseAPI = async (payload: any) => {
    try {
        return await api
            .get(`${apipermissionlistroleWise}/${payload.basicRoleId}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getBasicRoleAPI = async () => {
    try {
        return await api
            .get(`${apibasicrole}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}