import { combineReducers } from 'redux';
import user from './user'
import message from './message'
import pagePath from './pagePath'
import workFlow from './dagData'
import SignUpData from './signUpData'
import notificationCountData from './notification'
import masterData from './masterData';
import filterData from './filterData';
import tabValue from './tabValue';
import filterData_roles from './filterData_roles';
import filterData_org from './filterData_org';
import filterData_reports from './filterData_reports'
import filterData_position from './filterData_position';
import filterData_candidate from './filterData_candidate';
import filterData_OpenPosition from './filterData_OpenPosition';
import filterData_PendingInterviewFeedback from './filterData_PendingInterviewFeedback';
import filterData_InterviewsToBeScheduled from './filterData_InterviewsToBeScheduled';
import filterData_Joined_Candidates from './filterData_JoinedCandidates';
import filterData_users from './filterData_users';
import filterData_BackoutCandidates from './filterData_BackoutCandidates';
import filterData_SelectedCandidates from './filterData_SelectedCandidates';
import filterData_CandidateTracker from './filterData_CandidateTracker';
import filterData_OverviewReport from './filterData_OverviewReport';
import filterData_BulkTeamAllocation from './filterData_BulkTeamAllocation';
import filterData_OfferPendingCandidate from './filterData_OfferPendingCandidate';
import filterData_UpcomingJoinees from './filterData_UpcomingJoinees';
import filterData_InterviewScheduled from './filterData_InterviewScheduled';
import filterData_AccountsMisReport from './filterData_AccountsMisReport';
import filterData_pendingCvFeedback from './filterData_PendingCvFeedback';
import filterData_DailyLeadReport from './filterData_DailyLeadReport';
import filterData_MonthlyLeadReport from './filterData_MonthlyLeadReport';
import filterData_WeeklyLeadReport from './filterData_WeeklyLeadReport';
import filterData_ClientLastAction from './filterData_ClientLastAction'
import broadcastMessage from './broadcastMessage';
import driverTrackerData from './driveTrackerData';
import filterData_loader from './loader';
import store from '../store';
const reducer = combineReducers({
  driverTrackerData,
  user,
  message,
  pagePath,
  workFlow,
  SignUpData,
  notificationCountData,
  masterData,
  filterData,
  filterData_roles,
  filterData_org,
  filterData_position,
  filterData_users,
  tabValue,
  filterData_reports,
  filterData_candidate,
  filterData_OpenPosition,
  filterData_PendingInterviewFeedback,
  filterData_InterviewsToBeScheduled,
  filterData_Joined_Candidates,
  filterData_BackoutCandidates,
  filterData_SelectedCandidates,
  filterData_CandidateTracker,
  filterData_OverviewReport,
  filterData_BulkTeamAllocation,
  filterData_OfferPendingCandidate,
  filterData_UpcomingJoinees,
  filterData_InterviewScheduled,
  filterData_AccountsMisReport,
  filterData_pendingCvFeedback,
  filterData_DailyLeadReport,
  filterData_MonthlyLeadReport,
  filterData_WeeklyLeadReport,
  broadcastMessage,
  filterData_loader,
  filterData_ClientLastAction
})
// export type RootState = ReturnType<typeof reducer>
export type RootState = ReturnType<typeof store.getState>
export default reducer