import { Role } from '../types';
import * as container from '../containers';
enum path {
  Index = '/',
  CustomerHome = '/home',
  Admin = '/admin',
  Login = '/login',
  ThankYou = '/thank-you',
  TwoFactorAuth1 = '/TwoFactorAuth1',
  TwoFactorAuth2 = '/TwoFactorAuth2',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  SetPassword = '/registration-complete',
  ResetPasswordFeedback = '/resetpassword-feedback',
  ForgotPasswordFeedback = '/forgot-password-feedback',
  Account = '/account',
  Roles = '/user/:userId/:primaryRole/role',
  ToDo = '/:primaryRole/todo/:taskName',
  ToDoDetailView = '/superadmin/todo-detail-view',
  BulkUpload = '/user/bulkupload',
  Users = '/user/:userId/:primaryRole/users',
  UsersDetails = '/user/:userId/:primaryRole/users/edit/:id',
  viewUsers = '/user/:userId/:primaryRole/users/view/:id',
  editPostioin = '/user/:userId/:primaryRole/position/edit/:id',
  editCandidate = '/user/:userId/:primaryRole/candidates/edit/:id',
  viewCandidate = '/user/:userId/:primaryRole/candidates/view/:id',
  editMaskCandidateInfo = '/user/:userId/:primaryRole/candidates/:id/position/:positionId/maskedCandidateInfo',
  previewMaskedInfo = '/user/:userId/:primaryRole/candidates/:id/previewMaskedInfor/:positionId',
  candidateSendToQC = '/user/:userId/:primaryRole/candidates/:id/position/:positionId/sendToQC',
  selectedCandidateCheckList = '/:primaryRole/candidate/edit/candidate-checklist/:id/:positionId',
  joinibilyScore = '/user/:userId/:primaryRole/candidates/:id/:position/:positionId/:organizationId/joinability',
  InterviewFollowUp = '/user/:userId/:primaryRole/candidates/edit/interviewDetails/:id/:positionId/:previousInterviewId',
  scheduleInterviewcandidate = '/user/:userId/:primaryRole/candidates/edit/scheduleInterview/:id/:positionId/:organizationId',
  viewPosition = '/user/:userId/:primaryRole/position/view/:id',
  editPositionSourcingPoints = '/superadmin/editPositionSourcingPoints',
  RecruiterAllocation = '/superadmin/RecruiterAllocation',
  editOrganization = '/user/:userId/:primaryRole/organization/:id/edit',
  viewOrganization = '/user/:userId/:primaryRole/organization/view/:id',
  AdditionalInformation = '/additionalInformation',
  EducationalDetails = '/educationalDetails',
  AddEditViewUserDetails = '/addEditViewUserDetails',
  SalaryBandDetails = '/SalaryBandDetails',
  Organizations = '/user/:userId/:primaryRole/organization',
  Candidates = '/user/:userId/:primaryRole/candidates',
  Wiki = '/user/:userId/:primaryRole/wiki',
  WikiViewMode = '/user/:userId/:primaryRole/wiki/:id',
  Positions = '/user/:userId/:primaryRole/position',
  Reports = '/user/:userId/:primaryRole/reports/:reportType?',
  qcCandidateTracker = '/user/:userId/:primaryRole/reports/qcProductivity/:qcId/:statusId',
  productivityRecruiter = '/user/:userId/:primaryRole/reports/productivity/recruiterData/:recruiterId/:statusId',
  pendingCVFeedbackChild = '/user/:userId/:primaryRole/reports/:positionId/:columnName',
  PortalAllocations = '/user/:userId/:primaryRole/portalAllocation',
  portalAllocationHistory = '/user/:userId/:primaryRole/portalAllocation/portalAllocationHistory',
  DataManagement = '/user/:userId/:primaryRole/data-management',
  UserProfile = '/user-profile',
  UserProfileEdit = '/user-profile-edit',
  AdminProfile = '/admin-profile',
  AdminProfileEdit = '/admin-profile-edit',
  UserChangePassword = '/user-password-change',
  ProfilePage = '/profile',
  allUserChangePassword = '/user/setting/change-password',
  loggedinUsers = '/user/setting/loggedin-users',
  accessDetails = '/user/setting/loggedin-users/access-details',
  NotificationPage = '/notifications',
  BillingInformation = '/BillingInformation',
  ContactInformation = '/ContactInformation',
  Calendars = '/user/:userId/:primaryRole/position/:positionId/interviewSlots',
  Calendars1 = '/user/:userId/:primaryRole/calendars/Candidate/:candidateId/:positionId/:organizationId',
  CalendarsReshedule = '/user/:userId/:primaryRole/calendars/Candidate/:candidateId/:positionId/:organizationId/:interviewSlotId/:previusInterviewId',
  CandidateInformation = '/user/:userId/:primaryRole/position/:positionId/CandidateInformation',
  Screeningsheet = '/user/:userId/:primaryRole/candidates/:candidateId/view/screeningsheet/:screeningSheetId/:positionId',
  ScreeningsheetEdit = '/user/:userId/:primaryRole/candidates/:candidateId/edit/screeningsheet/:screeningSheetId/:positionId',
  ScreeningsheetCandidate = '/user/:userId/:primaryRole/candidates/:candidateId/editScreening/:positionId',
  Postjoiningfeedback = '/user/:userId/:primaryRole/position/:positionId/post-joining-feedback',
  Viewmaskedcandidateinfo = '/user/:userId/:primaryRole/candidates/:id/previewCandidateMaskedInfo/:positionId',
  SendToqcform = '/user/:userId/:primaryRole/candidates/:id/position/:positionId/sendToQC',
  ViewSourcingpoint = '/superadmin/position/view/:id',
  UpdateqcFeedback = '/user/:userId/:primaryRole/position/:positionId/candidate/:candidateId/UpdateqcFeedback',
  UserReportOpenPositions = '/user/report/open-positions',
  PendingInterviewFeedback = '/user/report/pending-interview-feedback',
  UserReportCandidateTracker = '/user/report/candidate-tracker',
  UserReportInterviewsToBeScheduled = '/user/report/interviews-to-be-scheduled',
  userReportJoinedCandidates = '/user/report/joined-candidates',
  clientLastActionDashboardTile = '/user/report/clientLastAction',
  userReportPendingInterviewFeedbackCandidates = '/user/report/pending-interview-feedback/candidates/:organizationId/:positionId/:interviewStage',
  userReportJoinedBackoutOverviewCandidateDetails = '/user/report/joined-backout-overview/candidate-details/:organizationId/:positionId/:interviewStage',
  JoinedBackoutOverviews = '/user/report/joined-backout-overview/candidate/:year/:month/:type',
  userReportCandidateDetails = '/user/report/candidate-details',
  userReportBackoutcandidatess = '/user/report/backout-candidates',
  driverTracker = '/user/Admin/driverTracker',
  userReportselectedCandidates = '/user/report/selected-candidates',
  userReportselectedCandidatesWithException = '/user/report/selected-candidates-with-exception',
  SPOCSentToClient = '/user/candidates/sendToClient',
  SPOCOfferPendingCandidate = '/user/report/offer-pending-candidate',
  userReportUpcomingJoinees = '/user/report/upcoming-joinees',
  userReportOfferPendingCandidates = '/user/report/offer-pending-candidates',
  SPOCBulkTeamAllocation = '/user/candidates/bulkTeamAllocation',
  DriveSummaryReport = '/user/DriveSummaryReport/:date/:id',
  userReportJoinedBackoutOverview = '/user/report/joined-backout-overview',
  InterviewScheduledUrl = '/user/report/interviews',
  UserAccountsMisReportUrl = '/user/accounts/mis-report',
  UserAccountsMisReporPaymentTrackertUrl = '/user/Accounts/mis-report/paymentTracker',
  PendingSourcingPointTable = '/user/:userId/:primaryRole/pendingSourcingPoint',
  CandidateBulkUpload = '/user/setting/upload-bulk-candidates',

  // Dashboard Routes
  dashboardRoute = '/user/:userId/:primaryRole/dashboard',

  //lead Reoprts
  leadReports = '/user/:userId/:primaryRole/lead-report',
  dailyLeadReports = '/user/:userId/:primaryRole/lead-report/daily-report',
  weeklyLeadReports = '/user/:userId/:primaryRole/lead-report/weekly-report',
  monthlyLeadReports = '/user/:userId/:primaryRole/lead-report/monthly-report',

  //Advanced Serach
  candidateSearch = '/user/:userId/:primaryRole/candidate-search',
  savedSearch = '/user/:userId/:primaryRole/saved-search'
}
export interface RouteTemplate {
  path: string;
  Component: any;
  type?: string;
  Role?: string;
  name?: any;
  icon?: any;
  isVisible?: boolean;
  isActive?: boolean;
}

const commonTeporaryRoute: RouteTemplate[] = [
  
  {
    path: `${path.CandidateBulkUpload}`,
    Component: container.CandidateBulkUpload,
    name: 'Candidate Bulk upload',
    icon: 'Candidate Bulk upload',
    isVisible: false,
  },
  {
    path: `${path.PortalAllocations}`,
    Component: container.PortalAllocations,
  },
  {
    path: `${path.portalAllocationHistory}`,
    Component: container.portalAllocationHistory,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },
  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.accessDetails}`,
    Component: container.AccessDetails,
  },
  {
    path: `${path.ScreeningsheetEdit}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Index}`,
    Component: container.CustomerHome,
  },
  {
    path: `${path.CustomerHome}`,
    Component: container.CustomerHome,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.CalendarsReshedule}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.ScreeningsheetCandidate}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Screeningsheet}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Viewmaskedcandidateinfo}`,
    Component: container.Viewmaskedcandidateinfo,
  },
  {
    path: `${path.SendToqcform}`,
    Component: container.SendToqcform,
  },

  {
    path: `${path.Roles}`,
    Component: container.Roles,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
  {
    path: `${path.Roles}`,
    Component: container.Roles,
  },
  {
    path: `${path.BulkUpload}`,
    Component: container.BulkUpload,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.Postjoiningfeedback}`,
    Component: container.Postjoiningfeedback,
  },
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.UpdateqcFeedback}`,
    Component: container.UpdateQCFeedback,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.previewMaskedInfo}`,
    Component: container.PreviewMaskedInfo,
  },
  {
    path: `${path.candidateSendToQC}`,
    Component: container.SendToQC,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.ContactInformation}`,
    Component: container.ContactInformation,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.EducationalDetails}`,
    Component: container.EducationalDetails,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
  },
  {
    path: `${path.DataManagement}`,
    Component: container.DataManagement,
  },
  {
    path: `${path.AdminProfileEdit}`,
    Component: container.AdminProfileEdit,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.NotificationPage}`,
    Component: Notification,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  },
  {
    path: `${path.loggedinUsers}`,
    Component: container.LoggedInUsers,
  },
  {
    path: `${path.allUserChangePassword}`,
    Component: container.ChangePassword,
  },
  {
    path: `${path.dailyLeadReports}`,
    Component: container.DailyLeadReports,
  },
  {
    path: `${path.weeklyLeadReports}`,
    Component: container.WeeklyLeadReports,
  },
  {
    path: `${path.monthlyLeadReports}`,
    Component: container.MonthlyLeadReports,
  },
  {
    path: `${path.ResetPasswordFeedback}`,
    Component: container.ResetPasswordFeedback,
  },  
];

const accountAdmin: RouteTemplate[] = [
  {
    path: `${path.Index}`,
    Component: container.CustomerHome,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.CustomerHome}`,
    Component: container.CustomerHome,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.UserProfile}`,
    Component: container.UserProfile,
  },
  {
    path: `${path.UserProfileEdit}`,
    Component: container.UserProfileEdit,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.NotificationPage}`,
    Component: Notification,
  },
];

const AccountsHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.MISReportDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.EducationalDetails}`,
    Component: container.EducationalDetails,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.UserAccountsMisReportUrl}`,
    Component: container.AccountMISReport,
    name: 'MIS Report',
    icon: 'MISReport',
    isVisible: false,
  },
  {
    path: `${path.UserAccountsMisReporPaymentTrackertUrl}`,
    Component: container.PaymentTracker,
    name: 'MIS Report',
    icon: 'MISReport',
    isVisible: false,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.ContactInformation}`,
    Component: container.ContactInformation,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  },
  
];

const AdminHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.CandidateBulkUpload}`,
    Component: container.CandidateBulkUpload,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.dashboardRoute}`,
    Component: container.AdminDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.userReportJoinedBackoutOverview}`,
    Component: container.OverviewReport,
    name: 'Overview Report',
    icon: 'Overview Report',
    isVisible: false,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.UserReportCandidateTracker}`,
    Component: container.CandidateTracker,
    name: 'CandidateTracker',
    icon: 'CandidateTracker',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.driverTracker}`,
    Component: container.DriverTracker,
    name: 'Driver Tracker',
    icon: 'Driver Tracker',
    isVisible: false,
  },
  {
    path: `${path.DriveSummaryReport}`,
    Component: container.DriveSummaryReport,
    name: 'Driver Summary Report',
    icon: 'Driver Summary Report',
    isVisible: false,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.Roles}`,
    Component: container.Roles,
    name: 'Roles',
    icon: 'RoleUser',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },

  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.BulkUpload}`,
    Component: container.BulkUpload,
    name: 'Bulk',
    icon: 'Bulk',
    isVisible: true,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedBackoutOverviewCandidateDetails}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidate Details',
    icon: 'Candidate Details',
    isVisible: false,
  },
  {
    path: `${path.JoinedBackoutOverviews}`,
    Component: container.OverViewTable,
    name: 'Over View Table',
    icon: 'Over View Table',
    isVisible: false,
  },
  {
    path: `${path.userReportCandidateDetails}`,
    Component: container.OpenPositionTable,
    name: 'Open Position Table',
    icon: 'Open Position Table',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.PortalAllocations}`,
    Component: container.PortalAllocations,
    name: 'Portal Allocation',
    icon: 'PortalAllocation',
    isVisible: true,
  },
  {
    path: `${path.portalAllocationHistory}`,
    Component: container.portalAllocationHistory,
    name: 'PortalAllocationHistory',
    icon: 'PortalAllocationHistory',
    isVisible: false,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
    name: 'Lead Reports',
    icon: 'leadReports',
    isVisible: true,
  },
  {
    path: `${path.DataManagement}`,
    Component: container.DataManagement,
    name: 'Data Management',
    icon: 'Data',
    isVisible: true,
  }
];

const agencyRCRHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.AgencyRCRDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  }   
];

const BDHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.BDDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.ContactInformation}`,
    Component: container.ContactInformation,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  }    
];

const ClientHRHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.ClientHrDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  }, 
  {
    path: `${path.PendingSourcingPointTable}`,
    Component: container.PendingSourcingPointTable,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  }
];

const ClientManagerHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.ClientManagerDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportCandidateTracker}`,
    Component: container.CandidateTracker,
    name: 'CandidateTracker',
    icon: 'CandidateTracker',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.userReportCandidateDetails}`,
    Component: container.OpenPositionTable,
    name: 'Open Position Table',
    icon: 'Open Position Table',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
];

const InternalHRHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.InternalHRDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },
  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.previewMaskedInfo}`,
    Component: container.PreviewMaskedInfo,
  },
  {
    path: `${path.candidateSendToQC}`,
    Component: container.SendToQC,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'SelectedCandidates',
    icon: 'SelectedCandidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  },
  {
    path: `${path.SPOCOfferPendingCandidate}`,
    Component: container.OfferPendingCandidate,
    name: 'OfferPendingCandidate',
    icon: 'OfferPendingCandidate',
    isVisible: false,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  }
];

const interviewerHeaderRoutes: RouteTemplate[] = [
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },  
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
];

const ManagerHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.ManagerDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.UserAccountsMisReportUrl}`,
    Component: container.AccountMISReport,
    name: 'MIS Report',
    icon: 'MISReport',
    isVisible: false,
  },
  {
    path: `${path.UserAccountsMisReporPaymentTrackertUrl}`,
    Component: container.PaymentTracker,
    name: 'MIS Report',
    icon: 'MISReport',
    isVisible: false,
  },
  {
    path: `${path.pendingCVFeedbackChild}`,
    Component: container.pendingCVFeedbackChild,
  },
  {
    path: `${path.userReportCandidateDetails}`,
    Component: container.OpenPositionTable,
    name: 'Open Position Table',
    icon: 'Open Position Table',
    isVisible: false,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  
  {
    path: `${path.PortalAllocations}`,
    Component: container.PortalAllocations,
    name: 'Portal Allocation',
    icon: 'PortalAllocation',
    isVisible: true,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
    name: 'Lead Reports',
    icon: 'leadReports',
    isVisible: true,
  },
  {
    path: `${path.DataManagement}`,
    Component: container.DataManagement,
    name: 'Data Management',
    icon: 'Data',
    isVisible: true,
  },
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.UserReportCandidateTracker}`,
    Component: container.CandidateTracker,
    name: 'CandidateTracker',
    icon: 'CandidateTracker',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'SelectedCandidates',
    icon: 'SelectedCandidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.driverTracker}`,
    Component: container.DriverTracker,
    name: 'Driver Tracker',
    icon: 'Driver Tracker',
    isVisible: false,
  },
  {
    path: `${path.DriveSummaryReport}`,
    Component: container.DriveSummaryReport,
    name: 'Driver Summary Report',
    icon: 'Driver Summary Report',
    isVisible: false,
  },
  {
    path: `${path.SPOCOfferPendingCandidate}`,
    Component: container.OfferPendingCandidate,
    name: 'OfferPendingCandidate',
    icon: 'OfferPendingCandidate',
    isVisible: false,
  },
  {
    path: `${path.SPOCBulkTeamAllocation}`,
    Component: container.BulkTeamAllocation,
    name: 'BulkTeamAllocation',
    icon: 'BulkTeamAllocation',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  }
];

const peoplefyHeaderRoutes: RouteTemplate[] = [
  {
    path: `${path.CustomerHome}`,
    Component: container.CustomerHome,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Roles}`,
    Component: container.Roles,
    name: 'Roles',
    icon: 'RoleUser',
    isVisible: true,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organizations',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.BulkUpload}`,
    Component: container.BulkUpload,
    name: 'BulkUpload',
    icon: 'Bulk',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.PortalAllocations}`,
    Component: container.PortalAllocations,
    name: 'Portal Allocation',
    icon: 'PortalAllocation',
    isVisible: true,
  },
  {
    path: `${path.portalAllocationHistory}`,
    Component: container.portalAllocationHistory,
    name: 'Portal Allocation',
    icon: 'PortalAllocation',
    isVisible: false,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
    name: 'Lead Reports',
    icon: 'leadReports',
    isVisible: true,
  },
  {
    path: `${path.DataManagement}`,
    Component: container.DataManagement,
    name: 'Data Management',
    icon: 'Data',
    isVisible: true,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
];

const QCHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.QCdashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.UpdateqcFeedback}`,
    Component: container.UpdateQCFeedback,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.ScreeningsheetEdit}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.CalendarsReshedule}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.ScreeningsheetCandidate}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Screeningsheet}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Viewmaskedcandidateinfo}`,
    Component: container.Viewmaskedcandidateinfo,
  },
  {
    path: `${path.SendToqcform}`,
    Component: container.SendToqcform,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  }, 
  {
    path: `${path.ResetPasswordFeedback}`,
    Component: container.ResetPasswordFeedback,
  },    
];

const RecruiterHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.RecruiterDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  },
  {
    path: `${path.pendingCVFeedbackChild}`,
    Component: container.pendingCVFeedbackChild,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.Postjoiningfeedback}`,
    Component: container.Postjoiningfeedback,
  },
  {
    path: `${path.SPOCSentToClient}`,
    Component: container.SentToClient,
    name: 'SentToClient',
    icon: 'SentToClient',
    isVisible: false,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.Viewmaskedcandidateinfo}`,
    Component: container.Viewmaskedcandidateinfo,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.CalendarsReshedule}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.ScreeningsheetEdit}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.ScreeningsheetCandidate}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Screeningsheet}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.SendToqcform}`,
    Component: container.SendToqcform,
  },
  {
    path: `${path.candidateSendToQC}`,
    Component: container.SendToQC,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
    name: 'Lead Reports',
    icon: 'leadReports',
    isVisible: true,
  },
  {
    path: `${path.dailyLeadReports}`,
    Component: container.DailyLeadReports,
  },
  {
    path: `${path.weeklyLeadReports}`,
    Component: container.WeeklyLeadReports,
  },
  {
    path: `${path.monthlyLeadReports}`,
    Component: container.MonthlyLeadReports,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.SPOCOfferPendingCandidate}`,
    Component: container.OfferPendingCandidate,
    name: 'OfferPendingCandidate',
    icon: 'OfferPendingCandidate',
    isVisible: false,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'SelectedCandidates',
    icon: 'SelectedCandidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
];

const RPOHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.RPODashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.EducationalDetails}`,
    Component: container.EducationalDetails,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.ContactInformation}`,
    Component: container.ContactInformation,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.ScreeningsheetEdit}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.CalendarsReshedule}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.ScreeningsheetCandidate}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Screeningsheet}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Viewmaskedcandidateinfo}`,
    Component: container.Viewmaskedcandidateinfo,
  },
  {
    path: `${path.SendToqcform}`,
    Component: container.SendToqcform,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  
  {
    path: `${path.driverTracker}`,
    Component: container.DriverTracker,
    name: 'Driver Tracker',
    icon: 'Driver Tracker',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.DriveSummaryReport}`,
    Component: container.DriveSummaryReport,
    name: 'Driver Summary Report',
    icon: 'Driver Summary Report',
    isVisible: false,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  }
];

const SPOCHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },  
  {
    path: `${path.clientLastActionDashboardTile}`,
    Component: container.clientLastAction,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },  
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.userReportCandidateDetails}`,
    Component: container.OpenPositionTable,
    name: 'Open Position Table',
    icon: 'Open Position Table',
    isVisible: false,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.pendingCVFeedbackChild}`,
    Component: container.pendingCVFeedbackChild,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
  {
    path: `${path.dashboardRoute}`,
    Component: container.SPOCDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.UserProfile}`,
    Component: container.UserProfile,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.UserProfileEdit}`,
    Component: container.UserProfileEdit,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.ScreeningsheetEdit}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.CalendarsReshedule}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.ScreeningsheetCandidate}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Screeningsheet}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Viewmaskedcandidateinfo}`,
    Component: container.Viewmaskedcandidateinfo,
  },
  {
    path: `${path.SendToqcform}`,
    Component: container.SendToqcform,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.SPOCSentToClient}`,
    Component: container.SentToClient,
    name: 'SentToClient',
    icon: 'SentToClient',
    isVisible: false,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  },
  {
    path: `${path.SPOCOfferPendingCandidate}`,
    Component: container.OfferPendingCandidate,
    name: 'OfferPendingCandidate',
    icon: 'OfferPendingCandidate',
    isVisible: false,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.UserReportCandidateTracker}`,
    Component: container.CandidateTracker,
    name: 'CandidateTracker',
    icon: 'CandidateTracker',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'SelectedCandidates',
    icon: 'SelectedCandidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.SPOCBulkTeamAllocation}`,
    Component: container.BulkTeamAllocation,
    name: 'BulkTeamAllocation',
    icon: 'BulkTeamAllocation',
    isVisible: false,
  },
  {
    path: `${path.DriveSummaryReport}`,
    Component: container.DriveSummaryReport,
    name: 'Driver Summary Report',
    icon: 'Driver Summary Report',
    isVisible: false,
  },  
  {
    path: `${path.PortalAllocations}`,
    Component: container.PortalAllocations,
    name: 'Portal Allocation',
    icon: 'PortalAllocation',
    isVisible: true,
  },
  {
    path: `${path.portalAllocationHistory}`,
    Component: container.portalAllocationHistory,
    name: 'PortalAllocationHistory',
    icon: 'PortalAllocationHistory',
    isVisible: false,
  },
  {
    path: `${path.driverTracker}`,
    Component: container.DriverTracker,
    name: 'Driver Tracker',
    icon: 'Driver Tracker',
    isVisible: false,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  },
  {
    path: `${path.Postjoiningfeedback}`,
    Component: container.Postjoiningfeedback,
  },
];

const superAdminRoutes: RouteTemplate[] = [
  ...peoplefyHeaderRoutes,
  {
    path: `${path.Index}`,
    Component: container.CustomerHome,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },
  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.CustomerHome}`,
    Component: container.CustomerHome,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.Calendars1}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.CalendarsReshedule}`,
    Component: container.Calendars1,
  },
  {
    path: `${path.ScreeningsheetCandidate}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Screeningsheet}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.ScreeningsheetEdit}`,
    Component: container.Screeningsheet,
  },
  {
    path: `${path.Viewmaskedcandidateinfo}`,
    Component: container.Viewmaskedcandidateinfo,
  },
  {
    path: `${path.SendToqcform}`,
    Component: container.SendToqcform,
  },

  {
    path: `${path.Roles}`,
    Component: container.Roles,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
  {
    path: `${path.Roles}`,
    Component: container.Roles,
  },
  {
    path: `${path.BulkUpload}`,
    Component: container.BulkUpload,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
  },
  {
    path: `${path.UsersDetails}`,
    Component: container.UsersDetails,
  },
  {
    path: `${path.viewUsers}`,
    Component: container.viewUsers,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.Postjoiningfeedback}`,
    Component: container.Postjoiningfeedback,
  },
  {
    path: `${path.UpdateqcFeedback}`,
    Component: container.UpdateQCFeedback,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.previewMaskedInfo}`,
    Component: container.PreviewMaskedInfo,
  },
  {
    path: `${path.candidateSendToQC}`,
    Component: container.SendToQC,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.viewPosition}`,
    Component: container.viewPosition,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.ContactInformation}`,
    Component: container.ContactInformation,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.EducationalDetails}`,
    Component: container.EducationalDetails,
  },
  {
    path: `${path.AddEditViewUserDetails}`,
    Component: container.AddEditViewUserDetails,
  },
  {
    path: `${path.AdditionalInformation}`,
    Component: container.AdditionalInformation,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
  },
  {
    path: `${path.DataManagement}`,
    Component: container.DataManagement,
  },
  {
    path: `${path.AdminProfileEdit}`,
    Component: container.AdminProfileEdit,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
  {
    path: `${path.NotificationPage}`,
    Component: Notification,
  },
  {
    path: `${path.allUserChangePassword}`,
    Component: container.ChangePassword,
  },
  {
    path: `${path.loggedinUsers}`,
    Component: container.LoggedInUsers,
  },
  {
    path: `${path.accessDetails}`,
    Component: container.AccessDetails,
  },
  {
    path: `${path.qcCandidateTracker}`,
    Component: container.qcCandidateTracker,
  },
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.dailyLeadReports}`,
    Component: container.DailyLeadReports,
  },
  {
    path: `${path.weeklyLeadReports}`,
    Component: container.WeeklyLeadReports,
  },
  {
    path: `${path.monthlyLeadReports}`,
    Component: container.MonthlyLeadReports,
  },
];

const TeamLeadHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.TeamLeadDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.UserAccountsMisReporPaymentTrackertUrl}`,
    Component: container.PaymentTracker,
    name: 'MIS Report',
    icon: 'MISReport',
    isVisible: false,
  },
  {
    path: `${path.UserAccountsMisReportUrl}`,
    Component: container.AccountMISReport,
    name: 'MIS Report',
    icon: 'MISReport',
    isVisible: false,
  },
  {
    path: `${path.pendingCVFeedbackChild}`,
    Component: container.pendingCVFeedbackChild,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
  {
    path: `${path.SalaryBandDetails}`,
    Component: container.SalaryBandDetails,
  },
  {
    path: `${path.BillingInformation}`,
    Component: container.BillingInformation,
  },
  {
    path: `${path.ContactInformation}`,
    Component: container.ContactInformation,
  },
  {
    path: `${path.editOrganization}`,
    Component: container.EditOrganization,
  },
  {
    path: `${path.Calendars}`,
    Component: container.Calendars,
  },
  {
    path: `${path.viewOrganization}`,
    Component: container.viewOrganization,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.editPostioin}`,
    Component: container.editPostioin,
  },
  {
    path: `${path.CandidateInformation}`,
    Component: container.CandidateInformation,
  },
  {
    path: `${path.editPositionSourcingPoints}`,
    Component: container.editPositionSourcingPoints,
  },
  {
    path: `${path.userReportCandidateDetails}`,
    Component: container.OpenPositionTable,
    name: 'Open Position Table',
    icon: 'Open Position Table',
    isVisible: false,
  },
  {
    path: `${path.RecruiterAllocation}`,
    Component: container.RecruiterAllocation,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.candidateSearch}`,
    Component: container.candidateSearch,
  },  
  {
    path: `${path.savedSearch}`,
    Component: container.savedSearch,
  },
  {
    path: `${path.viewCandidate}`,
    Component: container.viewCandidate,
  },
  {
    path: `${path.editCandidate}`,
    Component: container.editCandidate,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.editMaskCandidateInfo}`,
    Component: container.EditMaskCandidateInfo,
  },
  {
    path: `${path.previewMaskedInfo}`,
    Component: container.PreviewMaskedInfo,
  },
  {
    path: `${path.candidateSendToQC}`,
    Component: container.SendToQC,
  },
  {
    path: `${path.selectedCandidateCheckList}`,
    Component: container.SelectedCandidateChecklist,
  },
  {
    path: `${path.joinibilyScore}`,
    Component: container.JoinabilityScoreTable,
  },
  {
    path: `${path.scheduleInterviewcandidate}`,
    Component: container.ScheduleInterview,
  },
  {
    path: `${path.InterviewFollowUp}`,
    Component: container.InterviewFollowUp,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.WikiViewMode}`,
    Component: container.WikiView,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
    name: 'Lead Reports',
    icon: 'leadReports',
    isVisible: true,
  },
  {
    path: `${path.DataManagement}`,
    Component: container.DataManagement,
    name: 'Data Management',
    icon: 'Data',
    isVisible: true,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.DriveSummaryReport}`,
    Component: container.DriveSummaryReport,
    name: 'Driver Summary Report',
    icon: 'Driver Summary Report',
    isVisible: false,
  },
  {
    path: `${path.SPOCOfferPendingCandidate}`,
    Component: container.OfferPendingCandidate,
    name: 'OfferPendingCandidate',
    icon: 'OfferPendingCandidate',
    isVisible: false,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'SelectedCandidates',
    icon: 'SelectedCandidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.SPOCBulkTeamAllocation}`,
    Component: container.BulkTeamAllocation,
    name: 'BulkTeamAllocation',
    icon: 'BulkTeamAllocation',
    isVisible: false,
  },
  {
    path: `${path.driverTracker}`,
    Component: container.DriverTracker,
    name: 'Driver Tracker',
    icon: 'Driver Tracker',
    isVisible: false,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
  },
  {
    path: `${path.dailyLeadReports}`,
    Component: container.DailyLeadReports,
  },
  {
    path: `${path.weeklyLeadReports}`,
    Component: container.WeeklyLeadReports,
  },
  {
    path: `${path.monthlyLeadReports}`,
    Component: container.MonthlyLeadReports,
  },
  {
    path: `${path.productivityRecruiter}`,
    Component: container.productivityRecruiter,
  },
  {
    path: `${path.ProfilePage}`,
    Component: container.Profile,
  },
];

const unsecureRoutes: RouteTemplate[] = [
  {
    path: `${path.Index}`,
    Component: container.Login,
  },
  {
    path: `${path.Login}`,
    Component: container.Login,
  },
  {
    path: `${path.TwoFactorAuth1}`,
    Component: container.TwoFactorAuth1,
  },
  {
    path: `${path.TwoFactorAuth2}`,
    Component: container.TwoFactorAuth2,
  },
  {
    path: `${path.ResetPassword}`,
    Component: container.ResetPassword,
  },
  {
    path: `${path.SetPassword}`,
    Component: container.SetPassword,
  },
  {
    path: `${path.ForgotPassword}`,
    Component: container.ForgotPassword,
  },
  {
    path: `${path.ResetPasswordFeedback}`,
    Component: container.ResetPasswordFeedback,
  },
  {
    path: `${path.ForgotPasswordFeedback}`,
    Component: container.ForgotPasswordFeedback,
  },
];

const userObject: RouteTemplate[] = [
  {
    path: `${path.Index}`,
    Component: container.CustomerHome,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.CustomerHome}`,
    Component: container.CustomerHome,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
];

const VPHeaderRoutes: RouteTemplate[] = [
  ...commonTeporaryRoute,
  {
    path: `${path.dashboardRoute}`,
    Component: container.VPDashboard,
    name: 'Dashboard',
    icon: 'Dashboard',
    isVisible: true,
  },
  {
    path: `${path.pendingCVFeedbackChild}`,
    Component: container.pendingCVFeedbackChild,
  },
  {
    path: `${path.userReportPendingInterviewFeedbackCandidates}`,
    Component: container.CandidatesPositionTable,
    name: 'Candidates Position',
    icon: 'Candidates Position',
    isVisible: false,
  },
  {
    path: `${path.userReportCandidateDetails}`,
    Component: container.OpenPositionTable,
    name: 'Open Position Table',
    icon: 'Open Position Table',
    isVisible: false,
  },
  {
    path: `${path.Users}`,
    Component: container.Users,
    name: 'Users',
    icon: 'Showuser',
    isVisible: true,
  },
  {
    path: `${path.Organizations}`,
    Component: container.Organizations,
    name: 'Organization',
    icon: 'Organization',
    isVisible: true,
  },
  {
    path: `${path.Positions}`,
    Component: container.Positions,
    name: 'Positions',
    icon: 'Positions',
    isVisible: true,
  },
  {
    path: `${path.Candidates}`,
    Component: container.Candidates,
    name: 'Candidates',
    icon: 'Candidates',
    isVisible: true,
  },
  {
    path: `${path.Wiki}`,
    Component: container.Wiki,
    name: 'Wiki',
    icon: 'Wiki',
    isVisible: true,
  },
  {
    path: `${path.Reports}`,
    Component: container.Reports,
    name: 'Reports',
    icon: 'Reports',
    isVisible: true,
  },
  {
    path: `${path.PortalAllocations}`,
    Component: container.PortalAllocations,
    name: 'Portal Allocation',
    icon: 'PortalAllocation',
    isVisible: true,
  },
  {
    path: `${path.leadReports}`,
    Component: container.LeadReports,
    name: 'Lead Reports',
    icon: 'leadReports',
    isVisible: true,
  },
  {
    path: `${path.UserReportOpenPositions}`,
    Component: container.OpenPosition,
    name: 'OpenPosition',
    icon: 'OpenPosition',
    isVisible: false,
  },
  {
    path: `${path.PendingInterviewFeedback}`,
    Component: container.PendingInterviewFeedback,
    name: 'PendingInterviewFeedback',
    icon: 'PendingInterviewFeedback',
    isVisible: false,
  },
  {
    path: `${path.UserReportInterviewsToBeScheduled}`,
    Component: container.InterviewsToBeScheduled,
    name: 'Interviews to be Scheduled',
    icon: 'Interviews to be Scheduled',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedCandidates}`,
    Component: container.JoinedCandidates,
    name: 'Joined Candidates',
    icon: 'Joined Candidates',
    isVisible: false,
  },
  {
    path: `${path.DriveSummaryReport}`,
    Component: container.DriveSummaryReport,
    name: 'Driver Summary Report',
    icon: 'Driver Summary Report',
    isVisible: false,
  },
  {
    path: `${path.userReportUpcomingJoinees}`,
    Component: container.UpcomingJoinees,
    name: 'Upcoming Joinees',
    icon: 'Upcoming Joinees',
    isVisible: false,
  },
  {
    path: `${path.SPOCOfferPendingCandidate}`,
    Component: container.OfferPendingCandidate,
    name: 'OfferPendingCandidate',
    icon: 'OfferPendingCandidate',
    isVisible: false,
  },
  {
    path: `${path.SPOCBulkTeamAllocation}`,
    Component: container.BulkTeamAllocation,
    name: 'BulkTeamAllocation',
    icon: 'BulkTeamAllocation',
    isVisible: false,
  },
  {
    path: `${path.UserReportCandidateTracker}`,
    Component: container.CandidateTracker,
    name: 'CandidateTracker',
    icon: 'CandidateTracker',
    isVisible: false,
  },
  {
    path: `${path.userReportBackoutcandidatess}`,
    Component: container.BackoutCandidates,
    name: 'Backout Candidates',
    icon: 'Backout Candidates',
    isVisible: false,
  },
  {
    path: `${path.userReportJoinedBackoutOverview}`,
    Component: container.OverviewReport,
    name: 'Overview Report',
    icon: 'Overview Report',
    isVisible: false,
  },
  {
    path: `${path.JoinedBackoutOverviews}`,
    Component: container.OverViewTable,
    name: 'Over View Table',
    icon: 'Over View Table',
    isVisible: false,
  },
  {
    path: `${path.userReportselectedCandidates}`,
    Component: container.SelectedCandidates,
    name: 'SelectedCandidates',
    icon: 'SelectedCandidates',
    isVisible: false,
  },{
    path: `${path.userReportselectedCandidatesWithException}`,
    Component: container.SelectedCandidates,
    name: 'Selected Candidates',
    icon: 'Selected Candidates',
    isVisible: false,
  },
  {
    path: `${path.driverTracker}`,
    Component: container.DriverTracker,
    name: 'Driver Tracker',
    icon: 'Driver Tracker',
    isVisible: false,
  },
  {
    path: `${path.InterviewScheduledUrl}`,
    Component: container.InterviewScheduled,
    name: 'Interview Scheduled',
    icon: 'Interview Scheduled',
    isVisible: false,
  },
  {
    path: `${path.ToDo}`,
    Component: container.ToDo,
  },
  {
    path: `${path.ToDoDetailView}`,
    Component: container.ToDoDetailView,
  },
];

const securedRoutesMap = new Map<any, any>();

const peoplefyHeaderRoutesMap = new Map<any, any>();

peoplefyHeaderRoutesMap.set(Role.SuperAdmin, superAdminRoutes);
peoplefyHeaderRoutesMap.set(Role.AccountAdmin, accountAdmin);
peoplefyHeaderRoutesMap.set(Role.User, userObject);
peoplefyHeaderRoutesMap.set(Role.BD, BDHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.Admin, AdminHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.Manager, ManagerHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.InternalHR, InternalHRHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.SPOC, SPOCHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.RPO, RPOHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.Accounts, AccountsHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.Recruiter, RecruiterHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.QC, QCHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.AgencyRCR, agencyRCRHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.Interviewer, interviewerHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.ClientManager, ClientManagerHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.ClientHR, ClientHRHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.VP, VPHeaderRoutes);
peoplefyHeaderRoutesMap.set(Role.TeamLead, TeamLeadHeaderRoutes);

export {
  path,
  Role,
  accountAdmin,
  securedRoutesMap,
  peoplefyHeaderRoutesMap,
};

