import { REMOVE_OFFER_PENDING_CANDIDATE_STATE, SET_OFFER_PENDING_CANDIDATE_STATE } from '_store/constants/filterData_OfferPendingCandidate';
import Action from '../../types/action';

const initialState = {
  organizations: [],
  positions: [],
  recruiters: [],
  documentSentDate: null,
}
const filterData_OfferPendingCandidate = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_OFFER_PENDING_CANDIDATE_STATE:
      return { ...state, [action.payload.type]: action.payload.data }
    case REMOVE_OFFER_PENDING_CANDIDATE_STATE:
      return initialState;
    default:
      return state
  }
}
export default filterData_OfferPendingCandidate