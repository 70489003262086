import { ReactFragment } from 'react'
export interface Handles {
    checkFile: () => void
    removeFile: () => void
}

export interface ForwardMethod {
    method: () => any
    method1?: () => any
    method2?: () => any
}
export interface ListFileProps {
    id: number
    filename: string
    extension: string
}
export interface ShipperDetailsProps {
    userId: string
    address1: string
    company: string
    countryId: number
    countryName: string
    department_id: number
    department_name: string
    email: string
    name: string
    phoneNo: string
    requestDate: string
    status?: string
    listFile: Array<ListFileProps>
}

export interface ForwardMultipleMethod {
    method: () => void
    method1: (value: string) => void
}
export interface IReportTableForwardMethod {
    method: () => Array<any>
    method1: () => void
    method2: () => void
    method3: () => void
    resetRowPerPage: () => void
    method4?: () => void
}
export interface OrgTableForwardMethod {
    method: () => Array<any>
    method1: () => void
    method2: () => void
    method3: () => void
    resetRowPerPage: () => void
    method4?: () => void
}
export interface CandidateTableForwardMethod {
    method: () => Array<any>
    method1: () => void
    method2: () => void
    method3: () => void
    resetRowPerPage: () => void
    method4?: () => void
}
export interface UserTableForwardMethod {
    method: () => Array<any>
    method1: () => void
    method2: () => void
    method3: () => void
    method4?: () => void
}
export interface UserTableForwardProps {
    users: Array<any>
    fetchData: Function
    handleEditUserClick?: Function
    totalCountRows: number
    updateSingleStatus?: Function
    sortOrder: Function
    confirmDeletion: Function
    checkUser: Function
}
export interface UserFilterForwardProps {
    fetchFilterData: Function
    userId: string
    resetFilter: any
}
export interface UserHeaderForwardProps {
    setDataforDownload?: any;
    downPayload?: any;
    fetchData: Function
    addUserByCSV?: Function
    updateMultipleStatuses: Function
    addUser: Function
    searchUsers: Function
    confirmDeletion: Function
    usersSelectedCountParent: number
    isUsers: Function
    listofCompanies?: any[]
    users?: any
    downloadCSV?: any
    isFilteredFlag?: any
    toggleDrawer?: any
    userType?: any
    selectedRoleIds?: any
    selectedVerticalIds?: any
    selectedLanguage?: any
    selectedIndustryIds?: any
    selectedFunctionIds?: any
    selectedSkillIds?: any
    selectedDesignationIds?: any
    selectedReportingToIds?: any
    selectedOrganizationIds?: any
    selectedCityIds?: any
    handleFilerFromHeader?: any
    handleFilerFromHeaderStatus?: any
    handleFilerFromHeaderRole?: any
    handleFilerFromHeaderPermission?: any
    handleFilerFromHeaderName?: any
    handleFilter?: any
     setIsFilterApplied?:any
}

export interface AccountListProps {
    id: string;
    name: string;
    doamin: string;
    isDeleted: boolean;
    isBlocked: boolean;
    isApproved: boolean;
    acceptTerm: boolean;
    approvedDate: Date;
    requestDate: Date;
    rejectDate: Date;
    rejectReason: string;
    address?: any;
}

export interface UserDeletedHeaderForwardProps {
    fetchData: Function
    searchUsers: Function
    usersSelectedCountParent: number
    isUsers: Function
    checkUser?: Function
}
export interface UserHeaderRequestListForwardProps {
    fetchData: Function
    updateMultipleStatuses: Function
    searchUsers: Function
    confirmDeletion: Function
    usersSelectedCountParent: number
    isUsers: Function
    confirmApprove: Function
    confirmReject: Function
}
export interface UserHeaderApprovedRequestListForwardProps {
    fetchData: Function
    searchUsers: Function
    confirmDeletion: Function
    usersSelectedCountParent: number
    isUsers: Function
    addUser: Function
    confirmReject: Function
}
export interface CHAListHeaderForwardProps {
    updateMultipleStatuses: Function
    searchUsers: Function
    confirmDeletion: Function
    usersSelectedCountParent: number
}
export interface ShipperRequestListHeaderForwardProps {
    updateMultipleStatuses?: Function
    searchUsers: Function
    confirmDeletion: Function
    usersSelectedCountParent: number
}

export interface FieldProps {
    name: string
    label_text?: any
    placeholder?: string
    error?: any
    touched?: any
    handleChange?: any
    handleBlur?: any
    value?: any
    type?: any
    disabled?: boolean
    endAdor?: any
    startAdor?: any
    className?: any
    maxLength?: any
    autoFocus?: boolean;
    mandatory?: boolean;
    required?: boolean;
}
export interface NormalSelectProps {
    error?: any
    touched?: any
    handleChange?: any
    handleBlur?: any
    onBlur?: any
    value?: any
    onChange?: any
    type?: any
    helperText?: any
    variant?: any
    margin?: any
    label?: any
    name?: any

}
export interface FeedbackProps {
    IconData?: ReactFragment
    title: string
    content: ReactFragment
    className?: any
}

export interface AddUserDialogProps {
    show?: boolean;
    fileImport?: boolean;
    handleFileImportClose?: Function;
    dialogHandle: Function
    parentMethod?: Function
    parentMethod1?: Function
    csvData?: string
    refreshPage?: Function
    departmentsParent?: Array<DepartmentProps>
    rolesParent?: Array<RoleProps>
}
export interface ChangeCurrentPasswordProps {
    dialogHandle?: any;
}
export interface UsersModalFeedbackProps {
    IconData: ReactFragment
    content: ReactFragment
    leftBtnMethod?: Function
    rightBtnMethod?: Function
    leftBtnText?: string
    rightBtnText: string
    dialogHandle: Function
    parentMethod?: Function
}

export interface AutocompleteProps {
    IconData: ReactFragment
    placeholder: string
    handleChange: Function
    options: Array<any>
    className?: any
}

export interface CompanyTileProps {
    id: string
    logo_url: string
    title: string
    place: string
    isSelected: boolean
    method: Function
}
export interface UserAddProps {
    photo_url?: string
    firstname: string
    lastname: string
    role: string
    email: string
    phoneno: string
    department: string
    location: string
}

export interface Account {
    id: string;
    name: string;
    doamin?: any;
    isDeleted: boolean;
    isBlocked: boolean;
    isApproved: boolean;
    acceptTerm: boolean;
    approvedDate: Date;
    requestDate: Date;
    rejectDate: Date;
    rejectReason: string;
    address?: any;
}

export interface ISysUserTableProps {
    userId: string;
    fullName: string;
    verticle: string;
    organization: string;
    designation: string;
    cityName: string;
    industries: any;
    status: string;
    qrStatus?: any;
    roles: Array<string>;
    checked?: boolean
}
export interface UserTableProps {
    contactNumber: string
    id?: string
    name: string
    role: string
    createdDate: string
    lastAccessDate: string
    isActive: boolean
    email: string
    userId: string
    firstName: string
    lastName: string
    qrStatus?: any
    checked?: boolean
}

export interface ManageUserTableProps {
    contactNumber: string
    id?: string
    name: string
    role: string
    createdDate: string
    lastAccessDate: string
    isActive: boolean
    email: string
    userId: string
    firstName: string
    lastName: string
    account: Account
    checked?: boolean

}
export interface ManageRoleTableProps {
    id?: string
    name?: string
    checked?: boolean
    roleId: string
    description?: string
    roleDescription?: string
    permissions: any
    roleName: string
    status: boolean
    basicRoleName?: string
}
export interface ManagePositionTableProps {
    id?: string
    name?: string
    checked?: boolean
    positionId: number
    positionName: string
    organizationName: string
    positionStatus: any
    primarySkills: any
    receivedDate: any
    status: boolean
    positionGivenDate?: any
    projectName?: any
    currentOpenings?: any
    cVsInProcess?: any
}
export interface ManageCandidateTableProps {
    id?: string
    name?: string
    fetchData: Function
    checked?: boolean
    candidateId: number
    candidateName: string
    candidateStatus: string
    city: string
    lastScreenedForOrganization: string
    positionName: string
    recruiterName: string
}
export interface ManageWikiTableProps {
    id?: string
    name?: string
    checked?: boolean
    wikiId: string
    title: string
    category: string
    createdBy: string
    createdOn: any
    status: boolean
}
export interface ManageOrganizationTableProps {
    id?: string
    name?: string
    checked?: boolean
    organizationId?: any
    spocName?: any
    organizationName?: string
    verticalName?: any
    positionClosedInLast12Month?: number
    positionOpen?: any
    cityName?: string
    skillName?: any
    proficiencyLevel?: any
    followUpCall?: any
    location?: any
    cities?: any
    priority?: any
    teamSize?: number
    status?: boolean
    convertToClientStatus?: any
}
export interface ManageReportTableProps {
    candidateName?: any
    organizationName?: any
    positionName?: any
    dateOfJoining?: any
    recruiterName?: any
    clientHr?: any
    dateOfInvoice?: any
    amountDue?: number
    paymentTerms?: any
}
export interface UserTableSuperAdminProps {
    id?: string
    userName: string
    role: string
    company: string
    requestDate: string
    email: string
    userId: string
    checked?: boolean
}
export interface UserTableRequestListSuperAdminProps {
    id?: string
    userId?: string
    accountId?: string
    company: string;
    userName: string;
    email: string;
    role: string;
    requestDate: Date;
    phone: string;
    country: string;
    street: string;
    city: string;
    state: string;
    zipCode: number;
    rejectReason: string;
    approvedDate: Date;
    rejectedDate: Date;
    checked?: boolean
}
export interface ShippingLinesListTableProps {
    photo_url: string
    id: string
    company_name: string
    headquarters: any
    total_no_of_ships: Number
    status: boolean
    checked?: boolean
}
export interface CHAListTableProps {
    user_id: string
    cha_agency_name: string
    full_name: string
    created: string
    email: string
    pan__no: string
    registration_no: string
    status: boolean
    checked?: boolean
}
export enum Change {
    increase = 'increase',
    decrease = 'decrease',
}
export enum Order {
    asc = 'asc',
    desc = 'desc'
}
export interface ButtonTextMethod {
    btnMethod: Function
    btnText: string
}
export interface CHAAddDetailsProps {
    first_name?: string
    last_name?: string
    email?: string
    phone_no?: string
    cha_agency_name?: string
    registration_no?: string
    pan_based_cha_no?: string
    address1?: string
    address2?: string
    country_id?: number
    state_id?: number
    city_id?: number
    zipcode?: string
    status?: boolean
    title?: string
    subHead?: string
    leftBtn?: ButtonTextMethod
    rightBtn: ButtonTextMethod
}
export interface ShippersRequestListTableProps {
    userId: string
    name: string
    requestDate: string
    emailId: string
    company: string
    department_name: string
    department_id?: number
    countryId?: number
    country: string
    checked?: boolean
}
export interface PlaceProps {
    id: number
    shortname?: string
    name: string
    phonecode?: string
}

export interface EditProfileProps extends UserProfileProps {
    firstName?: string
    lastName?: string
    designation?: string
    phone_no?: string
    mobile?: string
    location?: string
    company?: string
    website?: string
    currentPassword?: string
    newPassword?: string
    confirmPassword?: string
    person_name?: string
    agency_name?: string
    email_id?: string
    registration_no?: string
    pan_no?: string
    address_1?: string
    address_2?: string
    zip_code?: string
    country?: Object
    state?: Object
    city?: Object
    status?: boolean
    title?: string
    subHead?: string
    leftBtn: ButtonTextMethod
    rightBtn: ButtonTextMethod
    role?: string
}
export interface ShipmentInformationProps {
    id?: string
    booking_no: string
    lading_no: string
    status: string
    origin: string
    destination: string
    vessel_etd: string
    created_by: string
    role: string
    request_status: any
}

export interface FullDetailsProps {
    booking_no: string
    lading_no: string
    origin: string
    destination: string
    vessel_voyage: string
    equipment_details: string
    vessel_etd: string
    carriage_condition: string
    commodity_description: string
    hs_code: string
    vgm_cut_off_time: string
    documentation_cut_off_time: string
    cargo_cut_off_time: string
    created_by: string
    role: string
    carriers_reference_no: string
    consignee_shipment_reference_no: string
    contact_no: string
    shippers_identifying_no: string
}

export interface FilterDataProps {
    department: any
    location: any
    status: any
}

export interface DepartmentProps {
    department_id: number
    department_name: string
}

export interface RoleProps {
    roleId: string
    name: string
}

export interface AlertsProps {
    alertId: number
    emailStatus: boolean
    phoneStatus: boolean
}

export interface UserProfileProps {
    address1?: string
    address2?: string
    cityId?: number
    cityName?: string
    companyName?: string
    countryId?: number
    countryName?: string
    designation?: string
    email?: string
    firstName?: string
    id?: string
    image_data?: string
    lastName?: string
    phoneNo?: string
    stateId?: number
    stateName?: string
    zipcode?: number
    registrationNo?: string
    location?: string
    departmentId?: string
    department?: string
    website?: string
    path?: string
    uploadPhotoAPI?: Function
    role?: string
}
export interface AvatarEditProps {
    saveImage: Function;
    onClose: Function;
}
export interface AvatarEditForwardMethod {
    showModal: () => any;
    savePhoto: (value: any) => any;
}

export interface ReferenceFilterForwardMethod {
    open: (imageValue: any, editMode: boolean) => void;
}
export type ProfilePhotoForward = ReferenceFilterForwardMethod

export interface ProfilePhotoProps {
    uploadPhotoAPI: Function;
    updateProfilePhoto: Function;
    profileData: any
}


export enum Role {
    SuperAdmin_Admin = 'SuperAdmin_Admin',
    AccountAdmin = 'AccountAdmin',
    SuperAdmin = 'SuperAdmin',
    User = 'User',
    Admin = 'Admin',
    Manager = 'Manager',
    BD = 'BD',
    InternalHR = 'InternalHR',
    SPOC = 'SPOC',
    RPO = 'RPO',
    Accounts = 'Accounts',
    Recruiter = 'Recruiter',
    QC = 'QC',
    AgencyRCR = 'AgencyRCR',
    Interviewer = 'Interviewer',
    ClientManager = 'ClientManager',
    ClientHR = 'ClientHR',
    VP = 'VP',
    TeamLead = 'TeamLead',
    SuperAdmin_BD = 'SuperAdmin_BD'
}