import axios from 'axios'
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '_store/actions/loader'
import { RootState } from '_store/reducer/rootReducer'
import store from '_store/store'
import { baseURL } from './urls'
const config = axios.get(baseURL)
const api = axios.create({
    baseURL,
})
const unauthorizedAPI = axios.create({
    baseURL,
})
const handleLogout = () => {
    const currentRoute: any = localStorage.getItem('currentRoute');
    localStorage.clear();
    localStorage.setItem('currentRoute', currentRoute);
    Cookies.remove('token')
    Cookies.remove('userId')
    Cookies.remove('role')
    Cookies.remove('primaryRole')
    Cookies.remove('positionTracking')
    window.location.href = '/login'
}

const apiArray = ["/it/skills", "soft/skills", "/position/sourcingPoint/status", "position/log", "manager/qc/clientexecutive", "/email/check/", "/verify/organizationName/", "/data/duplicate", "/notification/count"]

api.interceptors.request.use(
    function (config) { 
        let a = store.getState();
        let count = a.filterData_loader.count       
        store.dispatch(setLoader({ type: "count", data: count + 1 }));
        if(config.url && apiArray.find(ele => config.url?.includes(ele))){

        } else {
            document.body.classList.add('loading-indicator');
        }
        const user = localStorage.getItem('user')
        const token = `${Cookies.get('token')}`
        let isExpired = false
        if (user && token !== 'undefined' && token !== null && token !== undefined) {
            const decodedToken = jwt.decode(token, { complete: true })
            if (decodedToken?.payload?.exp < (new Date().getTime())) {
                isExpired = false
                config.headers.Authorization = `Bearer ${token}`
            } else {
                isExpired = true
            }

            if (isExpired) {
                handleLogout()
            }
        }
        return config
    },
)
api.interceptors.response.use(
    function (response) {
        let a = store.getState();
        let count = a.filterData_loader.count
        store.dispatch(setLoader({ type: "count", data: count - 1 }));
        let b = store.getState();
        let newCount = b.filterData_loader.count
        if(newCount === 0){
            document.body.classList.remove('loading-indicator');
            return response;
        } else{
            return response;
        }                  
    },
    function (error) {
        let a = store.getState();
        let count = a.filterData_loader.count
        store.dispatch(setLoader({ type: "count", data: count - 1 }));
        let b = store.getState();
        let newCount = b.filterData_loader.count
        if(newCount === 0){
            document.body.classList.remove('loading-indicator');
        }
        if (!error.response || error.response.status === 401) {
            handleLogout()
        } else {
            return Promise.reject(error)
        }
    }
)
unauthorizedAPI.interceptors.request.use(
    function (config) {
        document.body.classList.add('loading-indicator');
        const token = `${Cookies.get('token')}`
        const isExpired = false
        if (token !== 'undefined' && token !== null && token !== undefined) {
            const decodedToken = jwt.decode(token, { complete: true })
            if (decodedToken?.payload?.exp < (new Date().getTime())) {
                config.headers.Authorization = `Bearer ${token}`
            } else {
                handleLogout()
            }
        }
        return config
    }
)
unauthorizedAPI.interceptors.response.use(
    function (response) {
        document.body.classList.remove('loading-indicator');
        return response;
    },
    function (error) {
        document.body.classList.remove('loading-indicator');
        return Promise.reject(error)
    }
)

const getApi = async (apiName: string) => {
    try {
        return await api
            .get(`${apiName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export { unauthorizedAPI, config, getApi }
export default api