import { REMOVE_INTERVIEW_TO_BE_SCHEDULED_STATE, SET_ALL_INTERVIEW_TO_BE_SCHEDULED_STATE, SET_INTERVIEW_TO_BE_SCHEDULED_STATE } from '_store/constants/filterData_InterviewsToBeScheduled';
import Action from '../../types/action';

const initialState = {
  statusFrom: null,
  statusTo: null,
}
const filterData_InterviewsToBeScheduled = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_INTERVIEW_TO_BE_SCHEDULED_STATE:
      localStorage.setItem('interviewTobeScheduledFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      case SET_ALL_INTERVIEW_TO_BE_SCHEDULED_STATE:
      localStorage.setItem('interviewTobeScheduledFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
    case REMOVE_INTERVIEW_TO_BE_SCHEDULED_STATE:
      localStorage.removeItem('interviewTobeScheduledFilter');
      return initialState;
    default:
      return state
  }
}
export default filterData_InterviewsToBeScheduled