import { REMOVE_ACCOUNTS_MIS_REPORT_STATE, SET_ACCOUNTS_MIS_REPORT_STATE, SET_ALL_ACCOUNTS_MIS_REPORT_STATE } from '_store/constants/filterData_AccountsMisReport';
import Action from '../../types/action';

const initialState = {
  paymentStatus: { item : 'Pending' },
  candidateName: null,
  organization: [],
  pfyPosition: [],
  joinedAfter: null,
  joinedBefore: null,
  invoiceDateFrom: null,
  invoiceDateTo: null,
  receivedBefore: null,
  receivedAfter: null
}
const filterData_AccountsMisReport = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ACCOUNTS_MIS_REPORT_STATE:
      localStorage.setItem('MISReportFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
    case SET_ALL_ACCOUNTS_MIS_REPORT_STATE:
      localStorage.setItem('MISReportFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
    case REMOVE_ACCOUNTS_MIS_REPORT_STATE:
      localStorage.removeItem('MISReportFilter');
      return initialState;
      break;
    default:
      return state
  }
}
export default filterData_AccountsMisReport