import { ALL_PENDING_INTERVIEW_FEEDBACK_STATE, REMOVE_PENDING_INTERVIEW_FEEDBACK_STATE, SET_PENDING_INTERVIEW_FEEDBACK_STATE } from '_store/constants/filterData_PendingInterviewFeedback';
import Action from '../../types/action';

const initialState = {
  receivedBefore: null,
  receivedAfter: null
}
const filterData_PendingInterviewFeedback = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_PENDING_INTERVIEW_FEEDBACK_STATE:
      localStorage.setItem( 'pendingInterviewFeedback', JSON.stringify({ ...state, [action.payload.type]: action.payload.data, }) );
      return { ...state, [action.payload.type]: action.payload.data };
    case ALL_PENDING_INTERVIEW_FEEDBACK_STATE:
      localStorage.setItem('pendingInterviewFeedback', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
    case REMOVE_PENDING_INTERVIEW_FEEDBACK_STATE:
      localStorage.setItem('pendingInterviewFeedback',JSON.stringify(initialState));
      return initialState;
    default:
      return state;
  }
}
export default filterData_PendingInterviewFeedback