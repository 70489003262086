import api from './api'

const primaryRole = localStorage.getItem('primaryRole') || '';


export const apiPostCall = async (url: string, params: any) => {
    const primaryRole = localStorage.getItem('primaryRole') || '';
    const payload = {
        LoggedInUserRoleName: primaryRole,
        ...params
    }
    return await api.post(`${url}`, payload)
    .then(response => {
        return { status: response.status, body: response.data }
    }).catch(err => {
        return { status: err.response.status, body: err.response.data }
    })
}