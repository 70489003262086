export const interviewStagesList = [
  { id: 1, item: 'Written Test' },
  { id: 2, item: 'L1' },
  { id: 3, item: 'L2' },
  { id: 4, item: 'M1' },
  { id: 5, item: 'M2' },
  { id: 6, item: 'HR' },
]
export const priorityList = [
  { id: 1, item: 'A' },
  { id: 2, item: 'B' },
  { id: 3, item: 'C' },
  { id: 4, item: 'A1' },
  { id: 5, item: 'A2' },
  { id: 6, item: 'A3' },
]
export const yearList = [
  { id: 1, item: '2022' },
  { id: 2, item: '2021' },
  { id: 3, item: '2020' },
  { id: 4, item: '2019' },
  { id: 5, item: '2018' },
  { id: 6, item: '2017' },
  { id: 7, item: '2016' },
  { id: 8, item: '2015' },
  { id: 9, item: '2014' },
  { id: 10, item: '2013' },
  { id: 11, item: '2012' },
]
export const monthList = [
  { id: 1, item: 'January' },
  { id: 2, item: 'February' },
  { id: 3, item: 'March' },
  { id: 4, item: 'April' },
  { id: 5, item: 'May' },
  { id: 6, item: 'June' },
  { id: 7, item: 'July' },
  { id: 8, item: 'August' },
  { id: 9, item: 'September' },
  { id: 10, item: 'October' },
  { id: 11, item: 'November' },
  { id: 12, item: 'December' }
]
export const statusList = [
  { id: 1, item: 'Open-Sourcing Required' },
  { id: 2, item: 'Open-Sourcing not required' }
]
export const OverviewReportCounInitialState = {
  organization: [],
  year: { item: new Date().getFullYear() },
  month: null,
}
export const ActionPopUpInitialState = {
  loggedInUserId: null,
  candidateId: null,
  positionId: null,
  organizationId: null,
  previousInterviewId: null,
  candidateStatusId: { item: '' },
  comments: '',
}
export const isCandidateHappyList = [
  { id: 1, item: 'Yes' },
  { id: 2, item: 'No' },
];
export const ReasonForCancellationByClientList = [
  { id: 36, item: 'Position on Hold' },
  { id: 18, item: 'Position Filled' },
  { id: 19, item: 'Position Cancelled' },
  { id: 18, item: 'Any Other' },
];
export const ReasonForCancellationByCandidateList = [
  { id: 1, item: 'Candidate Not Interested' },
  { id: 2, item: 'Candidate Got Another offer' },
  { id: 3, item: 'Any Other' },
];