import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer/rootReducer';
import rootSaga from "./saga/";

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware]

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(middleware),
  devTools: process.env.NODE_ENV !== 'production'
});

// then run the saga
sagaMiddleware.run(rootSaga)

export default store;
