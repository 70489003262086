import styles from './index.module.scss';
import CreateAccountComponent from './createAccountComponent';
import InviteUserComponent from './inviteUserComponent';
import { Modal } from 'react-bootstrap';
import ModalCard from './modalCard';
import SuccessComponent from './successComponent';
import CreateOrEditIDPComponent from './CreateOrEditIDPComponent';
import LogoutComponent from './LogoutComponent';
import DeleteComponent from './DeleteComponent';
import AddUserModal from './addUserModal';
import DeleteUser from './DeleteUser';
import RegistrationFeedback from './RegistrationFeedback';
import ForgotPasswordFeedbackComponent from './ForgotPasswordFeedbackComponent';

const ModalComponent = props => {
  const successModal = () => {
    return (
      <ModalCard
        hide={props.onHideModal}
        show={props.show}
        delete={props.deleteProfile}
        deleteuser={props.deleteuser}
        component={
          <SuccessComponent
            name='test'
            hide={props.onHideModal}
            successMessage={props.successMessage}
          />
        }
      />
    );
  };

  const formModal = () => {
    return (
      <ModalCard
        hide={props.onHideModal}
        component={
          props.modalInfo.modalIdentity === 'createAccount' ? (
            <CreateAccountComponent
              accountChange={props.modalInfo.apiCall}
              resetForm={props.resetForm}
            />
          ) : props.modalInfo.modalIdentity === 'AddUser' || props.modalInfo.modalIdentity === 'editUser' ? (
            <AddUserModal
              modalIdentity={props.modalInfo.modalIdentity}
              accountChange={props.modalInfo.apiCall}
              editObj={props.modalInfo.editObj}
              companyName={props.companyName}
              resetForm={props.resetForm}
            />
          ) : props.modalInfo.modalIdentity === 'DeleteUser' ? (
            <DeleteUser
              hide={props.onHideModal}
              deleteuser={props.deleteuser}
              deleteObj={props.modalInfo.deleteObj}
            />
          ) : props.modalInfo.modalIdentity === 'inviteUser' ? (
            <InviteUserComponent
              accountChange={props.modalInfo.apiCall}
              companyName={props.companyName}
              resetForm={props.resetForm}
            />
          ) : props.modalInfo.modalIdentity === 'createIDP' || props.modalInfo.modalIdentity === 'editIDP' ? (
            <CreateOrEditIDPComponent
              modalIdentity={props.modalInfo.modalIdentity}
              accountChange={props.modalInfo.apiCall}
              editObj={props.modalInfo.editObj}
              companyName={props.companyName}
              resetForm={props.resetForm}
            />
          ) : props.modalInfo.modalIdentity === 'Logout' ? (
            <LogoutComponent
              hide={props.onHideModal}
            />
          ) : props.modalInfo.modalIdentity === 'Delete' ? (
            <DeleteComponent
              hide={props.onHideModal}
              delete={props.deleteProfile}
            />
          ) :  props.modalInfo.modalIdentity === 'RegistrationFeedback' ? (
            <RegistrationFeedback
             hide={props.onHideModal}
            />
          ) : props.modalInfo.modalIdentity === 'ForgotPasswordFeedback' ? (
            <ForgotPasswordFeedbackComponent
              hide={props.onHideModal}
              emaildata={props.modalInfo.emailValue}

            />
          ) : null
        }
      />
    );
  };

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      contentClassName={styles.modalMain}
    >
      {props.success ? successModal() : formModal()}
    </Modal>
  );
};

export default ModalComponent;
