import moment from 'moment';
export const convertUTCToLocalTime = (dateString) => {
    const date = new Date(dateString);
    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    const localTime = new Date(milliseconds);
    return localTime.toLocaleString()
};
export const convertUTCToLocalTimeWithFormat = (dateString, format) => {
    const date = convertUTCToLocalTime(dateString);
    return moment(date).format(format);
};