
import {
  REMOVE_POSITION_STATE,
  SET_POSITION_STATE,
  SET_ALL_POSITION_FILTERS
} from '_store/constants/filterData_position';
import Action from '../../types/action';
interface IInitialState {
  organizations: Array<object>;
  positionName: Array<object>;
  clientHr: Array<object>;
  currntOpenings: Array<object>;
  primarySkills: Array<object>;
  receivedBefore: null,
  positionstatus: Array<object>;
  seniorityLevel: Array<object>;
  priority: Array<object>;
  backendSpoc: Array<object>;
  projectName: Array<object>;
  frontendSpoc: Array<object>;
  clientBu: Array<object>;
  receivedAfter: null,
  function: Array<object>;
  industry: Array<object>;
  vertical: Array<object>;
  minExp: any;
  maxExp: any;
  minCtc: any;
  maxCtc: any;
  jobCity: Array<object>;
  positionType: Array<object>;
}
export const filterPositionArrayData = {
  organizations: [],
  positionName: [],
  clientHr: [],
  currntOpenings: [],
  primarySkills: [],
  positionstatus: [],
  seniorityLevel: [],
  priority: [],
  backendSpoc: [],
  projectName: [],
  frontendSpoc: [],
  clientBu: [],
  function: [],
  industry: [],
  vertical: [],
  jobCity: [],
  positionType: [],
  bu: [],
  positionId: []
}
export const filterPositionNumberData = {
  minExp: null,
  maxExp: null,
  minCtc: null,
  maxCtc: null,
}
export const filterPositionNullData = {
  receivedBefore: null,
  receivedAfter: null,
  positionGivenBefore: null,
  positionGivenAfter: null
}
export const filterPositionOtherFieldData = {
  ...filterPositionNullData,
  ...filterPositionNumberData
}
export const filterPositionNumber = {
}
export const filterPosition: IInitialState = {
  ...filterPositionArrayData,
  ...filterPositionOtherFieldData
}
  
const filterData_position = (state = filterPosition, action: Action) => {
  switch (action.type) {
    case SET_POSITION_STATE:
      localStorage.setItem('positionFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data };
      break;
    case SET_ALL_POSITION_FILTERS:
      localStorage.setItem('positionFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
    case REMOVE_POSITION_STATE:
      localStorage.setItem('positionFilter', JSON.stringify(filterPosition));
      return filterPosition;
      break;
    default:
      return state;
      break;
  }
};
export default filterData_position;
