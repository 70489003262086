import {
  REMOVE_JOINED_CANDIDATES_STATE,
  SET_ALL_JOINED_CANDIDATES_STATE,
  SET_JOINED_CANDIDATES_STATE,
} from '_store/constants/filterData_JoinedCandidates';
import Action from '../../types/action';

const initialState = {
  dojFrom: null,
  dojTo: null,
};
const filterData_Joined_Candidates = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_JOINED_CANDIDATES_STATE:
      localStorage.setItem('joinedCandidateFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data };
      break;
      case SET_ALL_JOINED_CANDIDATES_STATE:
        localStorage.setItem('joinedCandidateFilter', JSON.stringify({ ...state, ...action.payload }));
        return { ...state, ...action.payload };
        break;
    case REMOVE_JOINED_CANDIDATES_STATE:
      localStorage.removeItem('joinedCandidateFilter');
      return initialState;
      break;
    default:
      return state;
  }
};
export default filterData_Joined_Candidates;
