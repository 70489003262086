import {
  REMOVE_UPCOMING_JOINEES_STATE,
  SET_ALL_UPCOMING_JOINEES_STATE,
  SET_UPCOMING_JOINEES_STATE,
} from '_store/constants/filterData_UpcomingJoinees';
import Action from '../../types/action';

const initialState = {
  dateOfJoiningFrom: null,
  dateOfJoiningTo: null,
  scheduledDateFrom: null,
  scheduledDateTo: null,
  offeredFrom: null,
  offeredTo: null,
  resignedFrom: null,
  resignedTo: null,
  selectedFrom: null,
  selectedTo: null,
  organization: null,
  position: null,
  recruiter: null,
  dojFrom: null,
  dojTo: null,
};
const filterData_UpcomingJoinees = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_UPCOMING_JOINEES_STATE:
      localStorage.setItem( 'upcomingJoineesFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }) );
      return { ...state, [action.payload.type]: action.payload.data };
    case SET_ALL_UPCOMING_JOINEES_STATE:
      localStorage.setItem( 'upcomingJoineesFilter', JSON.stringify({ ...state, ...action.payload }) );
      return { ...state, ...action.payload };
      break;
    case REMOVE_UPCOMING_JOINEES_STATE:
      localStorage.removeItem('upcomingJoineesFilter');
      return initialState;
    default:
      return state;
  }
};
export default filterData_UpcomingJoinees;
