import {
  REMOVE_INTERVIEW_SCHEDULED_STATE,
  SET_ALL_INTSCHEDULE_FILTERS,
  SET_INTERVIEW_SCHEDULED_STATE,
} from '_store/constants/filterData_InterviewScheduled';
import Action from '../../types/action';

const initialState = {
  dateFrom: null,
  dateTo: null,
  timeFrom: null,
  timeTo: null,
  organizations: [],
  positions: [],
  backendSpocs: [],
  recruiters: [],
  interviewStatus: [],
  contactNumber: null,
  recruiterVerticals: [],
  interviewStages: [],
  modeOfInterviews: [],
  receivedBefore: null,
  receivedAfter: null,
};
const filterData_InterviewScheduled = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case SET_INTERVIEW_SCHEDULED_STATE:
      localStorage.setItem('interviewScheduleFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data };
    case SET_ALL_INTSCHEDULE_FILTERS:
      localStorage.setItem('interviewScheduleFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
    case REMOVE_INTERVIEW_SCHEDULED_STATE:
      localStorage.removeItem( 'interviewScheduleFilter');
      return initialState;
    default:
      return state;
  }
};
export default filterData_InterviewScheduled;
