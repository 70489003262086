import { dropdownInitialValue } from 'containers/system_admin/LeadReports/MonthlyLeadReport/filterTypes';
import { REMOVE_MONTHLY_LEAD_REPORT_STATE, SET_MONTHLY_LEAD_REPORT_STATE } from '_store/constants/filterData_MonthlyLeadReport';
import Action from '../../types/action';

const initialState = {
    recruiter: [],
    year: { item: '2023' },
    month: null,
}
const filterData_MonthlyLeadReport = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_MONTHLY_LEAD_REPORT_STATE:
      localStorage.setItem('monthlyLeadReportFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
    case REMOVE_MONTHLY_LEAD_REPORT_STATE:
      localStorage.setItem('monthlyLeadReportFilter', JSON.stringify(initialState));
      return initialState;
    default:
      return state
  }
}
export default filterData_MonthlyLeadReport