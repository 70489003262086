import {
  FormHelperText, TextField
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import {
  allSpecializationAPI,
  candidateCSVAPI,
  candidateCSVAPIparser
} from '_store/apis/accountSuperAdmin';
import { candidateEmailCheck } from '_store/apis/candidate';
import { candidateCSV, candidateCSVWithParser } from '_store/apis/urls';
import { candidateaddsingleAPI } from '_store/apis/userManagementAPI';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '_store/constants';
import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { dynamicSort } from 'utilities/dynamicSort';
import MultiSelectField from 'widgets/MultiSelectField';
import * as Yup from 'yup';
import {
  emailYup,
  firstNameVal,
  lastNameVal
} from '../../../../../../utilities/yupObjects';
import FileInput from '../../../../../../widgets/FileInput';
import NormalTextField from '../../../../../../widgets/NormalTextField';
import PrimaryButton from '../../../../../../widgets/PrimaryButton';
import SelectField from '../../../../../../widgets/SelectField';
import ParserConfirmation from '../ParserConfermation';
import styles from './index.module.scss';
const AddSinglePosition = (props: any) => {
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 50,
    stringify: (option: any) => option.name
  });
  const filterOptionsCurrentCity = createFilterOptions({
    matchFrom: 'any',
    limit: 50,
    stringify: (option: any) => option.name
  });
  const { show, handleClose, editMode, updateTable } = props;
  const primaryRole = localStorage.getItem('primaryRole') || '';
  const dispatch = useDispatch();
  const masterData = JSON.parse(localStorage.getItem('masterData')!);
  const listofIndustry = masterData?.industries.map((e) => ({
    item: e.name,
    id: e.id,
  }));
  const listofCourse = masterData?.courses.map((e) => ({
    item: e.name,
    id: e.id,
  }));
  const [listOfSpecialization, setListOfSpecialization] = useState<any>([]);
  let [selectedIndustry, setSelectedIndustry] = useState<any>([]);
  const [listofSkills, setListofSkills] = useState<any>([]);
  const [parserPopup, setParserPopup] = useState<boolean>(false);
  let [parserConfirm, setParserConfirm] = useState<boolean>(false);
  const [emailVal, setEmailVal] = useState('');
  const [emailVal1, setEmailVal1] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [candidateId, setCandidateId] = useState<any>();
  const countries = masterData?.countries.map((e) => ({
    item: `${e.name} (${e.dialingCode})`,
    id: e.id,
  }));
  const history = useHistory();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const localStorageUserDetails = localStorage.getItem('user') || null;
  const userDetails = JSON.parse(localStorageUserDetails!);

  const [switchStatus, setSwitchStatus] = useState<boolean>(true);
  let [uploadFile, setUploadFile] = useState<any>({});
  let [fileName, setFileName] = useState<any>([]);

  const getMultipleDropDown = (arr, idArr) => {
    const temp: any = [];
    arr?.forEach((ele) => {
      idArr?.forEach((ele1) => {
        if (ele.id === ele1) {
          temp.push({ item: ele.item, id: ele.id });
        }
      });
    });
    return temp;
  };
  const addSingleCandidateSchema = Yup.object().shape({
    firstName: firstNameVal,
    lastName: lastNameVal,
    gender: Yup.string().required('Gender is required'),
    cv: Yup.string().required('CV is required'),
    emailId: emailYup,
    resumeSourceFolder: Yup.string()
      .max(500, 'Only 500 Characters Allowed')
      .nullable(),
    contactNumber: Yup.string()
      .required('Contact number is required')
      .matches(phoneRegExp, 'Contact Number is not valid')
      .min(10, 'Min 10 Digit Required')
      .max(10, 'Only 10 Digit Allowed')
      .nullable(),

    emergencyNumber: Yup.string().matches(phoneRegExp, 'Emergency Number is not valid')
      .min(10, 'Min 10 Digit Required')
      .max(10, 'Only 10 Digit Allowed')
      .test('contactNumber',
        'Emergency Number and Conatct Number should not be same',
        function (value: any) {
          if (formik.values.contactNumber && formik.values.contactNumber === formik.values.emergencyNumber) {
            return false;
          }
          return true;
        }

      ).nullable(),

    // emergencyNumber:Yup.string()
    // .matches(phoneRegExp, 'Emergency Number is not valid')
    // .min(10, 'Min 10 Digit Required')
    // .max(10, 'Only 10 Digit Allowed')
    // .nullable(),
    parserSkillList: Yup.array().min(1, 'Key skills are Required'),
    source: Yup.string().required('Source is required'),
    contactCode: Yup.object().shape({
      item: Yup.string().required('Contact Code is required'),
    }),
    currentCityId: Yup.object()
      .shape({
        name: Yup.string().required('City is required'),
      })
      .typeError('City is required'),
  });

  const fetchSkills = async () => {
    setListofSkills(
      masterData?.skills.map((e) => ({ item: e.name, id: e.id }))
    );
  };



  const resetData = () => {
    setSwitchStatus(true);
    formik.resetForm();
    setSelectedIndustry([]);
  };
  const formik = useFormik({
    initialValues: {
      status: true,
      firstName: '',
      lastName: '',
      gender: '',
      emailId: '',
      currentCityName: "",
      contactCode: { item: '', name: '' },
      contactNumber: '',
      emergencyNumber: '',
      emergencyCode: { item: '', name: '' },
      source: '',
      functionId: { id: '', name: '' },
      currentCityId: { id: '', name: '' },
      specializationList: [],
      parserSkillList: [],
      resumeSourceFolder: '',
      courseNameList: [],
      cv: ''
    },
    validationSchema: addSingleCandidateSchema,
    onSubmit: () => submitData(),
  });

  const fileSelectedHandler = async (file) => {
    if (file) {
      setParserPopup(true);
      uploadFile = file;
      formik.setFieldValue('cv', file.name, false);
      formik.setFieldError('cv', '')
      setUploadFile(uploadFile);
    }
  };
  const contactCode = (list, id) => {
    return list?.filter((e) => {
      return e.item.includes(id);
    });
  };

  const [isParse, setIsParse] = useState(false)
  const [parseData, setParseData] = useState<any>([]);
  const parseFile = async () => {
    setIsParse(true)
    const formData = new FormData();
    formData.append('CV', uploadFile);
    try {
      if (candidateCSVAPIparser) {
        const { status, body } = await candidateCSVAPIparser(formData);
        if (status === 200) {
          formik.setFieldValue('firstName', body.firstName, false);
          formik.setFieldValue('lastName', body.lastName, false);
          formik.setFieldValue('emailId', body.emailId, false);
          formik.setFieldValue('contactNumber', body.contactNumber, false);
          formik.setFieldValue('emergencyNumber', body.emergencyNumber, false);
          const contactCodes = contactCode(countries, body?.contactCode);
          formik.setFieldValue('contactCode', contactCodes[0], false);

          const emergencyCodes = contactCode(countries, body?.emergencyCode);
          formik.setFieldValue('emergencyCode', emergencyCodes[0], false);

          body?.gender && formik.setFieldValue('gender', body?.gender);
          if (body?.currentCityId) {
            const getcity = masterData?.cities.filter((e) => {
              return e.id === body?.currentCityId;
            });
            formik.setFieldValue('currentCityId', getcity[0], false);
          }
          if (body?.functionId) {
            const getFunction = masterData?.cities.filter((e) => {
              return e.id === body?.functionId;
            });
            formik.setFieldValue('functionId', getFunction[0], false);
          }
          if (body?.source) {
            const getSource = masterData?.cities.filter((e) => {
              return e.id === body?.source;
            });
            formik.setFieldValue('source', getSource[0], false);
          }

          const getSkillList = getMultipleDropDown(
            listofSkills,
            body?.parserSkillList
          );
          formik.setFieldValue('parserSkillList', getSkillList, false);

          const getIndustryList = getMultipleDropDown(
            listofIndustry,
            body?.industryList
          );
          formik.setFieldValue('industryList', getIndustryList, false);
          const getCourseNameList = getMultipleDropDown(
            listofCourse,
            body?.courseNameList
          );
          formik.setFieldValue('courseNameList', getCourseNameList, false);

          const getSpecialization = getMultipleDropDown(
            listofIndustry,
            body?.specializationList
          );
          formik.setFieldValue('specializationList', getSpecialization, false);
          setParseData(body);
          dispatch({
            type: SUCCESS_MESSAGE,
            payload:
              body?.detail === undefined
                ? 'Successfully data parsed'
                : body?.detail,
          });
        } else if (status > 399 && status < 500) {
          dispatch({
            type: ERROR_MESSAGE,
            payload:
              body?.detail === undefined ? 'Failed to upload' : body?.detail,
          });
        } else {
          dispatch({
            type: ERROR_MESSAGE,
            payload:
              body?.detail === undefined ? 'Failed to connect' : body?.detail,
          });
        }
      }
    } catch (err) {
      dispatch({ type: ERROR_MESSAGE, payload: 'Failed to connect' });
    }
  };


  const fileSelected = async (candidatePyaload) => {
    if (uploadFile) {
      const formData = new FormData();
      formData.append('CandidateId', candidatePyaload.candidateId);
      formData.append('Cv', uploadFile);

      const file = '.' + uploadFile.name?.split('.').pop().toLowerCase();
      fileName = uploadFile.name.substring(0, uploadFile.name.lastIndexOf('.'));

      formData.append('fileExtension', file);
      formData.append('type', uploadFile.type);
      formData.append('fileName', fileName);
      try {
        if (candidateCSVAPI) {
          const url = isParse ? candidateCSVWithParser : candidateCSV
          const { status, body } = await candidateCSVAPI(url, formData);
          if (status === 200) {
            dispatch({
              type: SUCCESS_MESSAGE,
              payload:
                body?.message === undefined
                  ? 'Successfully updated'
                  : 'Candidate Created Successfully.',
            });
            history.push(`/user/${userDetails.userId}/${primaryRole}/candidates/edit/${candidatePyaload.candidateId}`);
            return;
          } else if (status > 399 && status < 500) {
            dispatch({
              type: ERROR_MESSAGE,
              payload:
                body?.message ? body?.message : 'Failed to upload',
            });
          } else {
            dispatch({
              type: ERROR_MESSAGE,
              payload:
                body?.message === undefined
                  ? 'Failed to connect'
                  : body?.message,
            });
          }
        }
      } catch (err) {
        dispatch({ type: ERROR_MESSAGE, payload: 'Failed to connect' });
      }
    }
  };
  const submitData = async () => {
    try {
      const values = formik.values;
      const payload = {
        ...parseData,
        ...values,
        createdBy: 0,
        createdOn: '2022-04-26T12:37:14.816Z',
        deleted: true,
        contactCode: formik.values.contactCode.item.toString(),
        status: true,
        emergencyCode: formik.values.emergencyCode
          ? formik.values.emergencyCode.item.toString()
          : null,
        loggedInUserRoleName: primaryRole,
        currentCityId: formik.values.currentCityId?.id || null,
        functionId: formik.values.functionId
          ? formik.values.functionId?.id
          : null,
        industryList:
          selectedIndustry.length > 0
            ? selectedIndustry.map((e) => e.id)
            : null,
        courseNameList:
          formik.values.courseNameList.length > 0
            ? formik.values.courseNameList?.map((e: any) => e.id)
            : null,
        specializationList:
          formik.values.specializationList.length > 0
            ? formik.values.specializationList?.map((e: any) => e.id)
            : null,
        parserSkillList:
          formik.values.parserSkillList.length > 0
            ? formik.values.parserSkillList?.map((e: any) => e.id)
            : null,
      };
      const { status, body } = await candidateaddsingleAPI(payload);
      if (status === 200 || status === 201) {
        await fileSelected(body);
        updateTable();
        handleModalClose();
        const cId = body.candidateId;
        // history.push(
        //   `/user/${userDetails.userId}/${primaryRole}/candidates/edit/${cId}`
        // );

        dispatch({
          type: SUCCESS_MESSAGE,
          payload: body?.message
            ? body?.message
            : 'Candidate Created Successfully.',
        });
      } else if (status > 399 && status < 500) {
        dispatch({
          type: ERROR_MESSAGE,
          payload: body?.message ? body?.message : 'Failed to add Candidate ',
        });
      } else {
        dispatch({
          type: ERROR_MESSAGE,
          payload:
            body?.message === undefined ? 'Failed to connect' : body?.message,
        });
      }
    } catch (err) {
      dispatch({ type: ERROR_MESSAGE, payload: 'Failed to connect' });
    }
  };
  const handleModalClose = () => {
    handleClose();
    resetData();
    formik.resetForm();
  };
  const handleChangeCity = (value) => {
    formik.setFieldValue('currentCityId', value.id);
  };
  const handleCourseChange = async (event, value) => {
    formik.setFieldValue('courseNameList', value);
    console.log(event);
    try {
      const payload = value?.map((e: any) => e?.id);
      const data = await allSpecializationAPI(payload);
      const { status, body }: any = data;
      if (status === 200) {
        const data = body;
        setListOfSpecialization(data.map((e) => ({ item: e.name, id: e.id })));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangeIndustry = (event, value) => {
    selectedIndustry = value;
    setSelectedIndustry(selectedIndustry);
    console.log(event);
  };
  const handleChangeSpecialization = (event, value) => {
    formik.setFieldValue('specializationList', value);
    console.log(event);
  };

  const handleChangeParserSkillList = (event, value) => {
    formik.setFieldValue('parserSkillList', value);
    console.log(event);
  };


  const validateEmailId = async (email) => {
    try {
      const payload = {
        emailId: email,
      };
      const { status, body } = await candidateEmailCheck(payload);
      setEmailVal(body?.message);
      if (body?.name !== null) {
        setEmailVal1('Email is already in use ');
        setCandidateName(body?.name);
        setCandidateId(body?.id);
      } else {
        setEmailVal1('');
      }
    } catch (err) {
      return { status: 500, body: 'Failed to connect' };
    }
  };
  const getCities = () => {
    const masterDataCities = masterData?.cities?.sort(dynamicSort('name'));
    const cities = masterDataCities?.map(ct => {
      const state = masterData?.states?.find(st => st.id === ct.stateId);
      return {
        ...ct,
        state
      };
    });
    return cities
  }

  useEffect(() => {
    if (parserConfirm) {
      parseFile();
      parserConfirm = false;
      setParserConfirm(parserConfirm);
    }
  }, [parserConfirm, setParserConfirm]);

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    setSwitchStatus(switchStatus);
  }, [switchStatus]);

  return (
    <>
      <ParserConfirmation
        show={parserPopup}
        handleClose={() => setParserPopup(false)}
        parserConfirm={parserConfirm}
        setParserConfirm={setParserConfirm}
      />
      <Modal
        backdrop="static"
        centered
        size="xl"
        show={show}
        onHide={handleModalClose}
        className={`${styles.modalCustomcard} ${styles.addUserPopupWrapper1}`}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <h4 className={styles.commonTitle}>
            {editMode ? 'Edit' : 'Add '} &nbsp;&nbsp; {' Candidate'}
          </h4>
        </Modal.Header>
        <Modal.Body>
          {candidateId !== null ? (<>
            <span className="text-danger" style={{ fontSize: '13px', marginLeft: '12px' }}>
              {emailVal !== "Email Didn't Exist." && emailVal} &nbsp;

              <Link to={`/user/${userDetails.userId}/${primaryRole}/candidates/edit/${candidateId}`}>
                {emailVal !== "Email Didn't Exist." && candidateName}
              </Link>

            </span>
          </>) : (<></>)}
          <FormikProvider value={formik}>

            <form>
              <div className="row">
                <div className="col-md-4 col-12">
                  <FileInput
                    label_text="uploadfile"
                    placeholder="Upload CV"
                    fileSelectedHandler={fileSelectedHandler}
                    fileTypes={['pdf', 'doc', 'docx', 'PDF', 'rtf']}
                    custRef={undefined}
                    name={''}
                  />
                  <span className="text-danger pl-3" style={{ fontSize: '12px' }}>
                    {formik.errors.cv ? formik.errors.cv : ''}
                  </span>
                </div>
                <div className={'col-md-4 col-12'}>
                  <NormalTextField
                    error={formik.errors.firstName}
                    touched={formik.touched.firstName}
                    placeholder="First Name"
                    label_text={'First Name'}
                    required={true}
                    name="firstName"
                    type="text"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className={styles.noramalF}
                  />
                </div>
                <div className={'col-md-4 col-12'}>
                  <NormalTextField
                    error={formik.errors.lastName}
                    touched={formik.touched.lastName}
                    placeholder="Last Name"
                    label_text={'Last Name'}
                    required={true}
                    name="lastName"
                    type="text"
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className={styles.noramalF}
                  />
                </div>
              </div>
              <div className={styles.customDiv}></div>
              <div className="row">
                <div className="col-md-2 col-6">
                  <SelectField
                    className={styles.filter_select_field}
                    placeholder={'Gender'}
                    attributeName="item"
                    required={true}
                    name="Gender"
                    options={[{ item: 'Male' }, { item: 'Female' }]}
                    value={{ item: formik.values.gender }}
                    methodHandleChange={(value) =>
                      formik.setFieldValue('gender', value.item)
                    }
                    error={formik.touched.gender ? formik.errors.gender : ''}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <NormalTextField
                    label_text={'Email '}
                    required={true}
                    placeholder={'Email'}
                    name="emailId"
                    type="text"
                    error={formik.errors.emailId || emailVal1}
                    touched={formik.touched.emailId}
                    handleChange={formik.handleChange}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      validateEmailId(e.target.value);
                    }}
                    value={formik.values.emailId}
                    className={styles.noramalF}
                  />
                </div>
                <div className="col-md-2 col-6">
                  <SelectField
                    className={styles.filter_select_field}
                    placeholder="Code"
                    attributeName="item"
                    name="Code"
                    required={true}
                    options={countries}
                    value={formik.values.contactCode}
                    methodHandleChange={(value) => {
                      formik.setFieldValue('contactCode', value);
                    }}
                    error={
                      formik.touched.contactCode
                        ? formik.errors.contactCode?.item
                        : ''
                    }
                  />
                </div>
                <div className="col-md-4 col-12">
                  <NormalTextField
                    label_text={'Contact No. '}
                    required={true}
                    placeholder={'Contact No.'}
                    name="contactNumber"
                    type="number"
                    disabled={formik.values.contactCode?.item ? false : true}
                    error={formik.errors.contactNumber}
                    touched={formik.touched.contactNumber}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.contactNumber}
                    className={styles.noramalF}
                  />
                </div>
              </div>
              <div className={styles.customDiv}></div>
              <div className="row">
                <div className="col-md-4 col-12">
                  <SelectField
                    className={styles.filter_select_field}
                    placeholder="Emergency Code"
                    attributeName="item"
                    name="Code"
                    options={countries}
                    value={formik.values.emergencyCode}
                    methodHandleChange={(value) => {
                      formik.setFieldValue('emergencyCode', value);
                    }}
                    error={
                      formik.touched.emergencyCode
                        ? formik.errors.emergencyCode?.item
                        : ''
                    }
                  />
                </div>
                <div className="col-md-4 col-12">
                  <NormalTextField
                    label_text={'Emergency No. '}
                    placeholder={'Emergency No.'}
                    name="emergencyNumber"
                    type="number"
                    disabled={formik.values.emergencyCode?.item ? false : true}
                    error={formik.errors.emergencyNumber}
                    touched={formik.touched.emergencyNumber}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.emergencyNumber}
                    className={styles.noramalF}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <Autocomplete
                    className={
                      formik.touched.currentCityId &&
                        formik.errors.currentCityId
                        ? 'error'
                        : ''
                    }
                    options={getCities()}
                    getOptionLabel={(option: any) => option.name}
                    value={formik.values.currentCityId}
                    filterOptions={filterOptionsCurrentCity}
                    groupBy={(option) => option.state?.code}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('currentCityId', newValue);
                      formik.setFieldValue('currentCityName', newValue?.name);
                      console.log(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={`${styles.noramalF} ${styles.textFiled}`}
                        {...params}
                        label={[
                          'Current City ',
                          <span className={styles.astrik}>*</span>,
                        ]}
                        variant="outlined"
                        margin="normal"
                      />
                    )}
                  />
                  <FormHelperText className="autoCompleteError">
                    {formik.touched.currentCityId
                      ? formik.errors.currentCityId?.name ||
                      formik.errors.currentCityId
                      : ''}
                  </FormHelperText>
                </div>
              </div>
              <div className={styles.customDiv}></div>
              <div className="row">
                <div
                  className={`col-md-4 col-12 ${selectedIndustry.length !== 0 ? styles.filterResult : ''
                    }`}
                >
                  <MultiSelectField
                    className={`${styles.noramalF} ${styles.MainP}`}
                    name={'industryList'}
                    label_text={'Industry'}
                    placeholder={
                      selectedIndustry.length !== 0
                        ? '+' + selectedIndustry.length
                        : ''
                    }
                    attributeName={'item'}
                    options={listofIndustry}
                    value={selectedIndustry}
                    methodHandleChange={(event, value) =>
                      handleChangeIndustry(event, value)
                    }
                  />
                </div>
                <div className="col-md-4 col-12">
                  <Autocomplete
                    options={masterData?.functions?.sort(dynamicSort('name'))}
                    getOptionLabel={(option: any) => option.name}
                    value={formik.values.functionId}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      formik.setFieldValue('functionId', newValue);
                      console.log(event);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={`${styles.noramalF} ${styles.textFiled}`}
                        {...params}
                        label={'Funtion'}
                        variant="outlined"
                        margin="normal"
                      />
                    )}
                  />
                </div>
                <div className={'col-md-4 col-12'}>
                  <SelectField
                    className={styles.filter_select_field}
                    placeholder="Source"
                    required={true}
                    attributeName="item"
                    name="source"
                    options={[
                      { item: 'Naukri' },
                      { item: 'Monster' },
                      { item: 'Linkedin' },
                      { item: 'Linkedin + Portal' },
                      { item: 'Other Portal' },
                      { item: 'Referral' },
                      { item: 'Head Hunt' },
                      { item: 'Legacy Data' },
                      { item: 'Other Source' },
                      { item: 'Client Referral' },
                    ].sort(dynamicSort('item'))}
                    value={{ item: formik.values.source }}
                    methodHandleChange={(value) =>
                      formik.setFieldValue('source', value.item)
                    }
                    error={formik.touched.source ? formik.errors.source : ''}
                  />
                </div>
              </div>
              <div className={styles.customDiv}></div>
              <div className="row">
                <div
                  className={`col-md-4 col-12 ${formik.values.courseNameList.length !== 0
                    ? styles.filterResult
                    : ''
                    }`}
                >
                  <MultiSelectField
                    className={`${styles.noramalF} ${styles.MainP}`}
                    name={'Course'}
                    label_text={'Course Name '}
                    placeholder={
                      formik.values.courseNameList.length !== 0
                        ? '+' + formik.values.courseNameList.length
                        : ''
                    }
                    attributeName={'item'}
                    options={listofCourse?.sort(dynamicSort('item'))}
                    error={formik.errors.courseNameList}
                    value={formik.values.courseNameList}
                    methodHandleChange={(event, value) =>
                      handleCourseChange(event, value)
                    }
                  />
                </div>
                <div
                  className={`col-md-4 col-12 ${formik.values.specializationList.length !== 0
                    ? styles.filterResult
                    : ''
                    }`}
                >
                  <MultiSelectField
                    className={`${styles.noramalF} ${styles.MainP}`}
                    name={'Specialization'}
                    label_text={'Specialization'}
                    placeholder={
                      formik.values.specializationList.length !== 0
                        ? '+' + formik.values.specializationList.length
                        : ''
                    }
                    attributeName={'item'}
                    options={listOfSpecialization?.sort(dynamicSort('item'))}
                    disabled={
                      formik.values.courseNameList.length !== 0 ? false : true
                    }
                    value={formik.values.specializationList}
                    error={formik.errors.specializationList}
                    methodHandleChange={(event, value) =>
                      handleChangeSpecialization(event, value)
                    }
                  />
                </div>

                <div className={'col-md-4 col-12'}>
                  <NormalTextField
                    className={styles.noramalF}
                    label_text={'Resume Source Folder'}
                    placeholder={'Resume Source Folder'}
                    name="resumeSourceFolder"
                    type="text"
                    error={formik.errors.resumeSourceFolder}
                    touched={formik.touched.resumeSourceFolder}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    value={formik.values.resumeSourceFolder}
                  ></NormalTextField>
                </div>
              </div>
              <div className={styles.customDiv}></div>
              <div className="row">
                <div className={'col-12'}>
                  <MultiSelectField
                    className={`${styles.noramalF} ${styles.MainP} w-100`}
                    required={true}
                    name={'parserSkillList'}
                    label_text={'Key Skills'}
                    placeholder={'Key Skills'}
                    attributeName={'item'}
                    options={listofSkills}
                    limitTags={formik.values.parserSkillList?.length}
                    touched={Boolean(formik.touched.parserSkillList)}
                    value={formik.values.parserSkillList}
                    error={formik.errors.parserSkillList}
                    methodHandleChange={(event, value) =>
                      handleChangeParserSkillList(event, value)
                    }
                  />
                </div>
              </div>
              <div className={styles.customDiv}></div>
              <div className="d-flex justify-content-end">
                <div
                  className={`${styles.modalPrimarymodal} ${styles.btnSave}`}
                >
                  <PrimaryButton
                    className={styles.Interbtn}
                    text={'Save & Procced'}
                    method={() => {
                      formik.handleSubmit();
                    }}
                  />
                </div>

                <div
                  className={`ml-3 ${styles.modalPrimarymodal} ${styles.btnSave}`}
                >
                  <PrimaryButton
                    className={styles.Interbtn}
                    text={'Cancel'}
                    method={() => {
                      handleModalClose();
                      resetData();
                    }}
                  />
                </div>
              </div>
            </form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddSinglePosition;
