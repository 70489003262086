import moment from "moment";
import { Role } from "../../types";

export const rowsPerPageOptions = [
  { item: "5" },
  { item: "10" },
  { item: "30" },
  { item: "50" },
  { item: "100" },
];
export const candidateList = [
  { id: 1, item: "Agency" },
  { id: 2, item: "Client" },
  { id: 3, item: "Internal" },
  { id: 4, item: "Prospect" },
];

// export const positionVerticalList = [
//   { id: 1, item: "IT" },
//   { id: 3, item: "KPO" },
//   { id: 2, item: "Non-IT" },
//   { id: 4, item: "Others" },
// ];
// const masterData = JSON.parse(localStorage.getItem('masterData')!);
// export const positionVerticalList = masterData.verticals.map((e) => ({ item: e.name, id: e.id }))

export const screenStatusList = [
  { id: 1, item: "Screen Select", key: "SCREEN-SELECT" },
  { id: 2, item: "Screen Hold", key: "SCREEN-HOLD" },
  { id: 3, item: "Send to QC", key: "SEND-TO-QC" },
  { id: 4, item: "Incomplete Call", key: "CALL-INCOMPLETE" },
  { id: 5, item: "Screen Reject", key: "SCREEN-REJECT" },
  { id: 6, item: "All", key: "All" },
];

export const daysOverDueList = [
  { id: 1, item: "1 to 7" },
  { id: 2, item: "8 to 15" },
  { id: 3, item: "16 to 30" },
  { id: 4, item: "30 and above" },
];

export const durationFromAndToMonthList = [
  { id: 1, item: "Jan" },
  { id: 2, item: "Feb" },
  { id: 3, item: "Mar" },
  { id: 4, item: "Apr" },
  { id: 5, item: "May" },
  { id: 6, item: "Jun" },
  { id: 7, item: "Jul" },
  { id: 8, item: "Aug" },
  { id: 9, item: "Sep" },
  { id: 10, item: "Oct" },
  { id: 11, item: "Nov" },
  { id: 12, item: "Dec" },
];

export const durationQuaterlyMonthList = [
  { id: 1, item: "Q1" },
  { id: 2, item: "Q2" },
  { id: 3, item: "Q3" },
  { id: 4, item: "Q4" },
];

export const durationHalfYearlyMonthList = [
  { id: 1, item: "H1" },
  { id: 2, item: "H2" },
];

export const viewModeList = [
  { id: 1, item: "Monthly" },
  { id: 2, item: "Quarterly" },
  { id: 3, item: "Half yearly" },
];

export const DurationFromAndToYearList = [
  { id: 1, item: "2012" },
  { id: 2, item: "2013" },
  { id: 3, item: "2014" },
  { id: 4, item: "2015" },
  { id: 5, item: "2016" },
  { id: 6, item: "2017" },
  { id: 7, item: "2018" },
  { id: 8, item: "2019" },
  { id: 9, item: "2020" },
  { id: 10, item: "2021" },
];

// export const verticalList = [
//   { name: "IT", id: 1 },
//   { name: "KPO", id: 2 },
//   { name: "Non-IT", id: 3 },
//   { name: "Others", id: 4 },
// ];
// export const verticalList = masterData.verticals.map((e) => ({ name: e.name, id: e.id }))

export const QCStatusList = [
  { id: 1, item: "QC Passed" },
  { id: 2, item: "QC Reject" },
  { id: 3, item: "QC Pending" },
];

export const positionStatusList = [
  { id: 1, item: "Open-Sourcing Required" },
  { id: 2, item: "Open-Sourcing not required" },
];

export const PositionPriorityList = [
  { id: 1, item: "A" },
  { id: 2, item: "B" },
  { id: 3, item: "C" },
  { id: 4, item: "A1" },
  { id: 5, item: "A2" },
  { id: 6, item: "A3" },
];
export enum ReportTypes {
  InvoicePaymentPending = "Invoice Payment Pending",
  ScreeningStatus = "Screening Status",
  RevenuePipeline = "Revenue Pipeline",
  RevenueAchieved = "Revenue Achieved",
  QCProductivity = "QC Productivity",
  TeamPerformance = "Team Performance",
  TodaysAssignedPositions = "Today's Assigned Positions",
  PendingCVFeedback = "Pending CV Feedback",
  UpcomingMeetings = "Upcoming Meetings",
  UpcomingFolloups = "Upcoming Followups",
  Productivity = "Productivity",
  RecruiterWorkAllocation = "Recruiter Work Allocation",
}
export const getSingleSelectedOption = (filed, list, fieldName) => {
  if (filed && list && list.length) {
    return list.find((ele) => filed == ele[fieldName]);
  }
  return null;
};
export const getSelectedOption = (filed, list, fieldName) => {
  if (filed && list && list.length) {
    return list.filter((ele) => filed.some((item) => item === ele[fieldName]));
  }
  return [];
};

export function generateYearsBetween(startYear = 2012) {
  const endDate = new Date().getFullYear();
  const years: any = [];

  for (let i = startYear; i <= endDate; i++) {
    years.push({ item: startYear });
    startYear++;
  }
  return years.reverse();
}

export function generateYearsScreningSheet(startYear = 1960) {
  const endDate = new Date().getFullYear();
  const years: any = [];

  for (let i = startYear; i <= endDate; i++) {
    years.push({ item: startYear });
    startYear++;
  }
  return years.reverse();
}

export interface IReportsList {
  count: number;
  items: any;
}
const requestInitiaPayload = {
  pageSize: 10,
  pageNo: 0,
  sortType: "asc",
  isSelected: false,
};
const initialPayloadInvoicePending = {
  ...requestInitiaPayload,
  apiName: "api/invoice/payment/pending",
  sortBy: "candidateName",
  filters: {},
  headers: [
    { label: "Candidate Name", key: "candidateName" },
    { label: "Organization Name", key: "organizationName" },
    { label: "Position Name", key: "positionName" },
    { label: "Date Of Joining", key: "dateOfJoining" },
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "Client Hr", key: "clientHr" },
    { label: "Date Of Invoice", key: "dateOfInvoice" },
    { label: "Amount Due", key: "amountDue" },
    { label: "Payment Terms", key: "paymentTerms" },
    { label: "Verticals", key: "verticals" },
  ],
};
const currentDate: any = new Date();
const fromPreviousDate = new Date(new Date().setDate(new Date().getDate() - 3));
export const initialPayloadScreeningStatus = {
  ...requestInitiaPayload,
  apiName: "api/screening/status",
  sortBy: "qcName",
  filters: {
    screenStatus: "Incomplete Call",
    fromDate: fromPreviousDate,
    toDate: currentDate,
  },
  headers: [
    { label: "Candidate Name", key: "candidateName" },
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "Position Name", key: "positionName" },
    { label: "Organization Name", key: "organizationName" },
    { label: "Date Called", key: "dateCalled" },
    { label: "Screen Status", key: "screenStatus" },
    { label: "CurrentStatus", key: "currentStatus" },
    { label: "QC Name", key: "qcName" },
    { label: "Comments", key: "comments" },
    { label: "Verticals", key: "verticals" },
  ],
};

const d = new Date();
const month = d.getMonth() + 1;
const currentYear = moment(new Date()).format("YYYY");
const quarter: any = month/3 + 1;

const initialPayloadRevenuePipeline = {
  ...requestInitiaPayload,
  apiName: "api/revenue/pipeline",
  sortBy: "candidateName",
  filters: {
    viewMode: "Quarterly",
    durationFromMonth: `0${parseInt(quarter)}`,
    durationFromYear: parseInt(currentYear),
    durationToMonth: `0${parseInt(quarter)}`,
    durationToYear: parseInt(currentYear),
  },
  headers: [
    { label: "Candidate Name", key: "candidateName" },
    { label: "Organization Name", key: "organizationName" },
    { label: "Position Name", key: "positionName" },
    { label: "Position Vertical", key: "positionVertical" },
    { label: "Expected Date Of Joining", key: "expectedDateOfJoining" },
    { label: "CTC Offered", key: "ctcOffered" },
    { label: "Revenue Projected", key: "revenueProjected" },
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "Recruiter Vertical", key: "recruiterVertical" },
  ],
};

const initialPayloadRevenueAchienved = {
  ...requestInitiaPayload,
  apiName: "api/revenue/achieved",
  sortBy: "candidateName",
  filters: {
    viewMode: "Quarterly",
    durationFromMonth: `0${parseInt(quarter)}`,
    durationFromYear: parseInt(currentYear),
    durationToMonth: `0${parseInt(quarter)}`,
    durationToYear: parseInt(currentYear),
  },
  headers: [
    { label: "Candidate Name", key: "candidateName" },
    { label: "Organization Name", key: "organizationName" },
    { label: "Position Name", key: "positionName" },
    { label: "Position Vertical", key: "positionVertical" },
    { label: "Expected Date Of Joining", key: "expectedDateOfJoining" },
    { label: "CTC Offered", key: "ctcOffered" },
    { label: "Revenue Projected", key: "revenueProjected" },
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "Recruiter Vertical", key: "recruiterVertical" },
  ],
};

const initialPayloadQCProductivity = {
  ...requestInitiaPayload,
  apiName: "api/qc/productivity",
  sortBy: "qcName",
  filters: {},
  headers: [
    { label: "QC Name", key: "qcName" },
    { label: "Send To Qc", key: "sendToQc" },
    { label: "QC Passed", key: "qcPassed" },
    { label: "QC Rejected", key: "qcRejected" },
    { label: "Send To Client", key: "sendToClient" },
    { label: "CV Shortlisted", key: "cvShortlisted" },
    { label: "CV Rejected", key: "cvRejected" },
    { label: "Interview Scheduled", key: "interviewScheduled" },
    { label: "Interview Rejected", key: "interviewRejected" },
    {
      label: "Final Selected",
      key: "finalSelected",
    },
    { label: "Offer Sent", key: "offerSent" },
    { label: "Joined", key: "joined" },
    { label: "Backout", key: "backout" },
  ],
};
const initialPayloadTeamPerformance = {
  ...requestInitiaPayload,
  apiName: "api/team/performance",
  sortBy: "recruiterName",
  filters: {
    viewMode: "Monthly",
    durationFromMonth: month <= 9 ? `0${month}` : `${month}`,
    durationFromYear: parseInt(currentYear),
    durationToMonth: month <= 9 ? `0${month}` : `${month}`,
    durationToYear: parseInt(currentYear),
  },
  headers: [
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "Target Duration", key: "targetDuration" },
    { label: "Target Revenue", key: "targetRevenue" },
    { label: "Revenue Achieved", key: "revenueAchieved" },
    { label: "Revenue In Pipeline", key: "revenueInPipeline" },
    { label: "Revenue In Remaining", key: "revenueInRemaining" },
    { label: "Target Vs Achieved %", key: "targetVsAchieved" },
    { label: "Target Vs Pipeline %", key: "targetVsPipeline" },
    { label: "Target Vs Remaining %", key: "targetVsRemaining" },
    { label: "Target Vs Revenuelost %", key: "targetVsRevenuelost" },
  ],
};

const initialPayloadTodaysAssignedPosition = {
  ...requestInitiaPayload,
  apiName: "api/todays/assigned/position",
  sortBy: "recruiter",
  filters: { durationFrom: new Date(), durationTo: new Date() },
  headers: [
    { label: "Recruiter", key: "recruiter" },
    { label: "Position", key: "position" },
    { label: "Assigned Date", key: "assignedDate" },
    { label: "QC", key: "qc" },
    { label: "Screened Count", key: "screenedCount" },
    { label: "QC Pending", key: "qcPending" },
    { label: "QC Passed", key: "qcPassed" },
    { label: "QC Rejected", key: "qcRejected" },
    { label: "Target", key: "target" },
    { label: "C Vs Achieved", key: "cVsAchieved" },
    { label: "Target Vs Achieved %", key: "targetVsAchieved" },
  ],
};

const initialPayloadPendingCVFeedback = {
  ...requestInitiaPayload,
  apiName: "api/pending/cv/feedback",
  sortBy: "PositionStatus",
  filters: {},
  headers: [
    { label: "Received Date", key: "receivedDate" },
    { label: "Days Elapsed Since Received", key: "daysElapsedSinceReceived" },
    { label: "Organization", key: "organization" },
    { label: "PFY Position", key: "pfyPosition" },
    { label: "Position Status", key: "positionStatus" },
    { label: "Total Openings", key: "totalOpenings" },
    { label: "Current Openings", key: "currentOpenings" },
    { label: "CVs Sent", key: "cVsSent" },
    { label: "Feedback Awaited", key: "feedbackAwaited" },
  ],
};

const initialPayloadUpcomingMeeting = {
  ...requestInitiaPayload,
  apiName: "api/upcoming/meetings",
  sortBy: "meetingDate",
  filters: {},
  headers: [
    { label: "Organization Name", key: "organizationName" },
    { label: "Contact Number", key: "contactNumber" },
    { label: "Verticals", key: "verticals" },
    { label: "Meeting Date", key: "meetingDate" },
  ],
};

const initialPayloadUpcomingFolloups = {
  ...requestInitiaPayload,
  apiName: "api/upcoming/followups",
  sortBy: "followUpDate",
  filters: {},
  headers: [
    { label: "Organization Name", key: "organizationName" },
    { label: "Contact Number", key: "contactNumber" },
    { label: "Verticals", key: "verticals" },
    { label: "FollowUp Date", key: "followUpDate" },
  ],
};
const date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();
const firstDay = new Date(y, m, 1);
const lastDay = new Date(y, m + 1, 0);
const fDate = moment(firstDay).format("YYYY-MM-DD");
const lDate = moment(lastDay).format("YYYY-MM-DD");
const initialPayloadProductivity = {
  ...requestInitiaPayload,
  apiName: "api/productivity",
  sortBy: "recruiterName",
  filters: {
    durationFrom: fDate,
    durationTo: lDate,
  },
  headers: [
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "CV Target", key: "cvTarget" },
    { label: "CV Submit", key: "cvSubmit" },
    { label: "First Interview Schedule", key: "firstInterviewSchedule" },
    { label: "Interviewer Appeared", key: "interviewerAppeared" },
    { label: "Offered", key: "offered" },
    { label: "Joined", key: "joined" },
    { label: "Not Joined", key: "notJoined" },
    { label: "Target To Submit %", key: "targetToSubmit" },
    {
      label: "Submitted To Interview Schedule %",
      key: "submittedToInterviewSchedule",
    },
    { label: "Scheduled To Appeared %", key: "scheduledToAppeared" },
    { label: "Appeared To Offered %", key: "appearedToOffered" },
    { label: "Offered To Joined %", key: "offeredToJoined" },
    { label: "Offered To Backout %", key: "offeredToBackout" },
  ],
};

const initialPayloadRecruiterWorkAllocation = {
  ...requestInitiaPayload,
  apiName: "api/recruiter/work/allocation",
  sortBy: "recruiterName",
  filters: {},
  headers: [
    { label: "Recruiter Name", key: "recruiterName" },
    { label: "Current Allocated Hours", key: "currentAllocatedHours" },
    { label: "Recruiter Vertical", key: "recruiterVertical" },
    { label: "Recruitment Competency", key: "recruitmentCompetency" },
  ],
};

export const getInitialStateOfReport = (selectedReport) => {
  switch (selectedReport.reportType) {
    case ReportTypes.InvoicePaymentPending:
      return initialPayloadInvoicePending;
      break;
    case ReportTypes.ScreeningStatus:
      return initialPayloadScreeningStatus;
      break;
    case ReportTypes.PendingCVFeedback:
      return initialPayloadPendingCVFeedback;
      break;
    case ReportTypes.Productivity:
      return initialPayloadProductivity;
      break;
    case ReportTypes.QCProductivity:
      return initialPayloadQCProductivity;
      break;
    case ReportTypes.RecruiterWorkAllocation:
      return initialPayloadRecruiterWorkAllocation;
      break;
    case ReportTypes.RevenueAchieved:
      return initialPayloadRevenueAchienved;
      break;
    case ReportTypes.RevenuePipeline:
      return initialPayloadRevenuePipeline;
      break;
    case ReportTypes.TeamPerformance:
      return initialPayloadTeamPerformance;
      break;
    case ReportTypes.TodaysAssignedPositions:
      return initialPayloadTodaysAssignedPosition;
      break;
    case ReportTypes.UpcomingFolloups:
      return initialPayloadUpcomingFolloups;
      break;
    case ReportTypes.UpcomingMeetings:
      return initialPayloadUpcomingMeeting;
      break;
    default:
      break;
  }
};
export interface IReportType {
  reportType: string;
  isSelected: boolean;
  filters: object;
  apiName: string;
  pageSize: number;
  pageNo: number;
  sortBy?: string;
  sortType: string;
}
const reportTypeCommonList: IReportType[] = [
  {
    reportType: ReportTypes.InvoicePaymentPending,
    ...initialPayloadInvoicePending,
  },
  {
    reportType: "Productivity",
    ...initialPayloadProductivity,
  },
  {
    reportType: "Revenue Pipeline",
    ...initialPayloadRevenuePipeline,
  },
  {
    reportType: "Revenue Achieved",
    ...initialPayloadRevenueAchienved,
  },
  {
    reportType: "Team Performance",
    ...initialPayloadTeamPerformance,
  },
  {
    reportType: "Screening Status",
    ...initialPayloadScreeningStatus,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
  {
    reportType: "Today's Assigned Positions",
    ...initialPayloadTodaysAssignedPosition,
  },
  {
    reportType: "Pending CV Feedback",
    ...initialPayloadPendingCVFeedback,
  },
  {
    reportType: "Recruiter Work Allocation",
    ...initialPayloadRecruiterWorkAllocation,
  },
  {
    reportType: "Upcoming Meetings",
    ...initialPayloadUpcomingMeeting,
  },
  {
    reportType: "Upcoming Followups",
    ...initialPayloadUpcomingFolloups,
  },
];
const reportTypeList1: IReportType[] = [
  {
    reportType: "Upcoming Meetings",
    ...initialPayloadUpcomingMeeting,
  },
  {
    reportType: "Upcoming Followups",
    ...initialPayloadUpcomingFolloups,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
];
const reportTypeList2: IReportType[] = [
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
];
const reportTypeList3: IReportType[] = [
  {
    reportType: "Invoice Payment Pending",
    ...initialPayloadInvoicePending,
  },
  {
    reportType: "Productivity",
    ...initialPayloadProductivity,
  },
  {
    reportType: "Revenue Pipeline",
    ...initialPayloadRevenuePipeline,
  },
  {
    reportType: "Revenue Achieved",
    ...initialPayloadRevenueAchienved,
  },
  {
    reportType: "Team Performance",
    ...initialPayloadTeamPerformance,
  },
  {
    reportType: "Screening Status",
    ...initialPayloadScreeningStatus,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
  {
    reportType: "Today's Assigned Positions",
    ...initialPayloadTodaysAssignedPosition,
  },
];
const reportTypeList4: IReportType[] = [
  {
    reportType: "Pending CV Feedback",
    ...initialPayloadPendingCVFeedback,
  },
  {
    reportType: "Productivity",
    ...initialPayloadProductivity,
  },
  {
    reportType: "Recruiter Work Allocation",
    ...initialPayloadRecruiterWorkAllocation,
  },
  {
    reportType: "Revenue Pipeline",
    ...initialPayloadRevenuePipeline,
  },
  {
    reportType: "Revenue Achieved",
    ...initialPayloadRevenueAchienved,
  },
  {
    reportType: "Team Performance",
    ...initialPayloadTeamPerformance,
  },
  {
    reportType: "Today's Assigned Positions",
    ...initialPayloadTodaysAssignedPosition,
  },
  {
    reportType: "Screening Status",
    ...initialPayloadScreeningStatus,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
];
const reportTypeList5: IReportType[] = [
  {
    reportType: "Invoice Payment Pending",
    ...initialPayloadInvoicePending,
  },
  {
    reportType: "Pending CV Feedback",
    ...initialPayloadPendingCVFeedback,
  },
  {
    reportType: "Productivity",
    ...initialPayloadProductivity,
  },
  {
    reportType: "Recruiter Work Allocation",
    ...initialPayloadRecruiterWorkAllocation,
  },
  {
    reportType: "Revenue Pipeline",
    ...initialPayloadRevenuePipeline,
  },
  {
    reportType: "Revenue Achieved",
    ...initialPayloadRevenueAchienved,
  },
  {
    reportType: "Team Performance",
    ...initialPayloadTeamPerformance,
  },
  {
    reportType: "Today's Assigned Positions",
    ...initialPayloadTodaysAssignedPosition,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
];

const reportTypeList6: IReportType[] = [
  {
    reportType: "Pending CV Feedback",
    ...initialPayloadPendingCVFeedback,
  },
  {
    reportType: "Productivity",
    ...initialPayloadProductivity,
  },
  {
    reportType: "Revenue Pipeline",
    ...initialPayloadRevenuePipeline,
  },
  {
    reportType: "Revenue Achieved",
    ...initialPayloadRevenueAchienved,
  },
  {
    reportType: "Team Performance",
    ...initialPayloadTeamPerformance,
  },
  {
    reportType: "Today's Assigned Positions",
    ...initialPayloadTodaysAssignedPosition,
  },
  {
    reportType: "Screening Status",
    ...initialPayloadScreeningStatus,
  },
];

const reportTypeList7: IReportType[] = [
  {
    reportType: "Today's Assigned Positions",
    ...initialPayloadTodaysAssignedPosition,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
];

const reportTypeList8: IReportType[] = [
  {
    reportType: "Pending CV Feedback",
    ...initialPayloadPendingCVFeedback,
  },
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
];

const reportTypeList9: IReportType[] = [
  {
    reportType: "QC Productivity",
    ...initialPayloadQCProductivity,
  },
  {
    reportType: "Screening Status",
    ...initialPayloadScreeningStatus,
  },
  {
    reportType: "Productivity",
    ...initialPayloadProductivity,
  },
];

export const initialStateReportType: IReportType[] = reportTypeCommonList;

const reportTypeList = new Map<any, any>();
reportTypeList.set(Role.BD, reportTypeList1);
reportTypeList.set(Role.InternalHR, reportTypeList9);
reportTypeList.set(Role.Accounts, reportTypeList2);
reportTypeList.set(Role.RPO, reportTypeList2);
reportTypeList.set(Role.Admin, reportTypeList3);
reportTypeList.set(Role.SuperAdmin, reportTypeList3);
reportTypeList.set(Role.SPOC, reportTypeList5);
reportTypeList.set(Role.Recruiter, reportTypeList6);
reportTypeList.set(Role.Manager, reportTypeList4);
reportTypeList.set(Role.TeamLead, reportTypeList4);
reportTypeList.set(Role.VP, reportTypeList4);
reportTypeList.set(Role.QC, reportTypeList2);
reportTypeList.set(Role.AgencyRCR, reportTypeList7);
reportTypeList.set(Role.ClientHR, reportTypeList8);
reportTypeList.set("", reportTypeCommonList);
export { reportTypeList };
