export function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        const result = (a[property]?.toLowerCase().trim() < b[property]?.toLowerCase().trim()) ? -1 : (a[property]?.toLowerCase().trim() > b[property]?.toLowerCase().trim()) ? 1 : 0;
        return result * sortOrder;
    }
}