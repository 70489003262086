import { REMOVE_DAILY_LEAD_REPORT_STATE, SET_ALL_DAILY_LEAD_REPORT_STATE, SET_DAILY_LEAD_REPORT_STATE } from '_store/constants/filterData_DailyLeadReport';
import Action from '../../types/action';
const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const initialState = {
    organizations: [],
    recruiters: [],
    from: null ,
    to: null,
}
const filterData_DailyLeadReport = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_ALL_DAILY_LEAD_REPORT_STATE:
      localStorage.setItem('dailyLeadReport', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;

    case SET_DAILY_LEAD_REPORT_STATE:
      localStorage.setItem('dailyLeadReport', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
    case REMOVE_DAILY_LEAD_REPORT_STATE:
      localStorage.removeItem('dailyLeadReport');
      return initialState;
      break;
    default:
      return state
  }
}
export default filterData_DailyLeadReport