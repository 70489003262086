import {
  REMOVE_USERS_STATE,
  SET_USERS_STATE,
  SET_ALL_USERS_FILTERS
} from '_store/constants/filterData_users';
import Action from '../../types/action';
interface IProps {
  id: any;
  userType: any;
  userTypeData:  any;
  email: string;
  userName: string;
  listofRoles: any;
  roles: any;
  listofVerticals: any;
  verticals: any;
  listofLanguages: any;
  languages: any;
  listofIndustries: any;
  userIndustry: any;
  industry: any;
  listofFunctions: any;
  userFunction: any;
  listOfSkill: any;
  keySkills: any;
  proficiencyLevel: any;
  listOfDesignation: any;
  designations: any;
  listOfReportingTos: any;
  reportingTo: any;
  listofOrganizations: any;
  organizations: any;
  listOfCities: any;
  cities: any;
  listofprofLevels: any;
  seniorityLevel: any;
  currentOrganizations: any;
}
const initialState: IProps = {
  id: null,
  userType: { item: 'Internal' },
  userTypeData: { item: 'Client' },
  email: '',
  userName: '',
  listofRoles: [],
  roles: [],
  listofVerticals: [],
  verticals: [],
  listofLanguages: [],
  languages: [],
  listofIndustries: [],
  userIndustry: [],
  industry: [],
  listofFunctions: [],
  userFunction: [],
  listOfSkill: [],
  keySkills: [],
  proficiencyLevel: { item: '' },
  listOfDesignation: [],
  designations: [],
  listOfReportingTos: [],
  reportingTo: [],
  listofOrganizations: [],
  organizations: [],
  listOfCities: [],
  cities: [],
  listofprofLevels: { item: '' },
  seniorityLevel: { item: '' },
  currentOrganizations: [],
};
const filterData_users = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_USERS_STATE:
      localStorage.setItem('userFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data };
      break;
    case SET_ALL_USERS_FILTERS:
      localStorage.setItem('userFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
    case REMOVE_USERS_STATE:
      localStorage.setItem('userFilter', JSON.stringify(initialState));
      return initialState;
      break;
    default:
      return state;
      break;
  }
};
export default filterData_users;
