import api from './api'
import {
    accountapproveAccounts, accountsuperadmin, addapirole, addedituser, addorganization, addSearchRecord, addUserSuperadmin, advancedSearchRecord, apiaccount, apiaccountdetails, apiaccountdisapproveAccounts, apicandidatedelete, apicandidatelist, apidataduplicate,
    apidatamanagement, apieditcandidatedeedit, apiorganizationlist, apipositionfiltervalue, apipositionlist, apipositionorganizationWise, apipositionrecruiters, apiwiki, apiwikilist, candidateCSVparser, candidatescreenpositionList, clientHrOrganizationWise, clientHrPositionList, companydeletemultiple, companydeletesingle, deleteSavedSearchApi, getParticularSavedSearchApi, pfySpoclist, reportingtTo, reportinvoicepaymentpending,
    reportproductivity, reportqcproductivity, reportrevenueachieved, reportrevenuepipeline, reportscreeningstatus, reportteamperformance, rolelist, savedSearchApi, specialization, superadminuseraddmultiple
} from './urls'
export const getAccountSuperAdmin = async (value: any) => {
    try {
        return await api
            .post(`${accountsuperadmin}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const accountApproveAccounts = async (obj: Object) => {
    try {
        return await api
            .put(accountapproveAccounts, obj)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const accountDisApproveAccounts = async (obj: Object) => {
    try {
        return await api
            .put(apiaccountdisapproveAccounts, obj)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getApiAccount = async (value: any) => {
    try {
        return await api.get(`${apiaccount}/${value}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getApiAccountDetailsAPI = async (id) => {
    try {
        return await api
            .get(`${apiaccountdetails}/${id}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const postApiAccount = async (value: any) => {
    try {
        return await api.post(apiaccount, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const companyEditSingleAPI = async (payload) => {
    try {
        return await api.put(`${apiaccount}/${payload.accountId}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const getUserListSuperadmin = async (value: any) => {
    try {
        return await api
            .post(`${rolelist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getRoleListAPI = async (value: any) => {
    try {
        return await api
            .post(`${rolelist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getpositionlistAPI = async (value: any) => {
    try {
        return await api
            .post(`${apipositionlist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const companydeletesingleCompany = async (payload: any) => {
    try {
        return await api.delete(companydeletesingle, { data: payload })
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const companydeletemultipleCompany = async (value: any) => {
    try {
        return await api.delete(companydeletemultiple, { data: value })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const companylistfilterAPI = async (value: Object) => {
    try {
        return await api.post(apiaccount, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const useraddsingleAPI = async (user: Object) => {
    try {
        return await api.post(addUserSuperadmin, user)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const roleaddsingleAPI = async (user: Object) => {
    try {
        return await api.post(addapirole, user)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addwikiAPI = async (user: Object) => {
    try {
        return await api.post(apiwiki, user)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addEditUserAPI = async (user: Object) => {
    try {
        return await api.put(addedituser, user)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const allSpecializationAPI = async (user: number) => {
    try {
        return await api
            .get(`${specialization}/${user}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const companylistsearchAPI = async (value: any) => {
    try {
        return await api
            .post(`${accountsuperadmin}`, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const useraddmultipleAPI = async (users: Array<Object>) => {
    try {
        return await api.post(superadminuseraddmultiple, users)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getInvicePaymentPending = async (value: any) => {
    try {
        return await api
            .post(`${reportinvoicepaymentpending}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getAddorganizationAPI = async () => {
    try {
        return await api.get(`${addorganization}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getReportingToAPI = async () => {
    try {
        return await api.get(`${reportingtTo}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getCandidatescreenpositionListAPI = async (payload: any) => {
    try {
        return await api.get(`${candidatescreenpositionList}/${payload.loggedInUserRoleName}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getPositionRecruitersAPI = async () => {
    try {
        return await api.get(`${apipositionrecruiters}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionClientHrOrganizationWiseAPI = async (value: any) => {
    try {
        return await api
            .post(`${clientHrOrganizationWise}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getIProductivity = async (value: any) => {
    try {
        return await api
            .post(`${reportproductivity}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getRevenuePipeline = async (value: any) => {
    try {
        return await api
            .post(`${reportrevenuepipeline}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getRevenueAchieved = async (value: any) => {
    try {
        return await api
            .post(`${reportrevenueachieved}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export enum IViewMode {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    HalfYearly = 'Half yearly'
}
export const viewModeOption = Object.keys(IViewMode).map((key: any) =>
    IViewMode[key]).filter(value => typeof value === 'string') as string[];
export interface ITeamPerformancePost {
    pageSize: number
    pageNo: number
    sortBy: string
    sortType: string
    viewMode: IViewMode
    durationFromYear: any;
    durationFromMonth: any;
    durationToYear: any;
    durationToMonth: any;
    recruiter: any;
    organization: any;
    reportingTo: any;
    LoggedInUserRoleName?: string;
}
export const initialValueTeamPerformancePost: ITeamPerformancePost = {
    pageSize: 10,
    pageNo: 0,
    sortBy: 'recruiterName',
    sortType: 'asc',
    viewMode: IViewMode.Monthly,
    durationFromYear: null,
    durationFromMonth: null,
    durationToYear: null,
    durationToMonth: null,
    recruiter: null,
    organization: null,
    reportingTo: null
}
export const getTeamPerformance = async (value: ITeamPerformancePost) => {
    try {
        return await api
            .post(`${reportteamperformance}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getScreeningStatus = async (value: any) => {
    try {
        return await api
            .post(`${reportscreeningstatus}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getQCProductivity = async (value: any) => {
    try {
        return await api
            .post(`${reportqcproductivity}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getOrganizationListAPI = async (value: any) => {
    try {
        return await api
            .post(`${apiorganizationlist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getPfySpocListAPI = async () => {
    try {
        return await api
            .get(`${pfySpoclist}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionListAPI = async (value: any) => {
    try {
        return await api
            .post(`${apipositionlist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const clientHRPositionListAPI = async (value: any) => {
    try {
        return await api
            .post(`${clientHrPositionList}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionFiltervalueAPI = async (value: any) => {
    try {
        return await api
            .post(`${apipositionfiltervalue}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidateListAPI = async (value: any) => {
    try {
        return await api
            .post(`${apicandidatelist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidateDeleteAPI = async (payload: any) => {
    try {
        return await api.delete(`${apicandidatedelete}/${payload.id}/${payload.role}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const wikideletesingleAPI = async (payload: any) => {
    try {
        return await api.delete(`${apiwiki}/${payload.wikiId}/${payload.LogedInUserRoleName}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getCandidateEditAPI = async (payload: any) => {
    try {
        return await api.get(`${apieditcandidatedeedit}/${payload.id}/${payload.role}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionorganizationWiseAPI = async (value: any) => {
    try {
        return await api
            .post(`${apipositionorganizationWise}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getSavedSearchData = async (value: any) => {
    try {
        return await api
            .post(`${savedSearchApi}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addNewSearchRecord = async (value: any) => {
    try {
        return await api
            .post(`${addSearchRecord}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const newAdvancedSearchRecord = async (value: any) => {
    try {
        return await api
            .post(`${advancedSearchRecord}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getPerticularSavedSearchData = async (value: any) => {
    try {
        return await api.get(`${getParticularSavedSearchApi}/${value}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const deletePerticularSavedSearchData = async (value: any) => {
    try {
        return await api.delete(`${deleteSavedSearchApi}/${value}`)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const wikiListAPI = async (value: any) => {
    try {
        return await api
            .post(`${apiwikilist}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const duplicatedataAPI = async (value: any) => {
    try {
        return await api
            .post(`${apidataduplicate}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const dataManagementAPI = async (value: any) => {
    try {
        return await api
            .post(`${apidatamanagement}`, value)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const candidateCSVAPIparser = async (file: any) => {
    try {
        return await api.post(candidateCSVparser, file)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidateCSVAPI = async (Url,file: object) => {
    try {
        return await api
          .post(Url, file)
          .then((response) => {
            return { status: response.status, body: response.data };
          })
          .catch((err) => {
            return { status: err.response.status, body: err.response.data };
          });
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
