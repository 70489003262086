import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'widgets/PrimaryButton';
import { path } from '../../pageRoutes/routers';
import { remove } from '../../_store/actions/user';
import styles from './index.module.scss';

const ForgotPasswordFeedbackComponent = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const navigate = () => {
        history.push(path.Login);
    };

    const handleYes = () => {
        dispatch(remove(navigate));
        props.hide()
    }

    return (
        <div className={`${styles.invitemodal} ${styles.centerContentModal}`}>
            <h5 className={styles.commonTitle}>
                Check email for reset link
            </h5>
            <h6 className={styles.modalsubtitle}>An email has been sent to the <span className={`${styles.forgotpasswordemail}`}>{props.emaildata}</span> Check the inbox of the your email account, and click the reset link provided.

            </h6>
            <div className={`d-flex justify-content-center align-items-center ${styles.buttonModel}`}>
                <PrimaryButton
                    text={'Okay'}
                    method={() => handleYes()}
                />
            </div>
        </div>

    );
};

export default ForgotPasswordFeedbackComponent;