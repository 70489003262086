import { IReportType } from './../../containers/reports/constants';
import { initialStateReportType } from 'containers/reports/constants';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: initialStateReportType
};
export const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    handleSelectedReport: (state, action) => {
      const { reportType, request } = action.payload;
      let newState = state.data;
      newState = state.data.map((ele: IReportType) => {
        if (ele.reportType === reportType) {
          ele = { ...ele, ...request, isSelected: true }
        } else {
          ele.isSelected = false;
        }        
        return ele;
      });
      localStorage.setItem('reportFilter', JSON.stringify(newState));
      state.data = newState;
    },
    handleResetReport: (state) => {
      localStorage.setItem('reportFilter', JSON.stringify(initialStateReportType));
      state.data = initialStateReportType
    }
  },
})

export const { handleSelectedReport, handleResetReport } = reports.actions

export default reports.reducer;