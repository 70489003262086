import { v4 as uuidv4 } from 'uuid'
import Action from '../../types/action'
import { SET_BROADCASTMESSAGE, REMOVE_BROADCASTMESSAGE } from '_store/constants/broadcastMessage'
const initialState = [];
const broadcastMessage = (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_BROADCASTMESSAGE:
            return [...state, ...action.payload ];
        break;
        case REMOVE_BROADCASTMESSAGE:
            return initialState;
        break;  
      default:
        return state;
    }
};
export default broadcastMessage