import TextField from '@material-ui/core/TextField';
import { FormikProvider, useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'widgets/PrimaryButton';
import * as Yup from 'yup';
import { broadcastMessageAPI } from '_store/apis/broadCastMessage';
import { RootState } from '_store/reducer/rootReducer';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../_store/constants/index';
import styles from './index.module.scss';

const BoradcastMessageModal = (props: any) => {
  const { show, handleClose } = props;
  const dispatch = useDispatch();
  const users = localStorage.getItem('user') || null;
  const primaryRole = localStorage.getItem('primaryRole') || '';
  const submitData = async () => {
    try {
      const payload = {
        message: formik.values.message,
        loggedInUserRoleName: primaryRole,
      };
      const { status, body } = await broadcastMessageAPI(payload);
      if (status === 200 || status === 201) {
        handleModalClose();
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: body?.message
            ? body?.message
            : 'Successfully Sent BoradCast Message',
        });
      } else if (status > 399 && status < 500) {
        dispatch({
          type: ERROR_MESSAGE,
          payload: body?.message
            ? body?.message
            : 'Failed to Sent BoradCast Message',
        });
      } else {
        dispatch({
          type: ERROR_MESSAGE,
          payload:
            body?.message === undefined ? 'Failed to connect' : body?.message,
        });
      }
    } catch (err) {
      dispatch({ type: ERROR_MESSAGE, payload: 'Failed to connect' });
    }
  };


  const broadcastSchema = Yup.object().shape({
    message: Yup.string()
      .required('Broadcast message is required')
      .nullable(),
  });
  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: broadcastSchema,
    onSubmit: () => submitData(),
  });

  const handleModalClose = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <>

      <Modal
        centered
        show={show}
        onHide={handleModalClose}
        className={`${styles.modalCustomcard} ${styles.addUserPopupWrapper1}`}
      >
        <Modal.Header className={styles.modal_header} closeButton>
          <h4 className={styles.commonTitle}>Broadcast &nbsp;Message</h4>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <form>
              <>
                <form className={styles.settingS}>
                  <div className="row">
                    <div className={'col-12'}>
                      <label className={styles.message}>Message:</label>
                      <TextField
                        multiline
                        name="message"
                        type="text"
                        variant="outlined"
                        className={'w-100'}
                        rows={8}
                        onChange={formik.handleChange}
                        required={true}
                        value={formik.values.message}
                        helperText={formik.touched?.message ? formik.errors?.message : ''}
                        error={formik.touched?.message && Boolean(formik.errors?.message)}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="mt-4 d-flex">
                    <div className={`${styles.modalPrimarymodal}`}>
                      <PrimaryButton
                        className={`${styles.Interbtn} ${styles.header_add_button}`}
                        text={'Cancel'}
                        method={() => {
                          handleModalClose();
                        }}
                      />
                    </div>
                    <div className={`ml-2 ${styles.modalPrimarymodal}`}>
                      <PrimaryButton
                        text={'Send'}
                        className={`${styles.Interbtn} ${styles.header_add_button}`}
                        method={() => formik.handleSubmit()}
                      />
                    </div>
                  </div>
                </form>
              </>
            </form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BoradcastMessageModal;
