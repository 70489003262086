import { SET_SIGN_UP_DATA } from '../constants/index'
import { v4 as uuidv4 } from 'uuid'
import Action from '../../types/action'

const initialState = {
    signUpPageData: null
}

const SignUpData = (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_SIGN_UP_DATA:
            return { ...state,
                 id: uuidv4(),
                 signUpPageData: action.payload, 
                 type: SET_SIGN_UP_DATA 
                }
        default:
            return state
    }
}
export default SignUpData