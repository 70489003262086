
export const SET_ORG_STATE = 'SET_ORG_STATE'
export const REMOVE_ORG_STATE = 'REMOVE_ORG_STATE'
export const SET_ALL_ORG_FILTERS = 'SET_ALL_ORG_FILTERS'
export const SET_ORG_VERTICAL_LIST = 'SET_ORG_VERTICAL_LIST'
export const REMOVE_ORG_VERTICAL_LIST = 'REMOVE_ORG_VERTICAL_LIST'
export const SET_ORG_VERTICAL = 'SET_ORG_VERTICAL'
export const REMOVE_ORG_VERTICAL = 'REMOVE_ORG_VERTICAL'

export const SET_ORG_PFYSPOC_LIST = 'SET_ORG_PFYSPOC_LIST'
export const REMOVE_ORG_PFYSPOC_LIST = 'REMOVE_ORG_PFYSPOC_LIST'
export const SET_ORG_PFYSPOC = 'SET_ORG_PFYSPOC'
export const REMOVE_ORG_PFYSPOC = 'REMOVE_ORG_PFYSPOC'

export const SET_ORG_CITY_LIST = 'SET_ORG_CITY_LIST'
export const REMOVE_ORG_CITY_LIST = 'REMOVE_ORG_CITY_LIST'
export const SET_ORG_CITY = 'SET_ORG_CITY'
export const REMOVE_ORG_CITY = 'REMOVE_ORG_CITY'

export const SET_REQ_CLOSED_IN_LAST_LIST = 'SET_REQ_CLOSED_IN_LAST_LIST'
export const REMOVE_REQ_CLOSED_IN_LAST_LIST = 'REMOVE_REQ_CLOSED_IN_LAST_LIST'
export const SET_REQ_CLOSED_IN_LAST = 'SET_REQ_CLOSED_IN_LAST'
export const REMOVE_REQ_CLOSED_IN_LAST = 'REMOVE_REQ_CLOSED_IN_LAST'

export const SET_OPEN_POSITION = 'SET_OPEN_POSITION'
export const REMOVE_OPEN_POSITION = 'REMOVE_OPEN_POSITION'

export const SET_ORG_TYPE = 'SET_ORG_TYPE'
export const REMOVE_ORG_TYPE = 'REMOVE_ORG_TYPE'

export const SET_ORG_NAME = 'SET_ORG_NAME'
export const REMOVE_ORG_NAME = 'REMOVE_ORG_NAME'

export const SET_ORG_FOLLOWED_UP_AFTER = 'SET_ORG_FOLLOWED_UP_AFTER'
export const REMOVE_ORG_FOLLOWED_UP_AFTER = 'REMOVE_ORG_FOLLOWED_UP_AFTER'

export const SET_ORG_FOLLOWED_UP_BEFORE = 'SET_ORG_FOLLOWED_UP_BEFORE'
export const REMOVE_ORG_FOLLOWED_UP_BEFORE = 'REMOVE_ORG_FOLLOWED_UP_BEFORE'

export const SET_ORG_SKILL_LIST = 'SET_ORG_SKILL_LIST'
export const REMOVE_ORG_SKILL_LIST = 'REMOVE_ORG_SKILL_LIST'
export const SET_ORG_SKILL = 'SET_ORG_SKILL'
export const REMOVE_ORG_SKILL = 'REMOVE_ORG_SKILL'

export const SET_ORG_PROFICIENCY_LIST = 'SET_ORG_PROFICIENCY_LIST'
export const REMOVE_ORG_PROFICIENCY_LIST = 'REMOVE_ORG_PROFICIENCY_LIST'
export const SET_ORG_PROFICIENCY = 'SET_ORG_PROFICIENCY'
export const REMOVE_ORG_PROFICIENCY = 'REMOVE_ORG_PROFICIENCY'

export const SET_ORG_CV_UPLOADED_LIST_LIST = 'SET_ORG_CV_UPLOADED_LIST_LIST'
export const REMOVE_ORG_CV_UPLOADED_LIST_LIST = 'REMOVE_ORG_CV_UPLOADED_LIST_LIST'
export const SET_ORG_CV_UPLOADED_LIST = 'SET_ORG_CV_UPLOADED_LIST'
export const REMOVE_ORG_CV_UPLOADED_LIST = 'REMOVE_ORG_CV_UPLOADED_LIST'

export const SET_ORG_OPEN_POSITIONS_LIST = 'SET_ORG_OPEN_POSITIONS_LIST'
export const REMOVE_ORG_OPEN_POSITIONS_LIST = 'REMOVE_ORG_OPEN_POSITIONS_LIST'
export const SET_ORG_OPEN_POSITIONS = 'SET_ORG_OPEN_POSITIONS'
export const REMOVE_ORG_OPEN_POSITIONS = 'REMOVE_ORG_OPEN_POSITIONS'

export const SET_ORG_POTENTIAL_LIST = 'SET_ORG_POTENTIAL_LIST'
export const REMOVE_ORG_POTENTIAL_LIST = 'REMOVE_ORG_POTENTIAL_LIST'
export const SET_ORG_POTENTIAL = 'SET_ORG_POTENTIAL'
export const REMOVE_ORG_POTENTIAL = 'REMOVE_ORG_POTENTIAL'

export const SET_ORG_PRIORITY_LIST = 'SET_ORG_PRIORITY_LIST'
export const REMOVE_ORG_PRIORITY_LIST = 'REMOVE_ORG_PRIORITY_LIST'
export const SET_ORG_PRIORITY = 'SET_ORG_PRIORITY'
export const REMOVE_ORG_PRIORITY = 'REMOVE_ORG_PRIORITY'

export const SET_ORG_CURRENT_SCOPE = 'SET_ORG_CURRENT_SCOPE'
export const REMOVE_ORG_CURRENT_SCOPE = 'REMOVE_ORG_CURRENT_SCOPE'

export const SET_ORG_PITCH_TO_CANDIDATE = 'SET_ORG_PITCH_TO_CANDIDATE'
export const REMOVE_ORG_PITCH_TO_CANDIDATE = 'REMOVE_ORG_PITCH_TO_CANDIDATE'

export const SET_ORG_CLIENT_ON_BOARD_AFTER = 'SET_ORG_CLIENT_ON_BOARD_AFTER'
export const REMOVE_ORG_CLIENT_ON_BOARD_AFTER = 'REMOVE_ORG_CLIENT_ON_BOARD_AFTER'

export const SET_ORG_CLIENT_ON_BOARD_BEFORE = 'SET_ORG_CLIENT_ON_BOARD_BEFORE'
export const REMOVE_ORG_CLIENT_ON_BOARD_BEFORE = 'REMOVE_ORG_CLIENT_ON_BOARD_BEFORE'