
export const priorityList = [
  { id: 1, item: 'A' },
  { id: 2, item: 'B' },
  { id: 3, item: 'C' },
  { id: 4, item: 'A1' },
  { id: 5, item: 'A2' },
  { id: 6, item: 'A3' },
]

export const settingListDefaultValue = [
  { id: 1, name: 'Received Date', sortBy: 'receivedDate' },
  { id: 2, name: 'Days Elapsed', sortBy: 'daysElapsed' },
  { id: 3, name: 'Organization', sortBy: 'organization' },
  { id: 5, name: 'Position Name', sortBy: 'positionName' },
];
export const settingList = [
  { id: 6, name: 'Position Status', sortBy: 'positionStatus' },
  { id: 7, name: 'Total Openings', sortBy: 'totalOpenings' },
  { id: 8, name: 'Current Openings', sortBy: 'currentOpenings' },
  { id: 9, name: 'QC Pass', sortBy: 'qcPass' },
  { id: 10, name: "Cv's Sent", sortBy: 'cvsSent' },
  { id: 11, name: 'CVS Shortlisted', sortBy: 'cvsShortlisted' },
  { id: 12, name: 'Feedback Awaited', sortBy: 'feedbackAwaited' },
  { id: 13, name: 'CVS Rejected', sortBy: 'cvsRejected' },
  { id: 14, name: 'More CVS Required', sortBy: 'moreCvsRequired' },
  { id: 15, name: 'Duplicate CVS', sortBy: 'duplicateCvs' },
  { id: 16, name: 'Effort Invested [hrs]', sortBy: 'effortInvestedhrs' },
  { id: 17, name: 'Written Test Scheduled', sortBy: 'writtenTestScheduled' },
  { id: 18, name: 'Written Test Conducted', sortBy: 'writtenTestConducted' },
  { id: 19, name: 'Written Test Shortlisted', sortBy: 'writtenTestShortlisted' },
  { id: 20, name: 'Written Test Rejected', sortBy: 'writtenTestRejected' },
  { id: 21, name: 'Written Test Hold', sortBy: 'writtenTestHold' },
  { id: 22, name: 'L1 Scheduled', sortBy: 'l1Scheduled' },
  { id: 23, name: 'L1 Conducted', sortBy: 'l1Conducted' },
  { id: 24, name: 'L1 Shortlisted', sortBy: 'l1Shortlisted' },
  { id: 25, name: 'L1 Rejected', sortBy: 'l1Rejected' },
  { id: 26, name: 'L1 Hold', sortBy: 'l1Hold' },
  { id: 27, name: 'L2 Scheduled', sortBy: 'l2Scheduled' },
  { id: 28, name: 'L2 Conducted', sortBy: 'l2Conducted' },
  { id: 29, name: 'L2 Shortlisted', sortBy: 'l2Shortlisted' },
  { id: 30, name: 'L2 Rejected', sortBy: 'l2Rejected' },
  { id: 31, name: 'L2 Hold', sortBy: 'l2Hold' },
  { id: 32, name: 'M1 Scheduled', sortBy: 'm1Scheduled' },
  { id: 33, name: 'M1 Conducted', sortBy: 'm1Conducted' },
  { id: 34, name: 'M1 Shortlisted', sortBy: 'm1Shortlisted' },
  { id: 35, name: 'M1 Rejected', sortBy: 'm1Rejected' },
  { id: 36, name: 'M1 Hold', sortBy: 'm1Hold' },
  { id: 37, name: 'M2 Scheduled', sortBy: 'm2Scheduled' },
  { id: 38, name: 'M2 Conducted', sortBy: 'm2Conducted' },
  { id: 39, name: 'M2 Shortlisted', sortBy: 'm2Shortlisted' },
  { id: 40, name: 'M2 Rejected', sortBy: 'm2Rejected' },
  { id: 41, name: 'M2 Hold', sortBy: 'm2Hold' },
  { id: 42, name: 'HR Scheduled', sortBy: 'hrScheduled' },
  { id: 43, name: 'HR Conducted', sortBy: 'hrConducted' },
  { id: 44, name: 'HR Shortlisted', sortBy: 'hrShortlisted' },
  { id: 45, name: 'HR Rejected', sortBy: 'hrRejected' },
  { id: 46, name: 'HR Hold', sortBy: 'hrHold' },
  { id: 47, name: 'Final Selected', sortBy: 'finalSelected' },
]
export const selectedSettingList = [
  { id: 6, name: 'Position Status', sortBy: 'positionStatus' },
  { id: 7, name: 'Total Openings', sortBy: 'totalOpenings' },
  { id: 8, name: 'Current Openings', sortBy: 'currentOpenings' },
  { id: 9, name: 'QC Pass', sortBy: 'qcPass' },
  { id: 10, name: "Cv's Sent", sortBy: 'cvsSent' },
  { id: 11, name: 'CVS Shortlisted', sortBy: 'cvsShortlisted' },
  { id: 12, name: 'Feedback Awaited', sortBy: 'feedbackAwaited' },
  { id: 13, name: 'CVS Rejected', sortBy: 'cvsRejected' },
  { id: 14, name: 'More CVS Required', sortBy: 'moreCvsRequired' },
  { id: 15, name: 'Duplicate CVS', sortBy: 'duplicateCvs' },
  { id: 16, name: 'Effort Invested [hrs]', sortBy: 'effortInvestedhrs' },
  { id: 22, name: 'L1 Scheduled', sortBy: 'l1Scheduled' },
  { id: 23, name: 'L1 Conducted', sortBy: 'l1Conducted' },
  { id: 24, name: 'L1 Shortlisted', sortBy: 'l1Shortlisted' },
  { id: 25, name: 'L1 Rejected', sortBy: 'l1Rejected' },
  { id: 26, name: 'L1 Hold', sortBy: 'l1Hold' },
  { id: 27, name: 'L2 Scheduled', sortBy: 'l2Scheduled' },
  { id: 28, name: 'L2 Conducted', sortBy: 'l2Conducted' },
  { id: 29, name: 'L2 Shortlisted', sortBy: 'l2Shortlisted' },
  { id: 30, name: 'L2 Rejected', sortBy: 'l2Rejected' },
  { id: 31, name: 'L2 Hold', sortBy: 'l2Hold' },
  { id: 32, name: 'M1 Scheduled', sortBy: 'm1Scheduled' },
  { id: 33, name: 'M1 Conducted', sortBy: 'm1Conducted' },
  { id: 34, name: 'M1 Shortlisted', sortBy: 'm1Shortlisted' },
  { id: 35, name: 'M1 Rejected', sortBy: 'm1Rejected' },
  { id: 36, name: 'M1 Hold', sortBy: 'm1Hold' },
  { id: 37, name: 'M2 Scheduled', sortBy: 'm2Scheduled' },
  { id: 38, name: 'M2 Conducted', sortBy: 'm2Conducted' },
  { id: 39, name: 'M2 Shortlisted', sortBy: 'm2Shortlisted' },
  { id: 40, name: 'M2 Rejected', sortBy: 'm2Rejected' },
  { id: 41, name: 'M2 Hold', sortBy: 'm2Hold' },
  { id: 42, name: 'HR Scheduled', sortBy: 'hrScheduled' },
  { id: 43, name: 'HR Conducted', sortBy: 'hrConducted' },
  { id: 44, name: 'HR Shortlisted', sortBy: 'hrShortlisted' },
  { id: 45, name: 'HR Rejected', sortBy: 'hrRejected' },
  { id: 46, name: 'HR Hold', sortBy: 'hrHold' },
  { id: 47, name: 'Final Selected', sortBy: 'finalSelected' },
]
export const statusList = [
  { id: 1, item: 'Open-Sourcing Required' },
  { id: 2, item: 'Open-Sourcing Not Required' }
]
interface IListPayload {
  pageNo: number;
  sortType: string;
  pageSize: number;
  sortBy: string;
  loggedInUserRoleName: string;

  organizations?: [],
  clientHr?: [],
  positions?: [],
  backendSpoc?: [],
  frontendSpoc?: [],
  receivedAfter?: null,
  receivedBefore?: null,
  positionStatus?: [],
  priority?: [],
}
export const OpenPositionInitialState: IListPayload = {
  pageNo: 1,
  sortType: 'desc',
  sortBy: 'positionId',
  pageSize: 10,
  loggedInUserRoleName: '',
  organizations: [],
  clientHr: [],
  positions: [],
  backendSpoc: [],
  frontendSpoc: [],
  receivedAfter: null,
  receivedBefore: null,
  positionStatus: [],
  priority: [],
}