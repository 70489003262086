
export const orgTypeList = [
  { item: 'Agency' },
  { item: 'Client' },
  { item: 'Internal' },
  { item: 'Prospect' },
]
export interface IListOrg {
  pageSize: number
  pageNo: number
  sortBy: string
  sortType: string
  logedInUserRoleName: string;

  organizationType?: string
  name?: string
  verticals?: number[]
  cities?: number[]
  pfySpocs?: number[]
  potentials?: string[]
  priority?: string[]
  followedUpAfter?: string
  followedUpBefore?: string
  clientOnBoardAfter?: string
  clientOnBoardBefore?: string
  openPosition?: string[]
  requirementClosedInLast?: string[]
  skills?: number[]
  proficiencies?: string[]
  vendorEmpMonth?: string[]
  qcSuccess?: string[]
  cvToClosurePercent?: string[]
  cvsUploadedInLast?: string[]
  pitchToCandidates?: string[]
  currentScope?: string[]
}
export interface IListUsers {
  pageSize: number
  pageNo: number
  sortBy: string
  sortType: string
  logedInUserRoleName: string

  userType?: string
  email?: string
  listofRoles?: string[]
  selectedRole?: string[]
  listofVerticals?: string[]
  selectedVertical?: string[]
  listofLanguages?: string[]
  selectedLanguage?: string[]
  listofIndustries?: string[]
  selectedIndustry?: string[]
  listofFunctions?: string[]
  selectedFunction?: string[]
  listOfSkill?: string[]
  selectedSkill?: string[],
  listOfDesignation?: string[]
  selectedDesignation?: string[]
  listOfReportingTo?: string[]
  selectedReportingTo?: string[]
  listofOrganizations?: string[]
  selectedOrganization?: string[]
  listOfCities?: string[]
  selectedCity?: string[]
}
export interface IListCandidate {
  pageSize: number
  pageNo: number
  sortBy: string
  sortType: string
  logedInUserRoleName: string;

  organizationType?: string
  name?: string
  verticals?: number[]
  cities?: number[]
  pfySpocs?: number[]
  potentials?: string[]
  priority?: string[]
  followedUpAfter?: string
  followedUpBefore?: string
  clientOnBoardAfter?: string
  clientOnBoardBefore?: string
  openPosition?: string[]
  requirementClosedInLast?: string[]
  skills?: number[]
  proficiencies?: string[]
  vendorEmpMonth?: string[]
  qcSuccess?: string[]
  cvToClosurePercent?: string[]
  cvsUploadedInLast?: string[]
  pitchToCandidates?: string[]
  currentScope?: string[]
}
const orgFilter = JSON.parse(localStorage.getItem('orgFilter')!);
export const dropdownInitialState = orgFilter?.organizationType?.item
  ? { item: orgFilter?.organizationType?.item }
  : { item: 'Client' };
export const dropdownInitial = { item: '' };

export const orgFilterInitialState = {
  organizationType: dropdownInitialState,
  name: '',
  verticals: [],
  cities: [],
  pfySpocs: [],
  potentials: [],
  priority: [],
  followedUpAfter: null,
  followedUpBefore: null,
  clientOnBoardAfter: null,
  clientOnBoardBefore: null,
  openPosition: [],
  requirementClosedInLast: [],
  skills: [],
  proficiencies: [],
  vendorEmpMonth: [],
  qcSuccess: [],
  cvsUploadedInLast: [],
  cvToClosurePercent: [],
  teamSize: [],
  currentScope: '',
  pitchToCandidates: '',
}
