import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import styles from './index.module.scss';
interface Props {
  name: string,
  label?: string,
  options?: any,
  error?: any,
  touched?: boolean,
  handleChange?: any,
  className?: any,
  value?: any,
  methodHandleChange: any,
  attributeName: string
  disabled?: boolean
  placeholder?: any
  startAdornment?: string
  idValueFlag?: boolean
  required?: boolean
  idValue?: any
  KeyUp?: any,
  style?: any
}

const SelectField = ({ required = false, disabled = false, name, options, className, value, methodHandleChange, KeyUp,
  attributeName, placeholder, error = false, style }: Props) => {

  return options ? (
    <div className={`${styles.commonInputfields} ${className ? className : ''}`}>
      <FormControl variant="outlined" error={Boolean(error)} className={`${styles.formControl}`}>
        <InputLabel disabled={disabled} id="demo-simple-select-outlined-label">{placeholder} {required ? (<span className={styles.astrik}>*</span>) : ''} </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id={name}
          value={(attributeName && value && value[attributeName]) ? value[attributeName] : ""}
          disabled={disabled}
          fullWidth={true}
          defaultValue={{ item: '' }}
          onKeyUp={KeyUp}
          style={style}
        >
          {options.map((item, index) => <MenuItem className={`${item.color ? styles[item.color] : ''}`} key={index} onClick={() => methodHandleChange(item)} value={(item[attributeName!])}>
            {item[attributeName!]}
          </MenuItem>)}
        </Select>
        {error ? <FormHelperText>{error} </FormHelperText> : <></>}
      </FormControl>
    </div>) : <></>
}


export default SelectField
