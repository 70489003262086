export const priorityList = [
  { id: 1, item: 'A' },
  { id: 2, item: 'B' },
  { id: 3, item: 'C' },
  { id: 4, item: 'A1' },
  { id: 5, item: 'A2' },
  { id: 6, item: 'A3' },
]
export const settingList = [
  { id: 9, name: 'Position Applied To', sortBy: 'positionAppliedTo', order: false },
  { id: 10, name: 'Email', sortBy: 'email', order: false },
  { id: 11, name: 'Emergency No.', sortBy: 'emergencyNo', order: false },
  { id: 12, name: 'Source', sortBy: 'source', order: false },
  { id: 13, name: 'Current City', sortBy: 'currentCity', order: false },
  { id: 14, name: 'Position City', sortBy: 'positionCity', order: false },
  { id: 15, name: 'Highest Qualification', sortBy: 'highestQualification', order: false },
  { id: 16, name: 'Specialization', sortBy: 'specialization', order: false },
  { id: 17, name: 'Date of Birth', sortBy: 'dateOfBirth', order: false },
  { id: 18, name: 'Designation', sortBy: 'designation', order: false },
  { id: 19, name: 'Current Employer', sortBy: 'currentEmployer', order: false },
  { id: 20, name: 'Parsed Employer', sortBy: 'parsedEmployer', order: false },
  { id: 21, name: 'Total Experience', sortBy: 'totalExperience', order: false },
  { id: 22, name: 'Relevant Experience', sortBy: 'relevantExperience', order: false },
  { id: 23, name: 'Fine with Shifts', sortBy: 'fineWithShifts', order: false },
  { id: 24, name: 'Handling a Team', sortBy: 'handlingATeam', order: false },
  { id: 25, name: 'Team Size', sortBy: 'teamSize', order: false },
  { id: 26, name: 'Reason for Job Change', sortBy: 'reasonForJobChange', order: false },
  { id: 27, name: 'Current CTC', sortBy: 'currentCtc', order: false },
  { id: 28, name: 'Variable', sortBy: 'variable', order: false },
  { id: 29, name: 'Expected CTC', sortBy: 'expectedCtc', order: false },
  { id: 30, name: 'Notice Period', sortBy: 'noticePeriod', order: false },
  { id: 31, name: 'Notice Period Negotiable', sortBy: 'noticePeriodNegotiable', order: false },
  { id: 32, name: 'Serving Notice Period', sortBy: 'servingNoticePeriod', order: false },
  { id: 33, name: 'Notice Period Buy-Out Option', sortBy: 'noticePeriodBuyoutOption', order: false },
  { id: 34, name: 'Offer In Hand', sortBy: 'offerInHand', order: false },
  { id: 35, name: 'Alternate Contact No.', sortBy: 'alternateContactNo', order: false },
  { id: 36, name: 'Communication Rating', sortBy: 'communicationRating', order: false },
  { id: 37, name: 'Technical Rating', sortBy: 'technicalRating', order: false },
  { id: 38, name: 'Recruiter', sortBy: 'recruiter', order: false },
  { id: 39, name: 'QC Done By', sortBy: 'qcDoneBy', order: false },
  { id: 40, name: 'CV Screen Status', sortBy: 'cvScreenStatus', order: false },
  { id: 41, name: 'CV Rejection Reason', sortBy: 'cvRejectReason', order: false },
  { id: 42, name: 'CV Feedback Date', sortBy: 'cvFeedbackDate', order: false },
  { id: 43, name: 'WT Status', sortBy: 'wtStatus', order: false },
  { id: 44, name: 'WT Interview', sortBy: 'wtInterviewDate', order: false },
  { id: 45, name: 'WT Feedback Date', sortBy: 'wtFeedbackDate', order: false },
  { id: 46, name: 'L1 Status', sortBy: 'l1Status', order: false },
  { id: 47, name: 'L1 Interview Date', sortBy: 'l1InterviewDate', order: false },
  { id: 48, name: 'L1 Feedback Date', sortBy: 'l1FeedbackDate', order: false },
  { id: 49, name: 'L2 Status', sortBy: 'l2Status', order: false },
  { id: 50, name: 'L2 INterview Date', sortBy: 'l2InterviewDate', order: false },
  { id: 51, name: 'L2 Feedback Date', sortBy: 'l2FeedbackDate', order: false },
  { id: 52, name: 'M1 Status', sortBy: 'm1Status', order: false },
  { id: 53, name: 'M1 Interview Date', sortBy: 'm1InterviewDate', order: false },
  { id: 54, name: 'M1 Feedback Date', sortBy: 'm1FeedbackDate', order: false },
  { id: 55, name: 'M2 Status', sortBy: 'm2Status', order: false },
  { id: 56, name: 'M2 Interview Date', sortBy: 'm2InterviewDate', order: false },
  { id: 57, name: 'M2 Feedback Date', sortBy: 'm2FeedbackDate', order: false },
  { id: 58, name: 'HR Status', sortBy: 'hrStatus', order: false },
  { id: 59, name: 'HR Interview Date', sortBy: 'hrInterviewDate', order: false },
  { id: 60, name: 'Final Status', sortBy: 'finalStatus', order: false },
  { id: 61, name: 'Rejection Reason', sortBy: 'rejectionReason', order: false },
  { id: 62, name: 'Last Action Date', sortBy: 'lastActionDate', order: false },
]
export const selectedSettingList = [
  { id: 9, name: 'Position Applied To', sortBy: 'positionAppliedTo', order: false },
  { id: 12, name: 'Source', sortBy: 'source', order: false },
  { id: 13, name: 'Current City', sortBy: 'currentCity', order: false },
  { id: 14, name: 'Position City', sortBy: 'positionCity', order: false },
  { id: 15, name: 'Highest Qualification', sortBy: 'highestQualification', order: false },
  { id: 16, name: 'Specialization', sortBy: 'specialization', order: false },  
  { id: 18, name: 'Designation', sortBy: 'designation', order: false },
  { id: 19, name: 'Current Employer', sortBy: 'currentEmployer', order: false },
  { id: 20, name: 'Parsed Employer', sortBy: 'parsedEmployer', order: false },
  { id: 21, name: 'Total Experience', sortBy: 'totalExperience', order: false },
  { id: 22, name: 'Relevant Experience', sortBy: 'relevantExperience', order: false },
  { id: 26, name: 'Reason for Job Change', sortBy: 'reasonForJobChange', order: false },
  { id: 27, name: 'Current CTC', sortBy: 'currentCtc', order: false },
  { id: 29, name: 'Expected CTC', sortBy: 'expectedCtc', order: false },
  { id: 30, name: 'Notice Period', sortBy: 'noticePeriod', order: false },
  { id: 31, name: 'Notice Period Negotiable', sortBy: 'noticePeriodNegotiable', order: false },
  { id: 32, name: 'Serving Notice Period', sortBy: 'servingNoticePeriod', order: false },
  { id: 33, name: 'Notice Period Buy-Out Option', sortBy: 'noticePeriodBuyoutOption', order: false },
  { id: 34, name: 'Offer In Hand', sortBy: 'offerInHand', order: false },
  { id: 36, name: 'Communication Rating', sortBy: 'communicationRating', order: false },
  { id: 37, name: 'Technical Rating', sortBy: 'technicalRating', order: false },
  { id: 38, name: 'Recruiter', sortBy: 'recruiter', order: false },
  { id: 39, name: 'QC Done By', sortBy: 'qcDoneBy', order: false },
  { id: 40, name: 'CV Screen Status', sortBy: 'cvScreenStatus', order: false },
  { id: 41, name: 'CV Rejection Reason', sortBy: 'cvRejectReason', order: false },
  { id: 42, name: 'CV Feedback Date', sortBy: 'cvFeedbackDate', order: false },
  { id: 46, name: 'L1 Status', sortBy: 'l1Status', order: false },
  { id: 47, name: 'L1 Interview Date', sortBy: 'l1InterviewDate', order: false },
  { id: 49, name: 'L2 Status', sortBy: 'l2Status', order: false },
  { id: 50, name: 'L2 INterview Date', sortBy: 'l2InterviewDate', order: false },
  { id: 52, name: 'M1 Status', sortBy: 'm1Status', order: false },
  { id: 53, name: 'M1 Interview Date', sortBy: 'm1InterviewDate', order: false },
  { id: 58, name: 'HR Status', sortBy: 'hrStatus', order: false },
  { id: 59, name: 'HR Interview Date', sortBy: 'hrInterviewDate', order: false },
  { id: 60, name: 'Final Status', sortBy: 'finalStatus', order: false },
  { id: 61, name: 'Rejection Reason', sortBy: 'rejectionReason', order: false },
  { id: 62, name: 'Last Action Date', sortBy: 'lastActionDate', order: false },
];
export const statusList = [
  { id: 1, item: 'Open' },
  { id: 2, item: 'To be Screened' },
  { id: 3, item: 'Screen Select' },
  { id: 4, item: 'Screen Reject' },  
  { id: 5, item: 'QC Pending' },
  { id: 6, item: 'QC Reject' },
  { id: 7, item: 'QC Passed' },
  { id: 8, item: 'Sent to Client' },
  { id: 9, item: 'CV Shortlisted' },
  { id: 10, item: 'CV Rejected' },  
  { id: 11, item: 'Interview Scheduled' },
  { id: 12, item: 'Interview Select' },
  { id: 13, item: 'Selected for Next Round' },
  { id: 14, item: 'Interview Hold' },
  { id: 15, item: 'Candidate Rejected in Interview' },
  { id: 16, item: 'Interview Round cancelled' },  
  { id: 17, item: 'Interview cancelled by Candidate' },
  { id: 18, item: 'Interview cancelled by client' },
  { id: 19, item: 'Position Cancelled' },
  { id: 20, item: 'Final Select' },
  { id: 21, item: 'Offer Hold' },
  { id: 22, item: 'Offer Sent' },
  { id: 23, item: 'Documents Sent' },
  { id: 24, item: 'Offer Revoked' },  
  { id: 25, item: 'Offer Accepted' },
  { id: 26, item: 'Offer Declined' },
  { id: 27, item: 'Joined' },
  { id: 28, item: 'Interview Re-scheduled' },
  { id: 29, item: 'CV Feedback Pending' },
  { id: 30, item: 'Candidate Backed-out' },  
  { id: 31, item: 'Incomplete-Call' },
  { id: 32, item: 'Screen Hold' },
  { id: 33, item: 'Interview to be Scheduled' },
  { id: 34, item: 'Interview Feedback Pending' },
  { id: 35, item: 'Duplicate CV' },
  { id: 36, item: 'Position Hold' },  
  { id: 37, item: 'Attrition' },
  { id: 38, item: 'Candidate on Hold' },
  { id: 39, item: 'HR Drop' },
  { id: 40, item: 'Interview Conducted' },
  { id: 41, item: 'Candidate Dropped by Recruiter' },
  { id: 42, item: 'Candidate self Drooped-out' }
]
export const CandidateTrackerInitialState = {
  organizations: [],
  positions:[],
  pfyPositions: [],
  clientHr: [],
  backendSpoc: [],
  frontendSpoc: [],
  contactNumber: '',
  firstName: '',
  lastName: '',
  screenedFrom: null,
  screenedTo: null,
  clientBu: [],
  currentStatus: [],
  lastActionDateFrom: null,
  lastActionDateTo: null
}