import api from './api'
import { addapirole, addSingleCandidate, addSingleOrganization, addSingleUser, addUserDetails, apiaddposition, apiorganization, apipositiondelete, apipositionmanagerqcclientexecutive, apipositionorganiationwise, apiteamAllocationpositionorganiationwise, apiwiki, apiwikidelete, checkAssociatePosition, checkAssociateRole, clienthrPositionListFilterData, deleteSingleOrganization, deleteSingleUser, deleteSingleUsers, edituser, organizationEmailCheckUrl, removetowfactorkey, templateDownload, useraddmultiple, userdeletemultiple, userinvitemultiple, userlist, userlistactionsstatussingle, userlistactionstatusactive, userlistactionstatusDeactivate, userlistfilter, userlistsearch } from './urls'

export const getUserTemplate = async () => {
    try {
        return await api.get(templateDownload)
            .then(response => {
                return { status: response.status, body: response.data }
            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const useraddsingleAPI = async (user: Object) => {
    try {
        return await api.post(addSingleUser, user)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const organizationaddsingleAPI = async (organization: Object) => {
    try {
        return await api.post(addSingleOrganization, organization)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const organizationEmailCheck = async (payload) => {
    try {
      return await api
        .post(`${organizationEmailCheckUrl}`, payload)
        .then((response) => {
          return { status: response.status, body: response.data };
        })
        .catch((err) => {
          return { status: err.response.status, body: err.response.data };
        });
    } catch (err) {
      return { status: 500, body: 'Failed to connect' };
    }
};
export const organizationEditSingleAPI = async (payload) => {
    try {
        return await api.put(`${apiorganization}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}

export const usereditsingleAPI = async (payload) => {
    try {
        return await api.put(`${addapirole}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const usereditAPI = async (payload) => {
    try {
        return await api.put(`${edituser}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const organizationeditAPI = async (payload) => {
    try {
        return await api.put(`${apiorganization}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const organizationeditsingleAPI = async (payload) => {
    try {
        return await api.put(`${addapirole}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const useraddmultipleAPI = async (users: Array<Object>) => {
    try {
        return await api.post(useraddmultiple, users)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userlistAPI = async (payload) => {
    try {
        return await api
            .post(`${userlist}`, payload)
            .then((response) => {
                return { status: response.status, body: { result: response.data } }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userDetailsAPI = async (id) => {
    try {
        return await api
            .get(`${addUserDetails}/${id}`)
            .then((response) => {
                return { status: response.status, body: response.data }
            })
            .catch((err) => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userlistactionsstatussingleAPI = async (value: Object) => {
    try {
        return await api.post(userlistactionsstatussingle, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const getListOfProjetcNamesAndBUList = async (value: Object) => {
    try {
        return await api.post(clienthrPositionListFilterData, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userlistactionsstatusmultipleactiveAPI = async (value: any) => {
    try {
        return await api.put(userlistactionstatusactive, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}

export const userlistactionsstatusmultipleDeactivateAPI = async (value: any) => {
    try {
        return await api.put(userlistactionstatusDeactivate, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const checkAssociateRoleAPI = async (payload: any) => {
    try {
        return await api
            .get(`${checkAssociateRole}/${payload}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const checkAssociatePositionAPI = async (payload: any) => {
    try {
        return await api
            .get(`${checkAssociatePosition}/${payload}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const removetowfactorkeyAPI = async (payload: any) => {
    try {
        return await api
            .post(`${removetowfactorkey}/${payload.userId}/${payload.logedInUserRoleName}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userdeletewikiAPI = async (value: any) => {
    try {
        return await api.delete(apiwikidelete, { data: value })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userdeletSinglewikiAPI = async (value: any) => {
    try {
        return await api.delete(`${apiwikidelete}/${value.wikiId}/${value.logedInUserRoleName}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userdeletesingleAPI = async (payload: any) => {
    try {
        return await api.delete(deleteSingleUser, { data: payload })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const usersdeletesingleAPI = async (payload: any) => {
    try {
        return await api.delete(deleteSingleUsers, { data: payload })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userdeletemultipleAPI = async (value: any) => {
    try {
        return await api.delete(userdeletemultiple, { data: value })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userlistfilterAPI = async (value: Object) => {
    try {
        return await api.post(userlistfilter, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userlistsearchAPI = async (value: any) => {
    try {
        return await api.post(userlistsearch, value)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const userinvitemultipleAPI = async (value: Object) => {
    try {
        return await api.post(userinvitemultiple, value)
            .then(response => {
                return { status: response?.status, body: response?.data }

            }).catch(err => {
                return { status: err.response?.status, body: err?.response?.data }
            })
    }
    catch (err) {

        return { status: 500, body: 'Failed to connect' }
    }
}
export const organizationdeletesingleAPI = async (payload: any) => {
    try {
        return await api.delete(deleteSingleOrganization, { data: payload })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positiondeletesingleAPI = async (payload: any) => {
    try {
        return await api.delete(apipositiondelete, { data: payload })
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const positionOrganizationWiseAPI = async (id: any) => {
    try {
        return await api.get(`${apipositionorganiationwise}/${id}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const teamAllocationPositionOrganizationWiseAPI = async (id: any) => {
    try {
        return await api.get(`${apiteamAllocationpositionorganiationwise}/${id}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const getManagerQcClientExecutiveAPI = async () => {
    try {
        return await api.get(`${apipositionmanagerqcclientexecutive}`)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }
}
export const addSinglePosition = async (value: Object) => {
    try {
        return await api.post(apiaddposition, value)
            .then(response => {
                return { status: response?.status, body: response?.data }

            }).catch(err => {
                return { status: err.response?.status, body: err?.response?.data }
            })
    }
    catch (err) {

        return { status: 500, body: 'Failed to connect' }
    }
}
export const candidateaddsingleAPI = async (candidate: Object) => {
    try {
        return await api.post(addSingleCandidate, candidate)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
export const wikieditsingleAPI = async (payload) => {
    try {
        return await api.put(`${apiwiki}`, payload)
            .then(response => {
                return { status: response.status, body: response.data }

            }).catch(err => {
                return { status: err.response.status, body: err.response.data }
            })
    }
    catch (err) {
        return { status: 500, body: 'Failed to connect' }
    }

}
