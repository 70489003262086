
import { v4 as uuidv4 } from 'uuid'
import Action from '../../types/action'
import { SET_MASTERDATA, REMOVE_MASTERDATA } from '_store/constants/masterData'
const initialState = {
    masterData: null
}

const masterData = (state = initialState, action: Action) => {
    switch (action.type) {
        case SET_MASTERDATA:
            return { ...state, id: uuidv4(), masterData: action.payload }
        case REMOVE_MASTERDATA:
            localStorage.removeItem('masterData')
            try {
                return { ...state, id: uuidv4(), masterData: null }
            } finally {
                action?.payload()
            }
        default:
            return state
    }
}
export default masterData