import { broadcastmessage } from './urls';
import api from './api';

export const broadcastMessageAPI = async (payload: any) => {
  try {
    return await api
      .post(`${broadcastmessage}`, payload)
      .then((response) => {
        return { status: response.status, body: response.data };
      })
      .catch((err) => {
        return { status: err.response.status, body: err.response.data };
      });
  } catch (err) {
    return { status: 500, body: 'Failed to connect' };
  }
};