import { call, put, takeEvery } from "redux-saga/effects";
import { dashboardDriveTracker } from "_store/apis/urls";
import { apiPostCall } from "_store/apis/_commonApi";
import { setDriveTrackerData, resetDriveTrackerState } from "_store/reducer/driveTrackerData";
import { DRIVE_TRACKER_DATA_ASYNC, DRIVE_TRACKER_DATA_RESET_ASYNC } from "./constants";



export function* getDriveTrackerData (request: { type: string; payload: any }) {
    try {
        localStorage.setItem('driveTrackerRequest', JSON.stringify({ ...request.payload }));
        const { body, status } = yield call(apiPostCall, dashboardDriveTracker, request.payload);
        if (request.type === 'DRIVE_TRACKER_DATA_RESET_ASYNC') {
            yield put(resetDriveTrackerState({ response: body, request: request.payload }));
        } else {
            yield put(setDriveTrackerData({ response: body, request: request.payload }));
        }
    }
    catch (err) {
        console.log(err)
    }
}

export function* watcherDriveTrackerSaga () {
    yield takeEvery(DRIVE_TRACKER_DATA_ASYNC, getDriveTrackerData)
    yield takeEvery(DRIVE_TRACKER_DATA_RESET_ASYNC, getDriveTrackerData)
}