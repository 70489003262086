import { SUCCESS_MESSAGE, ERROR_MESSAGE } from '../constants/index'
import { v4 as uuidv4 } from 'uuid'
import Action from '../../types/action'
import { SUCCESS_TABVALUE, ERROR_TABVALUE } from '_store/constants/tabValue'

const initialState = {
  tabValue: 'ViewProfile',
};

const tabValue = (state = initialState, action: Action) => {
  switch (action.type) {
    case SUCCESS_TABVALUE:
      return { ...state, id: uuidv4(), tabValue: action.payload }
    case ERROR_TABVALUE:
      try {
        return { ...state, id: uuidv4(), tabValue: null }
      } finally {
        action?.payload()
      }
    default:
      return state
  }
}
export default tabValue