import { Modal } from 'react-bootstrap'
import PrimaryButton from '../../../../../../widgets/PrimaryButton'
import styles from './index.module.scss'

const ParserConfirmation = (props: any) => {
    let { show, handleClose, parserConfirm ,setParserConfirm } = props;

    const parseDataHandler = () =>{
        parserConfirm = true
        setParserConfirm(parserConfirm)
        handleClose();
    }
    return (
        <Modal centered show={show} onHide={handleClose} className={`${styles.modalCustomcard} ${styles.addUserPopupWrapper1}`}>
            <Modal.Header className={styles.modal_header} closeButton>
                <h4 className={styles.commonTitle}>Parser Confirmation</h4>
            </Modal.Header>
            <Modal.Body className={styles.modalbody}>
                <div className="d-flex align-items-center justify-content-center">
                    <h4 className={`text-center ${styles.modalsubtitle}`}>Do you want to parse the information from CV?</h4>
                    <br></br>
                </div>
                <div className="d-flex justify-content-center">
                    <PrimaryButton className="mr-3" text={'Yes'} disabled method={parseDataHandler} />
                    <PrimaryButton text={'No, Upload File Only'} method={()=>handleClose()} />
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default ParserConfirmation