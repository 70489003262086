import { REMOVE_CANDIDATE_STATE, SET_CANDIDATE_STATE, SET_ALL_CANDIDATE_FILTERS } from '_store/constants/filterData_candidate';
import Action from '../../types/action';

export const filterCandidateNullData = {
  minExp: null,
  maxExp: null,
  minCtc: null,
  maxCtc: null,
  screenedFrom: null,
  screenedTo: null
}
export const filterCandidateStringData = {
  contactNo: '',
  firstName: '',
  lastName: '',
  email: '',
  resumeSourceFolder: ''
}
export const filterCandidateOtherFieldData = {
  ...filterCandidateNullData,
  ...filterCandidateStringData
}

        
export const filterCandidateLists = {
  listScreenForOrganization: [],
}

export const filterCandidateArrayData = {
  screenedForOrganization: [],
  noticePeriod: [],
  candidateStatus: [],
  city: [],
  currentEmployeer: [],
  functions: [],
  gender: [],
  industry: [],
  internalPositionName: [],
  preferedJobCity: [],
  primarySkill: [],
  screenedByRecruiter: [],
  uploadedBy: []
}
const initialState = {
  ...filterCandidateLists,
  ...filterCandidateArrayData,
  ...filterCandidateStringData,
  ...filterCandidateNullData
}
const filterData_candidate = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_CANDIDATE_STATE:
      localStorage.setItem('candidateFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
    case SET_ALL_CANDIDATE_FILTERS:
      localStorage.setItem('candidateFilter', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
      break;
    case REMOVE_CANDIDATE_STATE:
      localStorage.setItem('candidateFilter', JSON.stringify(initialState));
      return initialState;
      break;
    default:
      return state
      break;
  }
}
export default filterData_candidate