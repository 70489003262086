import { Avatar, Box, Tab, Tabs, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertUTCToLocalTime } from 'utilities/utcDate';
import { setNotificationData } from '_store/actions/notification';
import api from '_store/apis/api';
import { getDashboardNotificationDetail, getReadNotifications, markAllAllRead, putReadNotification } from '_store/apis/BackoutCandidates';
import { putNotifications } from '_store/apis/notification';
import { baseURLWS } from '_store/apis/urls';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '_store/constants';
import { RootState } from '_store/reducer/rootReducer';
import styles from './index.module.scss';
import { lightBlue } from '@mui/material/colors';


interface INotificationState {
  count: number;
  criticalCount: number;
  broadcastMessageData: any;
  notificationMessagesData: any;
  notificationCout: any;
}
interface IProps {
  type: any;
  isCritical?: boolean;
  notificationCout: any;
  normalAlertCount?: any;
  setNormalAlertCount?: any;
  fetchUserNotificationCount: any;
}
const NotificationComp = (props: IProps) => {
  const initialStateVal: INotificationState = {
    count: 0,
    criticalCount: 0,
    broadcastMessageData: [],
    notificationMessagesData: [],
    notificationCout: () => { }
  }
  const [initialState, setInitialState] = useState<INotificationState>(initialStateVal)
  const [readNotification, setReadNotifications] = useState([])
  const { setNormalAlertCount, normalAlertCount, type, notificationCout, fetchUserNotificationCount } = props;
  const dispatch = useDispatch();
  const notificationState = useSelector((state: RootState) => state.notificationCountData);
  const broadcastMessage: any = useSelector((state: RootState) => state.broadcastMessage);
  const user = useSelector((state: RootState) => state.user.user);
  const [value, setValue] = useState(0);
  const [pageNo, setpageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const putNotification = async (id) => {
    if (id) {
      try {
        const data = await putNotifications(id);
        const { status, body }: any = data;
        if (status === 200) {
          const count = notificationState.data.filter(ele => ele.Id !== id).length;
          const data = notificationState.data.filter(ele => ele.Id !== id);
          dispatch(setNotificationData({ count, data }));

          dispatch({ type: SUCCESS_MESSAGE, payload: body?.message ? body?.message : 'Successfully Read Notification' })
        }
        else if (status > 399 && status < 500) {
          dispatch({ type: ERROR_MESSAGE, payload: body?.message ? body?.message : 'Failed to Read Notification' })
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    if (notificationState.data && notificationState.data.length) {
      const criticalCount = notificationState?.data.filter(ele => ele.IsCritical).length;
      const count = notificationState?.data.filter(ele => !ele.IsCritical).length;
      setInitialState((prev: INotificationState) => ({ ...prev, count, criticalCount: prev.criticalCount + broadcastMessage?.length + count }))
    }
  }, [notificationState])
  const loadBroadCastData = async () => {
    const result = await api.get('api/todays/broadcastMessage');
    if (result.status) {
      return result.data;
    }
  }
  const listInnerRef = useRef<any>(null);


  const loadNotificationData = async (type) => {
    const primaryRole = localStorage.getItem('primaryRole')
    const user = localStorage.getItem('user')
    const userDetails = JSON.parse(user!);
    const payload = {
      loggedInUserRoleName: primaryRole,
      alertType: type,
      // id:userDetails.userId,
      pageNo: pageNo,
    }
    try {
      const responseData: any = await getDashboardNotificationDetail(payload);
      const { status, body } = responseData;
      if (status == 200) {
        return body;
      }
    } catch (error) {
      console.error(error)
    }
  }

  const readNotifications = async (type: string) => {
    const payload = {
      notificationType: type,
      pageNo: pageNo
    }
    try {
      const responseData: any = await getReadNotifications(payload);
      const { status, body } = responseData;
      if (status == 200) {
        return body;
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleReadNotification = async (id?) => {
    try {
      const responseData: any = await putReadNotification(id);
      const { status } = responseData;
      if (status == 200) {
        notificationCout();
        loadInitialNotifications();
        fetchUserNotificationCount();
      }
    } catch (error) {
      console.error(error)
    }
  }

  const readAllNotifiations = async () => {
    const primaryRole = localStorage.getItem('primaryRole')
    const user = localStorage.getItem('user')
    const userDetails = JSON.parse(user!);
    const roleId = userDetails?.roles.find((item: any) => item.name === primaryRole)?.id
    const payload =
    {
      loggedInUserRoleId: roleId
    }

    try {
      const responseData: any = await markAllAllRead(payload);
      if (responseData.status == 200) {
        notificationCout();
        setInitialState((prev: INotificationState) => ({ ...prev, notificationMessagesData: [] }))
        loadInitialNotifications();
        fetchUserNotificationCount();
      }
    } catch (error) {
      console.error(error)
    }
  }
  const loadInitialNotifications = async () => {
    if (type === 'notificationsBroadCast') {
      const result = await loadBroadCastData();
      setInitialState((prev: INotificationState) => ({ ...prev, broadcastMessageData: result }));
      return;
    }
    if (type === 'notifications') {
      const notificationMessagesData = await loadNotificationData("normal");
      const resultReadNotification = await readNotifications("normal");
      //for onscroll append data
      let Allnotificationdata = initialState.notificationMessagesData.concat(notificationMessagesData)
      let AllReadnotificationdata = readNotification.concat(resultReadNotification)
      console.log(Allnotificationdata, 'Allnotificationdata')
      setInitialState((prev: INotificationState) => ({ ...prev, notificationMessagesData: notificationMessagesData }))
      setReadNotifications(AllReadnotificationdata);
      return;
    }
    const notificationMessagesData = await loadNotificationData("critical");
    const resultReadNotification = await readNotifications("critical");
    setInitialState((prev: INotificationState) => ({ ...prev, notificationMessagesData }))
    setReadNotifications(resultReadNotification);
    return;
  }
  useEffect(() => {
    loadInitialNotifications()
  }, [type]);


  const handleMarkAllRead = async () => {
    readAllNotifiations();
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const checkDates = (date: string) => {
    const givenDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday = givenDate.toLocaleDateString() === today.toLocaleDateString();
    const isYesterday = givenDate.toLocaleDateString() === yesterday.toLocaleDateString();
    let dateString = "";
    if (isToday) {
      dateString = `TODAY / ${moment(convertUTCToLocalTime(date)).format("hh:mm A")}`;
      return dateString;
    } else if (isYesterday) {
      dateString = `YESTERDAY / ${moment(convertUTCToLocalTime(date)).format("hh:mm A")}`;
      return dateString;
    } else {
      dateString = `${moment(date + "Z", "YYYY-MM-DDTHH:mm:ssZ").format("DD-MM-YYYY hh:mm A")}`;
      return dateString;
    }
  }

  const getTimeDiff = (date: any) => {
    const givenDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    var d1 = new Date(date);
    var d2 = new Date();

    var diff = d2.getTime() - d1.getTime();

    var minuteDiff = Math.round(diff / (1000 * 60)) - 330;
    var hourDiff = minuteDiff > 60 ? Math.round(minuteDiff / 60) : 0;

    const isToday = givenDate.toLocaleDateString() === today.toLocaleDateString();
    const isYesterday = givenDate.toLocaleDateString() === yesterday.toLocaleDateString();
    let dateString = "";
    if (isToday) {
      dateString = hourDiff >= 1 ? `${hourDiff} hours ago` : `${minuteDiff} minute ago`;
      return dateString;
    } else if (isYesterday) {
      dateString = `${hourDiff} hours ago`;
      return dateString;
    } else {
      return;
    }
  }

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);

  const showNotification = (messageData: any) => {
    new Notification(messageData[0].Template)
  }


  useEffect(() => {
    if (user) {
      // loadNotificationDataWS();
    }
  }, [user]);

  const loadNotificationDataWS = async () => {
    const localStorageUserDetails = localStorage.getItem('user') || null;
    const userDetails = JSON.parse(localStorageUserDetails!);
    const socketUrl = `${baseURLWS}${userDetails.userId}`;
    const socketNotification = new WebSocket(socketUrl);
    console.log("socketNotification ", socketNotification)
    socketNotification.onopen = () => {
      console.info('Socket opened notification');
      socketNotification.send('Ping');
    };
    socketNotification.onclose = (event) => {
      console.info('Socket closed notification', event);
    };
    socketNotification.onerror = (error) => {
      console.error('socket error notification', error);
    };
    socketNotification.onmessage = function (event) {
      const messageData: any = JSON.parse(event.data);
      if (messageData.length) {
        localStorage.removeItem('notificationMessage');
        localStorage.setItem('notificationMessage', JSON.stringify(messageData));
        notificationCout();
        showNotification(messageData)
      }
    };
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      let count = 50;
      // const { count, bulkTeamAllocationObjList } = formik.values;
      if (initialState.notificationMessagesData.length >= normalAlertCount) {
        setHasMore(false)
        return;
      }
      if ((Math.ceil(scrollTop) + clientHeight) === scrollHeight) {
        setpageNo(prev => prev + 1);

      }
    }
  };

  useEffect(() => {
    loadInitialNotifications()
  }, [pageNo]);

  useEffect(() => {
    setpageNo(1)
  }, [value]);
  return (
    <div className={''}>
      <div className={`${styles.dropdownContent1} `}>
        <div className={`${styles.notify_field} ${styles.critical}`}>
          <div className={styles.notify_head}>
            <span>
              {type === 'notificationsBroadCast' ? "Broadcast Messages" : type === 'notifications' ? "Normal Notifications" : "Critical Notifications"}
            </span>
            {type !== "criticalAlert" && value !== 1 && <span className={`${styles.mark_all_read_link}`}>
              <div style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={handleMarkAllRead} >Mark all as read</div>
            </span>}
          </div>
          {type !== "notificationsBroadCast" ?
            <div className={styles.notify_body} onScroll={onScroll} ref={listInnerRef} >
              <Tabs sx={{
                position: "sticky",
                top: 0, background: "#fff",
                zIndex: 1, minHeight: "unset", borderBottom: "1px solid #f1f1f1"
              }} value={value} onChange={handleChange} aria-label="Notification panel">
                <Tab sx={{ padding: "10px", fontWeight: 500, minWidth: "unset", minHeight: "unset", fontSize: 11, textTransform: "capitalize" }} label="Unread" {...a11yProps(0)} />
                <Tab sx={{ padding: "10px", fontWeight: 500, minWidth: "unset", minHeight: "unset", fontSize: 11, textTransform: "capitalize" }} label="Read" {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={value} index={0}>
                {initialState.notificationMessagesData?.map((item: any) => (<>
                  <div className={styles.notify_label} onClick={() => handleReadNotification(item.notificationId)}>
                    <div className={styles.notificationG}>
                      <Avatar sx={{ bgcolor: lightBlue[500], width: 50, height: 50 }}>{item?.createdBy?.split(" ")[0][0]}{item?.createdBy?.split(" ")[1][0]}</Avatar>
                      <div className={styles.notificationT} >
                        {checkDates(item.createdOn)}
                        <br></br>
                        <strong>{item.message} <div className="text-danger"> {getTimeDiff(item.createdOn)}</div></strong>
                      </div>
                    </div>
                  </div>
                </>))}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {type !== "notificationsBroadCast" && readNotification?.map((item: any) => (<>
                  <div className={styles.notify_label} >
                    <div className={styles.notificationG}>
                      <div className={styles.notificationT} >
                        {checkDates(item.readOn)}
                        <br></br>
                        {item.message}
                      </div>
                    </div>
                  </div>
                </>))}
              </TabPanel>
            </div> :
            <div className={styles.notify_body}>
              {initialState?.broadcastMessageData?.map(item => (<>
                <div className={styles.notify_label} >
                  <div className={styles.notificationG}>
                    <div className={styles.notificationT} >
                      {item}
                    </div>
                  </div>
                </div>
              </>))}
            </div>}
        </div>
      </div></div>
  );
}

export default NotificationComp;