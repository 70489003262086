import { REMOVE_CANDIDATE_TRACKER_STATE, SET_ALL_CANDIDATE_TRACKER_STATE, SET_CANDIDATE_TRACKER_STATE } from '_store/constants/filterData_CandidateTracker';
import Action from '../../types/action';
import { selectedSettingList } from 'containers/dashboard/AdminDashboard/CandidateTracker/filterTypes';

const initialState = { 
  setting: [
    ...selectedSettingList
  ]
}
const filterData_CandidateTracker = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_CANDIDATE_TRACKER_STATE:
      localStorage.setItem('candidateTrackerFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
      case SET_ALL_CANDIDATE_TRACKER_STATE:
        localStorage.setItem('candidateTrackerFilter', JSON.stringify({ ...state, ...action.payload }));
        return { ...state, ...action.payload };
        break;
    case REMOVE_CANDIDATE_TRACKER_STATE:
      localStorage.removeItem('candidateTrackerFilter');
      return initialState;
      break;
    default:
      return state
  }
}
export default filterData_CandidateTracker