import { REMOVE_SELECTED_CANDIDATEDES_STATE, SET_ALL_SELECTED_CANDIDATEDES_STATE, SET_SELECTED_CANDIDATEDES_STATE } from '_store/constants/filterData_SelectedCandidates';
import Action from '../../types/action';

const initialState = {
  dateOfJoiningFrom: null,
  dateOfJoiningTo: null,
  offeredFrom: null,
  offeredTo: null,
  resignedFrom: null,
  resignedTo: null,
  selectedFrom: null,
  selectedTo: null,
}
const filterData_SelectedCandidates = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SELECTED_CANDIDATEDES_STATE:
      localStorage.setItem('selectedCandidateFilter', JSON.stringify({ ...state, [action.payload.type]: action.payload.data }));
      return { ...state, [action.payload.type]: action.payload.data }
      break;
      case SET_ALL_SELECTED_CANDIDATEDES_STATE:
        localStorage.setItem('selectedCandidateFilter', JSON.stringify({ ...state, ...action.payload }));
        return { ...state, ...action.payload };
        break;
    case REMOVE_SELECTED_CANDIDATEDES_STATE:
      localStorage.removeItem('selectedCandidateFilter');
      return initialState;
      break;
    default:
      return state
  }
}
export default filterData_SelectedCandidates