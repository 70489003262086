import styles from './index.module.scss';
import PrimaryButton from 'widgets/PrimaryButton';
import { remove } from '../../_store/actions/user';
import { useDispatch, } from 'react-redux';
import { path } from '../../pageRoutes/routers';
import { useHistory } from 'react-router-dom';

const RegistrationFeedback = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const navigate = () => {
    history.push(path.Login);
  };

  const handleYes = () => {
    dispatch(remove(navigate));
    props.hide()
  }

  return (

    <div className={`${styles.invitemodal} ${styles.centerContentModal}`}>
      <h5 className={styles.commonTitle}>
        <strong>Thank you for registration</strong>
      </h5>
      <h6 className={styles.modalsubtitle}>We have received your registration request,<br /> PEOPLEFY will get back to you
      </h6>
      <div className={`d-flex justify-content-center align-items-center ${styles.buttonModel}`}>
        <PrimaryButton
          className={styles.modalBtn}
          text={'Okay'}
          method={() => handleYes()}
        />
      </div>
    </div>

  );
};

export default RegistrationFeedback;