import { Card } from 'react-bootstrap';
import styles from './index.module.scss';

const ModalCard = (props) => {

  return (
    <Card className={styles.modalsubMain}>
      <div className={styles.subBodyItems}>
        {props.component}
      </div>
    </Card>
  );
};

export default ModalCard;
